import React, { useState } from "react"
import { Dropdown, Icon } from "semantic-ui-react"


export const UserBanner = ({teamId, ...restProps}) => {
    const [username, setUsername] = useState("test")

    return (
        <Dropdown id="user_settings" className="userProfileDropdownBtn" icon={null} 
            style={{marginLeft: 36}} text={ <div className="projectHeaderLinks" style={{marginRight: 12}}>
                 {username}
                <Icon 
                    name="user circle" 
                    style={{color: 'white', fontSize: '10px', position: 'absolute', right: -20}} 
                /></div> }>
                <Dropdown.Menu style={{width: '100%'}} >
                <Dropdown.Item style={{textAlign: 'center'}} text='Logout'  />
            </Dropdown.Menu>
        </Dropdown>
    )
}