import React, { useState } from 'react';
import FadeIn from 'react-fade-in';
import { Row, Button } from 'reactstrap';

const SdkSetup = ({
  orgId,
  projectId,
  ...sdkData
}) => {
  return (
    <div>
      <br></br>

      <FadeIn>
        <h1 className="sdkFormH1" style={{ marginBottom: 13, marginTop: -10 }}> Configuring our Sdk </h1>

      </FadeIn>

      <FadeIn>
        <h1 className="sdkFormH1"> Integrating the SDK </h1>
        <p className="sdkFormText">Install the sdk.  </p>
        <div style={{ height: '59px' }} className="sdkFormCodeBg">
          <p className="sdkFormCode"> <span style={{ color: '#B7FFFF' }}> pip install  </span>stackstr-sdk </p>
        </div>
      </FadeIn>

      <FadeIn>
        <br></br>
        <h1 className="sdkFormH1"> Create a client </h1>
        <p className="sdkFormText"> Import the package into your Python script.  Retrieves your key, secret, & project id as parameters. </p>
        <p className="sdkFormText" style={{ fontFamily: 'monospace' }}> PROJECT_ID = {projectId}</p>
        <div className="sdkFormCodeBg" style={{ marginBottom: 23 }}>

          <p className="sdkFormCode">  <span style={{ color: '#B7FFFF' }}> from </span> stackstr <span style={{ color: '#B7FFFF' }}> import </span> StackStrClient </p>
          <p className="sdkFormCode" style={{ marginBottom: 0 }}> client = StackStrClient(key, secret, <span style={{ color: '#B7FFFF' }}> '{projectId}'</span>)   </p>
        </div>
        <p className="sdkFormText"> <span onClick={() => window.open('/' + orgId + '/credentials')} style={{ textDecoration: 'underline', cursor: 'pointer' }}> Create or use existing credentials to authenticate the client </span>. </p>
      </FadeIn>


      <FadeIn>

        <h1 className="sdkFormH1">Log your predictions </h1>
        <p className="sdkFormText"> Start logging your predictions! </p>
        <p className="sdkFormText"> Here is an example to get started: </p>
        <div className="sdkFormCodeBg">
          <p className="sdkFormCode">  <span style={{ color: '#B7FFFF' }}> from </span> stackstr <span style={{ color: '#B7FFFF' }}> import </span> StackStrClient </p>
          <p className="sdkFormCode"> client = StackStrClient(key, secret, <span style={{ color: '#B7FFFF' }}> '{projectId}'</span>)   </p>
          <p className="sdkFormCode"> <span style={{ color: '#CBABF9' }}> feature_dict  </span> = {"{"}"sepal.length": 5.1, "sepal.width": 3.5, "petal.length": 1.4, "petal.width": 0.2{"}"} </p>
          <p className="sdkFormCode"> <span style={{ color: '#CBABF9' }}> prediction </span> = ['Iris-setosa'] </p>
          <p className="sdkFormCode">
            client.log_prediction(<span style={{ color: '#CBABF9' }}>feature_dict</span>, <span style={{ color: '#CBABF9' }}>prediction</span>)</p>
        </div>
        <br></br>
      </FadeIn>
      <Button onClick={() => window.location.reload()} className="float-left filterDateBtn" style={{ fontSize: 16, height: 40 }}> Go to Project Dashboard</Button>

    </div>
  )

}


export default SdkSetup;