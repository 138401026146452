import React from "react";
import { Icon, Popup } from "semantic-ui-react";
import { Row } from 'reactstrap'
import { TabMenu } from "./TabMenu";
import { VersionDropdown } from "./dropdowns/VersionDropdown";

export const BigBanner = ({
    switchTabs,
    ...restProps
}) => {
    
    return (
        <div style={{position: 'relative', top: '90px'}}>
            <div className="bigWhiteBanner">

                <div style={{paddingLeft: 160, paddingTop: 106, paddingRight: 130}}>
                    
                    <VersionDropdown/>

                    <br></br>

                    <h1 style={{justifyContent: "space-between"}} className="ml-deployment-dashboardTitle"> Model Name
                        <Popup  style={{background: 'black'}} inverted className="float-right" content="Refresh data" trigger={
                        <div> <Icon className="float-right refresh-icon" style={{fontSize: 14, cursor: 'pointer', marginLeft: 10, marginTop: 0}} name="refresh" /> 
                        <p className="refresh-btn"> Refresh </p>
                        </div>} />
                    </h1>
               
                </div>

                <TabMenu switchTabs={switchTabs} />

            </div>

            <div className="dashboard-lighter-mark"></div>
            <div className="gradient-header"></div>


        </div>
    )
}