import React from "react";
import { useHistory } from "react-router";
import { Row } from 'reactstrap'
import "../../../../../styles/landing-pages/ml-deployment.scss"


export const Header = ({
    ...restProps
}) => {
    const history = useHistory()

    // document.getElementById("point-features").scrollIntoView()
    return (
        <div className="ml-deployment-landing-page-header ">
        <div style={{position: 'relative'}} className="container">

          <a style={{cursor: 'pointer', color: 'white'}} onClick={() => window.location.href = '/'} className="stackstr-text"> stackstr </a>

          <Row style={{justifyContent: 'center'}}>
            <a onClick={() =>  history.push('/', {"section": "features"})} className="header-links"> Features </a>
            <a onClick={() =>  history.push('/about')} className="header-links"> About </a>
            <a onClick={() => window.open('https://docs.stackstr.io')} className="header-links"> Docs </a>
            <a onClick={() =>  history.push('/contact')} className="header-links"> Contact </a>
          </Row>

          <div style={{marginTop: -20}} className="float-right">
            <a onClick={() => window.location.href = '/'} style={{marginRight: 0, cursor: 'pointer'}} className="header-action-links"> Get Started </a>
          </div>

        </div>
      </div>
    )
}