import React from "react"
import "../../styles/landing-pages/ml-production.scss"
import { Button, Row, Toast } from 'reactstrap'
import 'aos/dist/aos.css'; 
import FadeIn from 'react-fade-in';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import AOS from 'aos';
import Axios from 'axios';
import { Input } from 'reactstrap';
import Label from 'reactstrap/lib/Label';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BlackBanner } from "../../components/BlackBanner";
  
let slideshow = [
    "METRICS",
    "DATA DRIFT",
    "ALERTS"
]

AOS.init();

// You can also pass an optional settings object
// below listed default settings
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});
  
class MLProduction extends React.Component {
    constructor() {
        super();
        this.state= {
            email: "",
            isLoading: false,
            msg: "",
            requestBeta: true,
            currentSlide: <div>
            <Card className="dashboardPreview">
                <CardBody>
                <video id="ml-production-video" src={require('../../assets/videos/ml_production_video.mp4')} style={{width: '100%'}} height="400" controls="controls" autoplay="true" />
                </CardBody>
            </Card>
        </div>
        }
    }
    setLoading =() => {
        this.setState({ isLoading : false })
    }

    getTimer = async() => {
        await setTimeout(console.log('okay'), 3000);
    }

    pickSlideComponent = async(name) => {
        this.setState({ isLoading : true })
        await this.getTimer().then(() => {
                if (name == "DEPLOYMENT") {
                    this.setState({ currentSlide : <FadeIn> 
                                <Card className="dashboardPreview">
                        <CardBody>
                        <img className="dashboardPreviewGif" style={{width: '100%'}} src={require('../../assets/landing-pages/ml-production/assets/modelupload.jpg')} /> 
                        </CardBody>
                    </Card>
                    </FadeIn>});
                    return   this.setState({ isLoading : false })

                }
                if (name == "DATA DRIFT") {
                    this.setState({ isLoading : false })
                    return this.setState({ currentSlide : <FadeIn> <img style={{width: '100%'}} src={require('../../assets/landing-pages/ml-production/assets/DATADRIFT.svg')} />  </FadeIn>});
                }
                if (name == "METRICS") {
                let dashboardPreview = <div>
                    <Card className="dashboardPreview">
                        <CardBody>
                        <img className="dashboardPreviewGif"  draggable={false} style={{width: '100%', borderRadius: 15}} src={require('../../assets/landing-pages/ml-production/assets/dashboardpic.jpg')} />  
                        </CardBody>
                    </Card>
                </div>
                    this.setState({ isLoading : false })

                    return this.setState({ currentSlide : <FadeIn> {dashboardPreview} </FadeIn>});
                }
                if (name == "ALERTS") {
                    this.setState({ isLoading : false })
                    return this.setState({ currentSlide : <FadeIn> <img style={{width: '90%'}} src={require('../../assets/landing-pages/ml-production/assets/alerts.svg')} />  </FadeIn>});
                }
            })
    }

    clickOnSlide = (slide) => {
        function myFunction(e) {            
            document.getElementById(e).classList.add("active") 
            for (var i in slideshow) {
                if (slide != slideshow[i]) {
                    document.getElementById(slideshow[i]).classList.remove("active")
                    }
                }
            }
          myFunction(slide)
        for (let i in slideshow) {
            if (slideshow[i] == slide) {
                return this.pickSlideComponent(slideshow[i]);
            }
        }
    }

    submitEmail = async(event) => {
     
    }

    render() {

        return (
            <div className="ml-production-home-body"> 
                <ToastContainer transition={Flip} />

            <BlackBanner content={"This platform was built for StackStr and has since been archived."} />
            
                <img className="tinyStackstrLogo" src={require('../../assets/landing-pages/ml-production/assets/stackstrlogo.svg')} />
                <div style={{zIndex: 999}} class="container homeHeader">
                    <Row style={{justifyContent: 'center'}}>
                        <img   style={{zIndex: 999, width: '45px', marginTop: 15}} src={require('../../assets/landing-pages/ml-production/assets/stackstrlogo.svg')} />
                    </Row>
                </div>
                <div className="triangleBody"></div>
                <div className="bottomTriangleBody"></div>
                <br></br>
                <br></br>
                <div class="container fluid" style={{zIndex: 999}}>
                <div class="row" style={{justifyContent: 'center'}}>
                    <div class="col-sm-10">
                    <br></br>
                    <br></br>
                    <br></br>
                    <FadeIn delay={400}>
                    <h1  className="titleMLMonitoring" style={{marginBottom: 19}}> Real-time Production Monitoring of ML Models</h1>
                    </FadeIn>
                        <FadeIn delay={800}>
                        <p className="paragraphMonitoring" style={{marginBottom: 29}}> Track your ML models’ performance with 
                        <span className="paragraphMonitoringBold">  3 lines of code. </span>
                        </p>
                            {
                                this.state.requestBeta ?
                                <Row className="rowCenterpiece">
                                    <Button onClick={() => document.getElementById("section-1").scrollIntoView(true)} className="MLmonitoringBtn" style={{marginRight: 30, marginBottom: '20px'}} > Video Demo </Button>
                                    <Button onClick={() => window.location.href = '/ml-production/dashboard'} className="MLmonitoringBtn" style={{marginRight: 30}}> View Dashboard</Button>
                                </Row>

                                :
                                this.state.msg.length == 0 ?
                                    <FadeIn>
                                        <p style={{textAlign: 'center', color: 'white'}} className="text-center"> Enter your email to join the waitlist </p>
                                        <Label className="whiteLabel"> Email </Label>
                                            <Input id="emailInput" onChange={(text) => this.setState({ email : text.target.value })} placeholder="Enter your email to join the waitlist" />
                                            <button className="btnMonitoring" onClick={this.submitEmail}> Submit </button>                                    
                                    </FadeIn>
                                : 
                                <p style={{textAlign: 'center', color: 'white', fontWeight: 'bold'}}> {this.state.msg} </p>

                            }
                  
                        </FadeIn>
                    </div>
                    </div>
                    </div>
                <br></br>
                <br></br>
                <div class="row" style={{justifyContent: 'center'}}>
                    <div class="col-sm-3">
                        <div data-aos-duration="1300" data-aos="fade-down">

                        <Row style={{justifyContent: 'center', marginBottom: 17}}>
                            <img className="floatingIconDiamond" src={require('../../assets/landing-pages/ml-production/assets/step1diamond.svg')} />
                        </Row>
                        <p className="featureSubTitle" style={{textAlign: 'center', marginBottom: 10}}>Install our Python package </p>
                        <Row style={{justifyContent: 'center'}}>
                            <p className="bulletPointText" style={{textAlign: 'center', width: '344px', marginBottom: 30}}> Import the package, verify your credentials with a secret given, & log your predictions. </p>
                        </Row>
                        <Row style={{justifyContent: 'center', marginBottom: 17}}>
                            <img style={{width: '100%', paddingLeft: '10px', paddingRight: '10px'}} src={require('../../assets/landing-pages/ml-production/assets/installpythonimg.svg')} />
                        </Row>
                        </div>
                    </div>

                    <div class="col-sm-3">
                    <div data-aos-duration="2200" data-aos="fade-down">
                        <Row style={{justifyContent: 'center', marginBottom: 17}}>
                            <img className="floatingIconDiamond" src={require('../../assets/landing-pages/ml-production/assets/step2diamond.svg')} />
                        </Row>
                        <p className="featureSubTitle" style={{textAlign: 'center', marginBottom: 10}}>Login to see your dashboard </p>
                        <Row style={{justifyContent: 'center'}}>
                        <p className="bulletPointText" style={{textAlign: 'center', width: '326px', marginBottom: 30}}> After sending requests, you’ll see metrics recorded on StackStr’s site. </p>
                        </Row>
                        <Row style={{justifyContent: 'center', marginBottom: 17}}>
                            <img style={{width: '100%', paddingLeft: '20px', paddingRight: '10px'}} src={require('../../assets/landing-pages/ml-production/assets/metricdashboard.svg')} />
                        </Row>
                    </div>
                    </div>

                    <div class="col-sm-3">
                    <div data-aos-duration="3000" data-aos="fade-down">
                        <Row style={{justifyContent: 'center', marginBottom: 17}}>
                            <img className="floatingIconDiamond" src={require('../../assets/landing-pages/ml-production/assets/step3diamond.svg')} />
                        </Row>
                        <p className="featureSubTitle" style={{textAlign: 'center', marginBottom: 10}}>Receive notifications </p>
                        <Row style={{justifyContent: 'center'}}>
                        <p className="bulletPointText" style={{textAlign: 'center', width: '302px', marginBottom: 30}}> Create & receive custom alerts via Slack, phone, or email. </p>
                        </Row>
                        <Row style={{justifyContent: 'center', marginBottom: 17}}>
                            <img style={{width: '100%', paddingLeft: '10px'}} src={require('../../assets/landing-pages/ml-production/assets/notificationimg.svg')} />
                        </Row>
                    </div>
                    </div>

                </div>
                <br></br>
               
                <div class="container">
                <Row style={{justifyContent: 'center'}}>
                <div class="col-sm-8">
                    <FadeIn delay="600">
                        <p id="section-1" className="featureTitle" style={{textAlign: 'right', marginBottom: 1}}> The Next Leap </p>   
                    </FadeIn>
                    <p className="featureSubTitle" style={{textAlign: 'right', marginBottom: 8}}> Using a ML Observability Platform </p>
                    <p className="featureText" style={{textAlign: 'right',
                    }}> Mitigate problems by unlocking blackbox models
                    using real-time monitoring. </p>
                    <br></br>
                    {
                        this.state.isLoading?
                        <img src={require('../../assets/landing-pages/ml-production/assets/diamondIcon.svg')} />
                        :
                        this.state.currentSlide
                    }


                    </div>
                    <div class="col-sm">
                    <div style={{marginTop: '30px'}} data-aos-duration="1700" data-aos="fade-up">
                    <div style={{padding: '20px'}} id="METRICS" onClick={(id) => this.clickOnSlide("METRICS")} className="point active">
                    <img src={require('../../assets/landing-pages/ml-production/assets/metrics-icon.svg')} />
                    <p className="bulletPointTitle"> Effectively Measure Performance </p>
                    <p className="bulletPointText" style={{width: 'auto'}}> Use a powerful dashboard to easily manage and analyze your production models. Observe their performance via data metrics & set unique targets for each model. </p>
                    </div>
                    </div>

                    <div data-aos-duration="2200" data-aos="fade-up">
                    <div style={{padding: '20px'}} id="DATA DRIFT" onClick={(id) => this.clickOnSlide("DATA DRIFT")} className="point">
                    <img style={{marginTop: 10, marginBottom: 5}} src={require('../../assets/landing-pages/ml-production/assets/datadetection.svg')} />
                    <p className="bulletPointTitle"> Data Drift Detection  </p>
                    <p className="bulletPointText" style={{width: 'auto'}}> Gain transparency into your model’s health by recording past and current drifts, so you can catch the moments when your model underperforms. </p>
                    </div>
                    </div>

                    <div data-aos-duration="2600" data-aos="fade-up">
                    <div style={{padding: '20px'}} id="ALERTS" onClick={(id) => this.clickOnSlide("ALERTS")} class="point">
                    <img style={{marginTop: 10, marginBottom: 5}} src={require('../../assets/landing-pages/ml-production/assets/resolveProblems.svg')} />
                    <p className="bulletPointTitle"> Trigger Smart Alerts</p>
                    <p className="bulletPointText" style={{width: 'auto'}}> Troubleshoot models and proactively discern changes in data drifts. Set up notifications to detect specific metrics, and receive them within your team’s workspace. </p>
                    </div>
                    </div>
                    </div>
                </Row>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <div class="container">
                <Row style={{justifyContent: 'center'}}>
                    <img src={require('../../assets/landing-pages/ml-production/assets/codingelement.svg')} />
                </Row>
                <p style={{textAlign: 'center', marginBottom: 0}} className="designedForDevelopers"> Designed For Developers </p>
                <p className="featureSubTitle"  style={{textAlign: 'center'}}>  To take us with you everywhere  </p>
                <Row style={{justifyContent: 'center', marginTop: 8}}>
                <p className="featureText" style={{textAlign: 'center', width: 540}}> Provide support for the ML models in your projects, so you can monitor and integrate metrics into other services.   </p>
                </Row>
                </div>
                <img className="designedfordeveloperImg" draggable={false}  src={require('../../assets/landing-pages/ml-production/assets/designedfordeveloper.svg')} />
                <br></br>
                <br></br>
                <br></br>
                <div style={{position: 'relative'}} className="whyStackStrStrip">
                <div className="circleLightGradient"></div>
                    <div className="circleGradient"></div>
                    <div className="circleLightTealGradient"></div>
                    <div className="circleLightestTealGradient"></div>
                <div class="container">
                    <div className="apiBenefits">
                        <img style={{marginBottom: 12}} src={require('../../assets/landing-pages/ml-production/assets/tealdiamond.svg')} />
                        <p style={{marginBottom: 12}} className="whyStackStrSubTitle"> Why StackStr </p>
                        <p className="whyStackStrTitle" style={{textAlign: 'left', paddingBottom: 14}}>  We give your models  <span style={{borderBottom: '1px solid #8BDCFF'}}> confidence </span></p>
                        <Row style={{justifyContent: 'center'}}>
                        <div class="col-sm-3">
                            <div data-aos-duration="1000" data-aos="fade-up">
                                <img style={{marginBottom: 14}} src={require('../../assets/landing-pages/ml-production/assets/dataIntegrity.svg')} />
                            <p className="bulletPointTitle"> Maintain Data Integrity </p>
                            <p className="whyStackStrBulletPointText" style={{marginTop: 7}}>Make sure to spot early signs of performance drops and data drifts before they impact the quality of your AI. </p>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div data-aos-duration="1700" data-aos="fade-up">
                        <img style={{marginBottom: 14}} src={require('../../assets/landing-pages/ml-production/assets/modelBias.svg')} />
                                <p className="bulletPointTitle"> Detect Model Bias </p>
                                <p className="whyStackStrBulletPointText" style={{marginTop: 7}}> Monitor your ML model’s performance over time to maintain quality and to quickly identify critical threats. </p>
                            </div>
                        </div>
                        <div class="col-sm">
                            <div data-aos-duration="2200" data-aos="fade-up">
                        <img style={{marginBottom: 14}} src={require('../../assets/landing-pages/ml-production/assets/platformagnostic.svg')} />
                                <p className="bulletPointTitle"> Platform-Agnostic </p>
                                <p className="whyStackStrBulletPointText" style={{marginTop: 7}}> Serve models developed in any framework, and ingest from any data source. Stackstr’s integrations are easily pluggable, making it flexible to use. </p>
                                </div>
                        </div>
                        <div class="col-sm">
                            <div data-aos-duration="2600" data-aos="fade-up">
                        <img style={{marginBottom: 14}} src={require('../../assets/landing-pages/ml-production/assets/takeaction.svg')} />
                            <p className="bulletPointTitle"> Take Action </p>
                            <p className="whyStackStrBulletPointText" style={{marginTop: 7}}>Set alerts & get notified for a wide range of issues in your model. Use a dashboard to manage, customize, & create alerts all-within-one, notifying all members in your team’s workspace. </p>
                            </div>
                       </div>
                        </Row>
                    </div>
                </div>
                </div>
                <br></br>
                    <div class="container">
                        <br></br>
                        <br></br>
                        <Row style={{justifyContent: 'center'}}>
                        <p className="seamlessIntegration" style={{textAlign: 'center',  marginBottom: 1}}> Seamless Integration </p>
                        </Row>
                        <p className="featureSubTitle"  style={{textAlign: 'center', marginBottom: 14}}>  Because we believe in flexibility </p>
                        <Row style={{justifyContent: 'center', marginTop: 8}}>
                        <p className="featureText" style={{textAlign: 'center', marginTop: 0, width: 300}}> We fit into your existing MLOps infrastructure. </p>
                        </Row>
                        <br></br>
                        <br></br>
                        <Row style={{justifyContent: 'center'}}>
                        <img className="seamlessIntegrationImg" draggable={false} src={require('../../assets/landing-pages/ml-production/assets/seamlessintegration.svg')} />
                        </Row>
                        
                    </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                 <div className="ventureFirms">
                <p style={{textAlign:'center', zIndex: 999}} className="backedBySubText"> BACKED BY </p>
                <div  class="container" style={{zIndex: 999}}>
                <img className="bubbles" style={{position: 'absolute', top: 800, zIndex: 999}} src={require('../../assets/landing-pages/ml-production/assets/bubbles.svg')} />
                <Row style={{justifyContent: 'center'}}>
                <img style={{textAlign:'center', zIndex: 999}} src={require('../../assets/landing-pages/ml-production/assets/knollventures.svg')} />
                <img style={{textAlign:'center', zIndex: 999}} src={require('../../assets/landing-pages/ml-production/assets/outlanderlabs.svg')} />
                <img style={{textAlign:'center', zIndex: 999, marginLeft: 30, marginTop: 30}} src={require('../../assets/landing-pages/ml-production/assets/tenholdings.svg')} />
                
                </Row>
                </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
 


               
            </div>
        )
    }
}

export default MLProduction;