import React, { useState } from 'react';
import { Icon, Image, Reveal } from 'semantic-ui-react'
import {Popup } from 'semantic-ui-react'
import copy from 'copy-to-clipboard';
import { Button } from 'reactstrap'

export const KeyReveal = ({...restProps}) => {
    const [reveal, setReveal] = useState(false)
    const [message, setMessage] = useState("Loading key")
    const [hasKey, setKey] = useState(true)


    const getKey = async() => {
        if (!reveal) {
                setTimeout(() => {
                        setMessage("SAMPLE_API_KEY")
                    setReveal(true)
                }, 1000)
            }
    }

    const createKey = async() => {
        setMessage('Generating key...')
        setTimeout(() => {
            setMessage("SAMPLE_API_KEY")
            setKey(true)
        }, 3000)
    }

    const copyApiKey = () => {
        copy(message);
    }

    return (
        <div className="grayBlockHeader">
           <Reveal animated={reveal ? 'move up' : null}>
            <Reveal.Content visible>
                <Button style={{width: '210px'}} id="API_key" onClick={getKey} className="APIKeyBtn">  Load your API key <Icon style={{marginLeft: 3}} name="key" /> </Button>
            </Reveal.Content>
            <Reveal.Content className="ml-experiment-reveal" style={{width: 206}} hidden>
            <Button style={{borderRadius: '30px', height: 32, width: '210px', background: 'none', border: '1px solid white', color: 'white', fontSize: 13}} onClick={getKey}> 
            {
                hasKey ? 
                <Popup
                content='Copied to clipboard!'
                on='click'
                pinned
                inverted
                position="bottom right"
                trigger={<span style={{fontSize: 11}} onClick={copyApiKey}>  {message} <Icon name="copy outline" />  </span>}
                /> 
                :
            <Popup
                    content='Copied to clipboard!'
                    on='click'
                    inverted
                    pinned
                    position="bottom right"
                    trigger={<span style={{fontSize: 11}} onClick={createKey}>  {message}  <Icon name="copy outline" />  </span>}
                /> 
            }
          
                </Button>
            </Reveal.Content>
        </Reveal>
    </div>
    )
}