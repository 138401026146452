import { Card } from '@material-ui/core';
import React, { useState } from 'react';
import { CardBody, Progress, Row } from 'reactstrap';

const createCardColor = (color) => {
    if (color == 'blue') {
        return 'linear-gradient(298.61deg, rgba(1, 65, 160, 0.1344) 4.3%, rgba(97, 158, 249, 0.56) 74.88%)';
    }
    if (color == 'purple') {
        return 'linear-gradient(298.61deg, rgba(1, 65, 160, 0.3472) 4.3%, rgba(100, 97, 249, 0.56) 74.88%)'
    }
}

const ProgressCard = ({color, style, title, value, percentChange, thresholdValue, ...props}) => {
    const [background, setBackground] = useState(createCardColor(color));

    return (
        <div>
             <Card style={{background: background, overflow: style}} className="progressCard">
                <CardBody style={{padding: '1.4rem'}}>
                <p className="dashboardCardTitle"> {title}</p>
                    <Row style={{justifyContent: 'center'}}>
                        <Card className="dashboardInnerCard">
                            <CardBody>
                                <p className="cardValue" style={{marginTop: 5}}> {Number.parseFloat(value).toFixed(4)} </p>
                            </CardBody>
                        </Card>
                    </Row>
                </CardBody>
                <p className="benchmarkNumber"> THRESHOLD: {thresholdValue} </p>
            </Card>
        </div>
    )
}

export default ProgressCard;