import React from "react";
import moment from "moment";
import { Col, Row, CardBody, Card } from 'reactstrap';
import { Line } from 'react-chartjs-2'
import { getStackBarChart, stackBarChartOptions } from "../../components/graphs/LineGraph";
import { RequestTable } from "../../components/tables/RequestTable";
import { DatePopup } from "../../components/DatePopup";
import { DashboardUtils } from "../utils/DashboardUtil";
import { ToastContainer } from "react-toastify";

export class Overview extends React.Component {
    state={
        version: this.props.version,
        model: this.props.model
    }


    render() {

        return (
            <div>
                <p className="dashboardSubtitle"> Overview 
                    <Row style={{justifyContent: 'flex-end', marginTop: -30}}>

                    <div style={{marginBottom: '12px'}}>
                        <span className="filter-by-text"> Filter by </span>
                        {
                            DashboardUtils.getTimeTabs().reverse().map((tab) => {
                                if (this.state.currTimeTab == tab["name"]) {
                                    return  <button style={{background: '#EA60DC', color: 'white', border: 'none', fontWeight: 600}} className="dateMarkBtn"> {tab["name"]} </button>
                                }
                                return  <button  style={{border: '1px solid white'}} className="dateMarkBtn"> {tab["name"]} </button>
                            })
                        }
                    </div>    

                    <DatePopup switchVersion={this.switchVersion} version={this.state.version} model={this.state.model} getMetrics={this.getMetrics} setTimestamp={this.getTimestamp} />

                    </Row></p>
                        <br></br>
                        <Row>
                            <Col>
                                <Card style={{height: '250px'}} className="dashboardCard">
                                    <CardBody style={{padding: 0}}>
                                        <p className="graph_title"> Latency per request </p>
                                        <p className="graph_subtitle"> {this.state.currTimeTab} </p>

                                        <div style={{height: '129px', width: '100%', position: 'absolute', bottom: '0px'}}>
                                        <Line  
                                            options={stackBarChartOptions()}
                                            data={getStackBarChart({
                                                color: ["#DBACFF", "#E75AD8"],
                                                labels: [1, 2, 3, 4, 5, 6, 7, 8],
                                                data: [10, 2, 42, 16, 23, 15, 20, 8],                                                
                                              })}
                                            />
                                        </div>
                                      
                                      

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                            <Card style={{height: '250px'}} className="dashboardCard">
                                    <CardBody style={{padding: 0}}>
                                    <p className="graph_title"> Requests per second </p>
                                        <p className="graph_subtitle"> {this.state.currTimeTab} </p>

                                        <div style={{height: '129px', width: '100%', position: 'absolute', bottom: '0px'}}>
                                        <Line 
                                            options={stackBarChartOptions()}
                                            data={getStackBarChart({
                                                color: ["#5ABAFF", "#5259FF"],
                                                labels: [1, 2, 3, 4, 5, 6, 7, 8],
                                                data: [20, 18, 11, 3, 3, 15, 5, 20],                                                
                                              })}
                                            />
                                        </div>
                                      
                                      

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <br></br>
                       
                        <p style={{marginBottom: '15px'}} className="dashboardSubtitle"> Request History </p>

                        <RequestTable  />

                        <ToastContainer />
            </div>
        )
    }
}