import _ from 'lodash'
import React, { Component } from 'react'
import { Search, Grid, Header, Segment } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

const sortExpData = (data) => {
  console.log(data)
    const sortedData = []
    Object.keys(data).map((project, i) => {
        sortedData[i] = {
            "title": project,
            "description": "Go to " + project.substring(0, 6) + "'s dashboard"
        }
    })
    console.log(sortedData)
    return sortedData;
}

export default class ExpSearchBox extends Component {
  state = { 
      results: [], 
      value: '', 
      source: this.props.data,
      isLoading: false, 
    }

    updateExp = this.props.updateExp

  handleResultSelect = (e, { result }) => {
    this.updateExp(result.title)
     this.setState({ value: result.title })
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value })

    setTimeout(() => {
      const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
      const isMatch = (result) => re.test(result.title)

      this.setState({
        isLoading: false,
        results: _.filter(sortExpData(this.state.source), isMatch),
      })
    }, 300)
  }

  componentDidUpdate() {
      if (this.props.data != this.state.source) {
          this.setState({ source : this.props.data })
      }
  }

  render() {
    const { isLoading, value, results } = this.state

    return (
      <div style={{marginTop: '10px'}}>
          <Search
            size="small"
            className="ml_experiment_dashboard"
            style={{height: 31, color: 'white'}}
            placeholder={"Search for an experiment"}
            loading={isLoading}
            icon={null}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true,
            })}
            results={results}
            value={value}
          />
      </div>
    )
  }
}