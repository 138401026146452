import React from 'react';
import { Line } from 'react-chartjs-2';
import FeatureCards from '../cards/FeatureCards';
import { getStackLineChart, stackLineChartOptions, getStackLineChartPlain } from './Chart';


const PredictionLogs = ({
    modelData,
    metrics,
    ...restProps
    }) => {
        return (
            <div>
            <FeatureCards
                title="ATTRIBUTES"
                content={
                    <Line 
                        options={stackLineChartOptions} 
                        data={getStackLineChartPlain({
                        metrics: metrics,
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                        data:[
                            {
                            label: "Production",
                            data: [33, 53, 85, 41, 44, 65],
                            },
                            {
                            label: "Training",
                            data: [33, 25, 35, 51, 54, 76],                
                            }
                        ]
                    })} />
                    }
            />
        <br></br>
    </div>
    )
}

export default PredictionLogs;