import React, { Component } from 'react'
import { CodeEditorEditable } from 'react-code-editor-editable'
import 'highlight.js/scss/ir-black.scss'
import FadeIn from 'react-fade-in';
import { ExperimentUtils } from '../utils/ExperimentUtils';

const writeCode = (code) => {
    code = ExperimentUtils.getExpDetails()["details"]
    var parsedCode = ``;

    if (code["requirements"] != null) {
        code["requirements"].map((name) => { 
            parsedCode += name + '\n' 
        }) 
    };
    return parsedCode;
};

export default class PackagePreview extends Component {
    
    state = { value: writeCode("") }

    render() {
        return (
            <FadeIn>
                <div className='center'>
                    <CodeEditorEditable
                        borderRadius="0px"
                        tabSize={5}
                        caretColor="agate"
                        value={this.state.value}
                        setValue={(value) => this.setState({ value : value })}
                        width='50vw'
                        height='60vh'
                        language='python'
                        inlineNumbers
                    />
                </div>
        </FadeIn>
        )
    }
}