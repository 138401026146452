import React, { Component } from "react";
import moment from "moment";
import { Table, Row } from "reactstrap";
import { Icon, Menu } from "semantic-ui-react";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import { ExpandCodeModal } from '../../dashboard/components/modals/ExpandCodeModal'

export class RequestTable extends Component {
    state={
        requestInfo: {
            "request_info": [{
                "status": 200, 
                "prediction": "[[4]]",
                "latency_ms": 231,
                "request": "[[1, 2, 3, 4]]",
                "error": "None",
                "timestamp": "09/07/2020, 4:12 pm"
            }, {
                "status": 200, 
                "prediction": "[[3]]",
                "latency_ms": 345,
                "request": "[[3, 10, 3, 1]]",
                "error": "None",
                "timestamp": "09/07/2020, 5:32 pm"
            }, {
                "status": 200, 
                "prediction": "[[1]]",
                "latency_ms": 239,
                "request": "[[50, 2, 6, 9]]",
                "error": "None",
                "timestamp": "09/07/2020, 9:40 pm"
            }, {
                "status": 200, 
                "prediction": "[[10]]",
                "latency_ms": 939,
                "request": "[[24, 32, 0, 10]]",
                "error": "None",
                "timestamp": "09/07/2020, 11:24 pm"
            }]
        },
        tableIndex: 0,
        version: "v0.0.1",
        isLoading: false,
    }

    render() {
        if (this.state.isLoading) {
            return  <div style={{paddingTop: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center',  paddingLeft: 160, paddingBottom: '80px', paddingRight: 100}}>
                <Row style={{position: 'absolute'}}>
                    <CircularProgress style={{position: 'relative'}} className="center-loader" color="secondary" />
                </Row>
            </div>
        }

        return (
            <div>
                <Table className="tableProjectBg">
                        <div className="table-top-header" style={{padding: 13, paddingLeft: 15, width: '400%'}}>
                        <p  className="tableTitle" style={{marginBottom: 0, padding: '5px'}}> Request Information </p>
                        {/* <Button className="float-right"> Sort By </Button> */}
                        </div>
                            <tr>
                                <td className="cellTitle"> TIMESTAMP  </td>
                                <td className="cellTitle"> BODY </td>
                                <td className="">  </td>
                                <td>  </td>
                                <td className="cellTitle"> STATUS </td>
                                <td className="cellTitle"> PREDICTION </td>
                                <td className="cellTitle"> LATENCY_MS </td>
                                
                                <td className="cellTitle"> ERRORS </td>

                            </tr>
                        <tbody>
                            {
                                this.state.requestInfo.request_info.map((requests) => {
                                    console.log(requests)
                
                                    return (
                                        <tr>
                                            <td className="cellText"> {requests.timestamp} </td>
                                            <td className="cellText"> {requests.request}   </td>
                                            <td> </td>
                                            <td> </td>
                                            <td className="cellText"> {requests.status} </td>
                                            <td className="cellText"> {requests.prediction} </td>
                                            <td className="cellText"> {requests.latency_ms} </td>
                                            <td className="cellText"> {requests.error} </td>
                                        </tr>
                                    )
                                })
                            }

                        <tr class="no-border">
                            <td>  </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td>  </td>
                            <td>   </td>
                        </tr>
                        <tr className="table-bottom-mark" style={{paddingBottom: '30px'}}>
                                <td className="cellTitle table-bottom-mark"> </td>
                                <td className="cellTitle" style={{textAlign: 'center', background: '#212124', borderBottom: 'none'}}>  </td>

                                <td className="cellTitle" style={{textAlign: 'center', background: '#212124'}}>  </td>
                                <td className="cellTitle" style={{textAlign: 'center', background: '#212124'}}>  </td>

                                <td className="cellTitle" style={{textAlign: 'center', background: '#212124'}}>  </td>
                                <td className="cellTitle" style={{textAlign: 'center', background: '#212124'}}> </td>
                                <td className="cellTitle" style={{textAlign: 'center', background: '#212124'}}> </td>
                                <td className="cellTitle" style={{textAlign: 'center', background: '#212124'}}> 
                                
                                <Menu className="float-right">
                                    <Menu.Item>                     
                                        <Icon style={{cursor: 'pointer'}} onClick={this.nextPage} name='chevron right' />
                                    </Menu.Item>
                                </Menu>  
                                
                                </td>

                                <br></br>
                                <br></br>
                            </tr>
                        </tbody>

                    
                    </Table>
            </div>
        )
    }

}