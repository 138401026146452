import React from 'react';
import { Button, Row, Toast, Col, ButtonGroup, Modal, ModalHeader, Label, ModalFooter, ModalBody } from 'reactstrap'
import 'aos/dist/aos.css'; 
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import 'react-toastify/dist/ReactToastify.css';
import { OverviewMetrics } from '../../pages/landing-pages/components/ml-experimentation/components/OverviewMetrics';
import { ExperimentUtils } from '../../pages/landing-pages/components/ml-experimentation/components/utils/ExperimentUtils';
import 'semantic-ui-css/semantic.min.css'
import { ExperimentsMenu } from '../../pages/landing-pages/components/ml-experimentation/components/ExperimentsMenu';
import { DashboardHeader } from '../../pages/landing-pages/components/ml-experimentation/components/DashboardHeader';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import FadeIn from 'react-fade-in';
import { Input } from 'semantic-ui-react';
import { toast, ToastContainer } from 'react-toastify';
import { Slide } from '@material-ui/core';
import Particles from 'react-particles-js'

import "../../styles/landing-pages/ml-experimentation.scss"
import { BlackBanner } from '../../components/BlackBanner';

AOS.init();

var dashboardClick = 0
var receivedModal = false


AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
    
  
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 900, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  
  });

class MLExperimentation extends React.Component {
    constructor() {
        super();
        this.state= {
            email: "",
            currentTab: "",
            expId: "6096fe3b029464f12b17c253",
            view: "all_exp",
            pageComponent: [],
            email: "",
            name: "",
            company: "",
            interested: false,
            isLoading: false,
            modal: false
        }
    }

    updateTabData = (currentTab) => {
        this.setState({ currentTab : currentTab });
        this.setState({ pageComponent : ExperimentUtils.TabManager(currentTab, this.state.expId) });
    };

    switchExpData = (expID) => {
        this.setState({ expId : expID })
        this.setState({ view : "single_exp" })
        this.updateTabData("metrics")
        this.setState({ pageComponent : ExperimentUtils.TabManager(this.state.currentTab, expID) });
    };

    clickOnDashboard = () => {
        dashboardClick += 1
        if (dashboardClick == 3) {
            dashboardClick = 0;
            if (!receivedModal) {
                this.setState({ modal : true })
                receivedModal = true
            }
        }
    }

    goToAllExp = () => {
        this.setState({ view : "all_exp" })
    };

    toggle = () => {
        this.setState({ modal : false })
    };

    submitInfo = () => {};

    componentDidMount() {
        this.updateTabData("metrics");
    };

    render() {
        const expColors = {}
        const metrics = ExperimentUtils.getAllExpData()["metrics"];

        console.log(this.state.email, this.state.name, this.state.company)

        {
            Object.keys(metrics).map((expId, idx) => {
                const chooseGrad = idx % ExperimentUtils.getExpGrads().length
                expColors[expId] = {
                    "color": ExperimentUtils.getExpGrads()[chooseGrad]["color"]
                }
            })
        }        

        return (
            <div className="ml-experimentation-home-body"> 

            <BlackBanner content="This platform was built for StackStr and has since been archived" />

            <div style={{position: 'relative', zIndex: 999}}>
                    <img className="blobHeaderImg" style={{position: 'absolute', top: '40px', width: '80%'}} src={require("../../assets/landing-pages/ml-experimentation/assets/blobheader.svg")} />

                <div className="section1">
                    <Row>
                        <Col className="mainpicture">
                        <img   style={{width: '500px', marginTop: '-100px', float: 'right',}} src={require('../../assets/landing-pages/ml-experimentation/assets/mainpicture.svg')} />
                        </Col>
                        <Col>
                        
                        <p className="mlTitle"> Ditch your </p>
                        <p className="mlSubtitle"> tensorboard. </p>
                        <br></br>
                        <Row>
                            <Col className="curiousLargeBtn" sm={3}>
                                <Button onClick={() => window.location.href = '/ml-experiment/dashboard'}  className="contactUsBtn"> View Site </Button>
                            </Col>
                            <Col>
                                <p className="mlDesc">View all of your training runs in one dashboard & easily compare hyperparameters to make sure your models stay optimized.  </p>
                            </Col>
                            <Col className="curiousMiniBtn" sm={3}>
                                <Button onClick={() => document.getElementById("scribblesImg").scrollIntoView({behavior: 'smooth'})} className="contactUsBtn"> View Site </Button>
                            </Col>
                        </Row>
                        </Col>
                    </Row>
                </div>

                <br></br>
                <br></br>

                <div> 
                <div className="section_2">
                <Row id="scribblesImg" style={{justifyContent: 'center', marginBottom: 1}}>
                    <img src={require('../../assets/landing-pages/ml-experimentation/assets/scribbles.svg')} />
                </Row>

                <p id="interactiveDashboardDemo" className="text-center interactTitle"> Interact directly with Voyager’s dashboard </p>
                <Row style={{justifyContent: 'center'}}>
                    <p className="text-center interactDesc"> Play with the dashboard! Drag your mouse to select a region of the graph to inspect, or hover over datapoints. </p>
                </Row>
                <br></br>
                </div>

                <Row className="desktopMsg" style={{justifyContent: 'center', marginBottom: 1}}>
                <p class="desktopMsg" style={{color: 'white'}}> Need to be on a desktop, or have a screen resolution of at least 1300px to play with the dashboard. </p>
                </Row>

                {/* <p style={{color: 'white'}} className="text-center"> Choose a view </p> */}

                <div class="experimentalBtn">
                <Row style={{justifyContent: 'center'}}>
                    <ButtonGroup>
                        <Button className={this.state.view == "all_exp" ? "highlightBtn": ""} style={{background: 'none', fontSize: '14px'}} onClick={() => this.setState({ view : 'all_exp'})}> All Experiments </Button> | 
                        <Button className={this.state.view != "all_exp" ? "highlightBtn": ""} style={{background: 'none', fontSize: '14px'}} onClick={() => this.setState({ view : 'single_exp'})}> Single Experiment </Button>
                    </ButtonGroup>
                </Row>
                </div>

                <br></br>
                
                <br></br>
                <div data-aos="fade-up">
                {
                    this.state.view == "all_exp" ?
                    <div onClick={this.clickOnDashboard} class="row" style={{justifyContent: 'center'}}>

                        <Card className="dashboardBg interactiveDashboardContainer" style={{ borderRadius: '100px', height: '950px', border: 'none'}}>
                        <DashboardHeader goToAllExp={this.goToAllExp} updateExp={this.switchExpData} experiments={metrics} />
                        <ExperimentsMenu updateExp={this.switchExpData} expColors={expColors} experiments={metrics} />
                        <div id="experimentBanner" className="experimentBanner">
                            <p style={{marginTop: 24}}> <span className="experimentBannerTitle"> Dashboard</span> <span> </span></p>
                        </div>
                            <div id="dashboardBody" className="dashboardMetricsBody">
                                    <OverviewMetrics
                                        metrics={metrics}
                                        expColors={expColors}
                                    />
                            </div>

                        </Card>
                    </div>
                    :

                    <div>

                <div onClick={this.clickOnDashboard}  class="row" style={{justifyContent: 'center'}}>
                    <Card className="dashboardBg interactiveDashboardContainer" style={{borderRadius: '100px', height: '950px', border: 'none'}}>
                    <DashboardHeader  goToAllExp={this.goToAllExp}  updateExp={this.switchExpData} experiments={metrics} />
                    <ExperimentsMenu updateExp={this.switchExpData} expColors={expColors} experiments={metrics} />
                    <div id="experimentBanner" className="experimentBanner">
                        <p style={{marginTop: 24}}> <span className="experimentBannerTitle"> Dashboard</span> <span> </span></p>
                    </div>
                        <div id="dashboardBody" className="dashboardMetricsBody">
                        <Row>
                            {
                                ExperimentUtils.TabNames().map((tab) => {
                                    return (
                                        <p onClick={() => this.updateTabData(tab["route"])} style={{cursor: 'pointer'}} className="dashboardMetricsTabs"> {tab["name"]} </p>
                                    )
                                })
                            }
                        </Row>
                        {this.state.pageComponent}
                    </div>
                    </Card>
                                </div>
                                </div>
                    }
                <br></br>
                <br></br>
                <br></br>

                <ToastContainer />

             

        </div>
        </div>
        </div>
        <Particles
                       style={{position: 'absolute', height: '330vh', top: 0, left: 0}}
                    params={
                        {
                          "particles": {
                            "number": {
                              "value": 71,
                              "density": {
                                "enable": true,
                                "value_area": 800
                              }
                            },
                            "color": {
                              "value": "#fcd7ff"
                            },
                            "shape": {
                              "type": "circle",
                              "stroke": {
                                "width": 0,
                                "color": "#000000"
                              },
                              "polygon": {
                                "nb_sides": 5
                              },
                              "image": {
                                "width": 100,
                                "height": 100
                              }
                            },
                            "opacity": {
                              "value": 0.4,
                              "random": false,
                              "anim": {
                                "enable": false,
                                "speed": 1,
                                "opacity_min": 0.1,
                                "sync": false
                              }
                            },
                            "size": {
                              "value": 3,
                              "random": true,
                              "anim": {
                                "enable": false,
                                "speed": 0,
                                "size_min": 0.1,
                                "sync": false
                              }
                            },
                            "line_linked": {
                              "enable": true,
                              "distance": 150,
                              "color": "#ffffff",
                              "opacity": 0.4,
                              "width": 1
                            },
                            "move": {
                              "enable": true,
                              "speed": 1,
                              "direction": "none",
                              "random": false,
                              "straight": false,
                              "out_mode": "out",
                              "bounce": false,
                              "attract": {
                                "enable": false,
                                "rotateX": 600,
                                "rotateY": 1200
                              }
                            }
                          },
                          "interactivity": {
                            "detect_on": "canvas",
                            "events": {
                              "onhover": {
                                "enable": true,
                                "mode": "repulse"
                              },
                              "onclick": {
                                "enable": true,
                                "mode": "push"
                              },
                              "resize": true
                            },
                            "modes": {
                              "grab": {
                                "distance": 400,
                                "line_linked": {
                                  "opacity": 1
                                }
                              },
                              "bubble": {
                                "distance": 400,
                                "size": 40,
                                "duration": 2,
                                "opacity": 8,
                                "speed": 3
                              },
                              "repulse": {
                                "distance": 200,
                                "duration": 0.2
                              },
                              "push": {
                                "particles_nb": 4
                              },
                              "remove": {
                                "particles_nb": 2
                              }
                            }
                          },
                          "retina_detect": true
                        }}
                />        
            </div>
        )
    }
}

export default MLExperimentation;