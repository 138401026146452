import React, { Component } from "react";
import FadeIn from "react-fade-in";
import { ExperimentUtils } from "../utils/ExperimentUtils";

export class Hyperparam extends Component {

    state = {
        hyperParam: []
    }

    getHyperParams = () => {
        return this.setState({ hyperParam : ExperimentUtils.getExpDetails()["details"]["parameters"] });
    };

    componentDidMount() {
        this.getHyperParams();
    };

    render() {
        console.log(this.state.hyperParam)
        return (
            <div>
                <FadeIn>
                    <br></br>
                    <div>
                        <table style={{background: 'none', borderRadius: '30px'}} class="ui inverted hover celled striped table">
                        <thead>
                        <tr>
                            <th colspan="3"> Hyperparameters </th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(this.state.hyperParam).map((param) => {
                                    return (
                                        <tr>
                                        <td class="collapsing">
                                            <i class="settings icon"></i> {param}
                                        </td>
                                            <td> {this.state.hyperParam[param]}</td>
                                            <td class="right aligned collapsing"></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        </table>
                    </div>
                </FadeIn>
            </div>
        )
    }
}