import ChartJS from 'chart.js';
import 'chartjs-plugin-style';

export const getStackBarChart = ({ color, labels, data }) => canvas => {
    console.log(color)

  const ctx = canvas.getContext('2d');

    let gradient = ctx.createLinearGradient(0, 0, 0, 255);

    gradient.addColorStop(
    .3,
    ChartJS.helpers
        .color(color[0])
        .alpha(.8)
        .rgbString()
    );
    gradient.addColorStop(
    .5,
    ChartJS.helpers
            .color(color[1])
            .alpha(.80)
        .rgbString()
    )
    

        return {
            labels,
            data,
            datasets: [
              {
                data: data,
                borderColor: '#FAF9FF',
                backgroundColor: gradient,
                pointBackgroundColor: color[1],
                pointBorder: "white",
                pointHoverRadius: 5,
                broderRadius: 6,
                borderWidth: 1,
                
                pointHoverBackgroundColor: 'white'
              },
        
            ],
        }
};

export function stackBarChartOptions(xAxisLabel, yAxisLabel) {
        const chartDesign = {
            responsive: true,
            datasetStrokeWidth: 20,
            datasetFill: false,
            pointDot: false,
            showTooltips: false,
            scaleFontColor: "rgba(0,0,0,0)",
            scaleLineColor: "rgba(0,0,0,0)",
            scaleShowGridLines: false,
            datasetFill: false,
            maintainAspectRatio: false,
            legend: {
                display: false,
                position: "right",
                align: "middle",
                labels: {
                    fontColor: 'rgb(255, 255, 255)'
                }
            },
            tooplips: {
                shadowOffsetX: 3,
                shadowOffsetY: 3,
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
            },

            tooltips: {
            intersect: true,
               
            },
            elements: {
                point:{
                    borderColor: 'white',
                    pointRadius: 4,
                }
            },
            scales: {
            xAxes: [
                {
                display: false,
                type: 'time',
                gridLines: {
                    display: true,
                    color: 'rgba(168, 162, 162, 0.12)',
                    lineWidth: 1
                },
                time: {
                    parser: 'MM/DD/YYYY hh:mm:ss',
                    tooltipFormat: 'll hh:mm:ss',
                    unit: 'day',
                    unitStepSize: 1,
                    displayFormats: {
                      'day': 'MM/DD/YYYY'
                    }
                },
                scaleLabel: {
                    labelString: xAxisLabel,
                    display: true,
                    fontColor: 'black',
                },
                ticks: {
                    callback: function(value) { 
                        console.log(value)
                        return new Date(value).toLocaleDateString('de-DE', {month:'short', year:'numeric'}); 
                    },
                   
                    fontColor: "black", // this here
                    min: 0
                },
            },
            ],
            yAxes: [{ 
                display:false,   
                scaleLabel: {
                    display: true,
                    labelString: yAxisLabel,
                    fontColor: 'black',
                },  
                gridLines: {
                    display: true,
                    color: 'rgba(168, 162, 162, 0.12)',
                
                lineWidth: 1
            },  ticks: {
                fontColor: "black", // this here
            },
            }],
        },
    }
    return chartDesign;
};

