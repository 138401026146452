import ChartJS from 'chart.js';
import 'chartjs-plugin-style';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from "moment"

export const getStackBarChart = ({ labels, data }) => canvas => {

  const ctx = canvas.getContext('2d');

  data.map(data => {
    let gradient = ctx.createLinearGradient(0, 0, 0, 255);
    {
        gradient.addColorStop(
        .0,
        ChartJS.helpers
            .color(data["firstColor"])
            .alpha(.00)
            .rgbString()
        );
        gradient.addColorStop(
        .0,
        ChartJS.helpers
            .color(data["secondColor"])
            .alpha(.00)
            .rgbString()
        )
    }
    data["backgroundColor"] = gradient;
  })

  return {
    labels,
    type: 'scatter',
    data,
    datasets: data,
  };
};

export function stackBarChartOptions(xAxis, yAxis) {
        const chartDesign = {
            plugins: {
                zoom: {
                    pan: {
                    enabled: false,
                    mode: 'xy',
                    rangeMin: {
                            x: 0,
                            y: 0,
                        },
                    },
                    zoom: {
                        drag: true,
                        enabled: true,
                        threshold: 3,
                        sensitivity: 0.5,
                        mode: 'xy',
                        rangeMin: {
                            x: 0,
                            y: 0,
                        },
                    }
                }
              },
              
            legend: {
                display: true,
                position: "right",
                align: "middle",
                labels: {
                    fontColor: 'rgb(255, 255, 255)'
                }
            },
            tooplips: {
                shadowOffsetX: 3,
                shadowOffsetY: 3,
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
            },

            tooltips: {
            intersect: true,
                callbacks: {
                    title: (tooltipItems, data) =>  data.datasets[tooltipItems[0].datasetIndex].label,
                },
            },
            elements: {
                point:{
                    radius: 3
                }
            },
            scales: {
            xAxes: [
                {
                display: true,
                gridLines: {
                    display: true,
                    color: 'rgba(168, 162, 162, 0.12)',
                    lineWidth: 1
                },
                scaleLabel: {
                    labelString: xAxis,
                    display: true,
                    fontColor: '#FFF',
                },
                ticks: {
                    userCallback: function(label, index, labels) {
                        return label.toFixed(3);
                    },
                    fontColor: "#FFF", // this here
                    min: 0
                },
            },
            ],
            yAxes: [{ 
                display:true,   
                scaleLabel: {
                    display: true,
                    labelString: yAxis,
                    fontColor: '#FFF',
                },  
                gridLines: {
                    display: true,
                    color: 'rgba(168, 162, 162, 0.12)',
                
                lineWidth: 1
            },  ticks: {
                fontColor: "rgba(255, 255, 255, 0.76)", // this here
            },
            }],
        },
    }
    return chartDesign;
};


export function timeStampOptions(xAxis, yAxis) {
        const chartDesign = {
            plugins: {
                zoom: {
                    limits: {
                        x: {min: 0},
                        y: {min: 0}
                    },
                    pan: {
                        enabled: true,
                        mode: 'xy',
                    },
                    zoom: {
                        enabled: true,
                        mode: 'xy',
                    }
                }
            },
            legend: {
                display: true,
                position: "right",
                align: "middle",
                labels: {
                    fontColor: 'rgb(255, 255, 255)'
                }
            },
            tooplips: {
                shadowOffsetX: 3,
                shadowOffsetY: 3,
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
            },

            tooltips: {
            intersect: true,
                callbacks: {
                    title: (tooltipItems, data) =>  data.datasets[tooltipItems[0].datasetIndex].label,
                },
            },
            elements: {
                point:{
                    radius: 3
                }
            },
            scales: {
            xAxes: [
                {
                display: true,
                gridLines: {
                    display: true,
                    color: 'rgba(168, 162, 162, 0.12)',
                    lineWidth: 1
                },
                scaleLabel: {
                    labelString: xAxis,
                    display: true,
                    fontColor: '#FFF',
                },
                ticks: {
                    userCallback: function(label, index, labels) {
                        return moment(label).format("MM/DD/YY h:mm");
                    },
                    fontColor: "#FFF", // this here
                },
            },
            ],
            yAxes: [{ 
                display:true,   
                scaleLabel: {
                    display: true,
                    labelString: yAxis,
                    fontColor: '#FFF',
                },  
                gridLines: {
                    display: true,
                    color: 'rgba(168, 162, 162, 0.12)',
                
                lineWidth: 1
            },  ticks: {
                fontColor: "rgba(255, 255, 255, 0.76)", // this here
            },
            }],
        },
    }
    return chartDesign;
};


export function chartWithoutLegends(xAxis, yAxis) {
    const chartDesign = {
        plugins: {
            zoom: {
                limits: {
                    x: {min: 0},
                    y: {min: 0}
                },
                pan: {
                    enabled: true,
                    mode: 'xy',
                },
                zoom: {
                    enabled: true,
                    mode: 'xy',
                }
            }
        },
        legend: {
            display: false,
            position: "right",
            align: "middle",
            labels: {
                fontColor: 'rgb(255, 255, 255)'
            }
        },
        tooplips: {
            shadowOffsetX: 3,
            shadowOffsetY: 3,
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
        },

        tooltips: {
        intersect: true,
            callbacks: {
                title: (tooltipItems, data) =>  data.datasets[tooltipItems[0].datasetIndex].label,
            },
        },
        elements: {
            point:{
                radius: 3
            }
        },
        scales: {
        xAxes: [
            {
            display: true,
            gridLines: {
                display: true,
                color: 'rgba(168, 162, 162, 0.12)',
                lineWidth: 1
            },
            scaleLabel: {
                labelString: xAxis,
                display: true,
                fontColor: '#FFF',
            },
            ticks: {
            
                userCallback: function(label, index, labels) {
                    console.log('ticks')
                    return label;
                },
                fontColor: "#FFF", // this here
            },
        },
        ],
        yAxes: [{ 
            display:true,   
            scaleLabel: {
                display: true,
                labelString: yAxis,
                fontColor: '#FFF',
            },  
            gridLines: {
                display: true,
                color: 'rgba(168, 162, 162, 0.12)',
            
            lineWidth: 1
        },  ticks: {
            fontColor: "rgba(255, 255, 255, 0.76)", // this here
        },
        }],
    },
}
return chartDesign;
};