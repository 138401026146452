import React from "react";
import { HomeSideBar } from "../../components/HomeSideBar";
import { Button } from 'reactstrap'
import { Icon } from "semantic-ui-react";
import { KeyReveal } from "../../components/KeyReveal";
import { UserBanner } from "../../components/dropdown/UserDropdown";

export const Header = ({...restProps}) => {
    return (
        <div>
            <div className="projectHeader">
                <row style={{paddingLeft: 150, paddingTop: 14}}>
                    <Button className="backBtn" onClick={() => window.location.href = '/'} > <Icon style={{marginRight: '4px', position: 'relative', top: '-1px', fontSize: '10px', }} name="arrow left" /> 
                    <span style={{fontSize: '15px', fontWeight: 500}}> Back </span> </Button>
                <div style={{background: '#222222',position: 'absolute', left: '270px', top: '0px', height: '69px', width: '2px'}}></div>
                <div className="dashboardHeaderLink" style={{background: 'red'}}>
                <div style={{right: '250px', background: '#222222'}} className="projectHeaderDivider"></div>
                    <div>
                        <Icon style={{color: 'white', position: 'absolute', left: '300px', top: 20, fontSize: '12px' }} name="grid layout" />
                        <div style={{right: '250px', background: '#222222'}} className="projectHeaderDivider"></div>
                    <p style={{position: 'absolute', left: 330, top: 20, fontWeight: 500, fontSize: '15px'}} className="dashboardHeaderLink"> Dashboard </p>
                </div>
            </div>
            <div style={{background: '#222222',position: 'absolute', left: '440px', top: '0px', height: '69px', width: '2px'}}></div>
        </row>

        <div>  
            <div style={{right: '250px'}} className="projectHeaderDivider"></div>
                <a onClick={() => window.open("https://docs.stackstr.io")} style={{position: 'absolute', top: '27px', right: '190px', cursor: 'pointer'}} className="projectHeaderLinks"> Help </a>
            <div style={{right: '160px'}} className="projectHeaderDivider" ></div>

            <KeyReveal />

            <div className="avatarIcon">
                <UserBanner />
            </div>

            </div>
     

        </div>



            <HomeSideBar />
        </div>
    )
}