import ChartJS from 'chart.js';
import 'chartjs-plugin-style';
import { useEffect, useState } from 'react';
export const getStackLineChart = ({ labels, animation, data, data1 }) => canvas => {
  const ctx = canvas.getContext('2d');
  let gradient = ctx.createLinearGradient(0, 0, 0, 255);
  {
  gradient.addColorStop(
    .1,
    ChartJS.helpers
      .color('#4850FF')
      .alpha(.46)
      .rgbString()
  );
  gradient.addColorStop(
    .8,
    ChartJS.helpers
      .color('#8BDCFF')
      .alpha(0)
      .rgbString()
    )
  }
  return {
    labels,
    data,
    datasets: [
      {
        data: data,
        label: 'Accuracy Rate',
        borderColor: '#8BDCFF',
        backgroundColor: gradient,
    
      },

    ],
  };
};

const sortMetricBody = (metrics) => {
  const jsonBody = metrics
  const xValues = Object.keys(jsonBody)
  var createMetricBodies = {}
  Object.keys(jsonBody).map((day) => {
      Object.keys(jsonBody[day]).map(metrics => {
          if (!createMetricBodies[metrics]) {
              createMetricBodies[metrics] = []
              createMetricBodies[metrics].push(jsonBody[day][metrics])
          } else {
              createMetricBodies[metrics].push(jsonBody[day][metrics])
          }
      })
  })
  return {"x_values": xValues, "y_values": createMetricBodies}
}

export const getStackLineChartPlain = ({ labels, typeMetric, metrics, animation, data, data1 }) => canvas => {
  
  // var x_values = sortMetricBody(metrics["metrics"])["x_values"]
  var y_values = sortMetricBody(metrics)["y_values"]

  const ctx = canvas.getContext('2d');
  let gradient = ctx.createLinearGradient(0, 0, 0, 255);
  {
  gradient.addColorStop(
    .2,
    ChartJS.helpers
    .color('#30B1DA')
    .alpha(.66)
      .rgbString()
  );
  gradient.addColorStop(
    .8,
    ChartJS.helpers
    .color('#30B1DA')
    .alpha(0.1)
      .rgbString()
    )
  }
  let gradient1 = ctx.createLinearGradient(0, 0, 0, 255);
  {
    gradient1.addColorStop(
      .1,
      ChartJS.helpers
      .color('#547AFF')
      .alpha(.66)
        .rgbString()
    );
    gradient1.addColorStop(
      .8,
      ChartJS.helpers
      .color('#4850FF')
      .alpha(0.1)
        .rgbString()
      )
    }

    let gradient2 = ctx.createLinearGradient(0, 0, 0, 255);
    {
      gradient2.addColorStop(
        .1,
        ChartJS.helpers
        .color('#FF77D9')
        .alpha(.66)
          .rgbString()
      );
      gradient2.addColorStop(
        .8,
        ChartJS.helpers
        .color('#FF77D9')
        .alpha(0.1)
          .rgbString()
        )
      }
  
      let gradient3 = ctx.createLinearGradient(0, 0, 0, 255);
    {
      gradient3.addColorStop(
        .1,
        ChartJS.helpers
        .color('#6461F9')
        .alpha(.66)
          .rgbString()
      );
      gradient3.addColorStop(
        .8,
        ChartJS.helpers
        .color('#6461F9')
        .alpha(0.3)
          .rgbString()
        )
      }

  
  return {
    labels,
    data,
    datasets: 
        [
            {
              label: "Accuracy",
              data: [1, 2, 3, 4, 5, 6, 6, 7, 8, 9, 10, 11, 12],
              borderColor: '#FF77D9',
              backgroundColor: gradient2,
            },
            {
              label: "Recall",
              data: [10, 2, 3, 4, 5, 6, 6, 7, 8, 9, 10, 11, 12],              
              borderColor: '#598BE3',
              backgroundColor: gradient3,
            },
            {
              label: "F_1",
              data: [1, 20, 3, 4, 5, 6, 6, 7, 8, 9, 10, 11, 12],             
              borderColor: '#6461F9',
              backgroundColor: gradient1,
            },
            {
              label: "Precision",
              data: [20, 2, 3, 4, 5, 6, 60, 7, 8, 9, 10, 11, 12],              
              borderColor: '#79DFFF',
              backgroundColor: gradient,
            }
          ]
  };
};

export const getStackLineChartGrad = ({ labels, animation, data, data1 }) => canvas => {
  const ctx = canvas.getContext('2d');
  let gradient = ctx.createLinearGradient(0, 0, 0, 255);
  {
  gradient.addColorStop(
    .1,
    ChartJS.helpers
      .color('#7DACF2')
      .alpha(1)
      .rgbString()
  );
  gradient.addColorStop(
    .8,
    ChartJS.helpers
      .color('#7DACF2')
      .alpha(.6)
      .rgbString()
    )
  }
  return {
    labels,
    data,
    datasets: [
      {
        data: data,
        label: 'Accuracy Rate',
        borderColor: '#8BDCFF',
        backgroundColor: gradient,
        shadowOffsetX: 3,

      shadowOffsetY: 3,

      shadowBlur: 10,

      shadowColor: 'rgba(0, 0, 0, 0.5)',
      },

    ],
  };
};
export const stackLineChartOptionsGray = {

  tooltips: {
    intersect: true,
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: true,
          color: ' rgba(191, 196, 205, 0.26)',
          lineWidth: 1
        },
        ticks: {
          fontColor: "#90989F", // this here
        },
      },
      
    ],
    yAxes: [{ display:true,   gridLines: {
      display: true,
      color: ' rgba(191, 196, 205, 0.26)',
      lineWidth: 1
    },  ticks: {
      beginAtZero: true,
      fontColor: "#90989F", // this here
    },
  }],
  },
  legend: {
    display: false,
  },

};



export const stackLineChartOptions = {

    tooltips: {
      intersect: true,
      backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    elements: {
        point:{
            radius: 3
        }
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: true,
            color: 'rgba(168, 162, 162, 0.12)',
            lineWidth: 1
          },
          scaleLabel: {
            display: true,
            labelString: '',
            letterSpacing: '0.27em',
            marginTop: '20px',
            fontColor: '#FFF',
          },
          ticks: {
            beginAtZero: true,
            fontColor: "#FFF", // this here
          },
        },
        
      ],
      yAxes: [{ display:true,   
      scaleLabel: {
        display: true,
        labelString: 'A M O U N T S',
        fontColor: '#FFF',
      },  gridLines: {
        display: true,
        color: 'rgba(168, 162, 162, 0.12)',
        
        lineWidth: 1
      },  
      ticks: {
        beginAtZero: true ,
        min: 0,
        fontColor: "rgb(255, 255, 255)", // this here
      },
    }],
    },
    legend: {
      display: true,
      labels: {
          fontColor: 'rgb(255, 255, 255)'
      }
  }

};

export const chartOptionsWithoutAnimated = {
  showTooltips: false,
  normalized: true,
  tooltips: {
    intersect: false,
    enabled: false,
  },
  animation: {
    duration: 0,
  },
  showLines: false,
  hover: {
    animationDuration: 0, // duration of animations when hovering an item
  },
  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Y text',
        color: '#FFF',
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'X text',
        color: '#FFF',

      }
    }],
  }  ,   
  responsiveAnimationDuration: 0,
  legend: {
    display: false,
  },
  
};

