import React, { Component } from "react";
import { Bar, Line, Scatter } from 'react-chartjs-2'
import { Row, Col, Card, CardBody } from 'reactstrap';
import { chartWithoutLegends, getStackBarChart, stackBarChartOptions, timeStampOptions } from "../../graphs/BarGraph";
import { Dropdown, Icon, Loader } from "semantic-ui-react";
import moment from "moment";
import FadeIn from "react-fade-in";
import { ExperimentUtils } from "../../../utils/ExperimentUtils";

export class Metrics extends Component {
    state = {
        experiment: this.props.experiment,
        metrics: ExperimentUtils.getAllExpData()["metrics"][this.props.experiment]["metrics"],
        xAxisVar: "epoch",
        isLoading: true
    }

    inputRefs = {};

    setRef = (ref, expId, type) => {
        const refData = this.inputRefs
        if (!refData[expId]) {
            refData[expId] = {}
        }
        refData[expId][type] = ref
        this.inputRefs = refData;
    };
  
    resetZoom = (metric, type) => { 
        console.log(metric, type)
        setTimeout(() => {
            this.inputRefs[metric][type].chartInstance.resetZoom();
        }, 200);
    };

    chooseXAxis = (name) => {
        this.setState({ xAxisVar : name })
        setTimeout(() => {
            Object.keys(this.state.metrics).map((metric) => {
                this.resetZoom(metric, name)
            });
        }, 1000);
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ isLoading : false })
        }, 2000)
    }

    componentDidUpdate() {
        if (this.state.experiment != this.props.experiment) {
            console.log(this.props.experiment)
            this.setState({ experiment : this.props.experiment })
            this.setState({ metrics: ExperimentUtils.getAllExpData()["metrics"][this.props.experiment]["metrics"] })
        }
    }

    render() {
        // if (this.state.isLoading) {
        //     return <CircularProgress color="secondary" />
        // }

        const experimentData = {}

        {
            Object.keys(this.state.metrics).map((metric, idx) => {
                const chooseGrad = idx % ExperimentUtils.getExpGrads().length

                    if (experimentData[metric] == undefined) {
                        experimentData[metric] = []
                    }

                    experimentData[metric][idx] = {
                        "label": metric, 
                        showLine: true,  
                        borderWidth: .8,
                        fill: true, 
                        backgroundColor: "rgba(255, 255, 255, 0.0)",
                        borderColor: ExperimentUtils.getExpGrads()[chooseGrad]["color"],
                        data: []
                    }

                    Object.keys(this.state.metrics[metric]).map((val) => {
                        if (this.state.metrics[metric][val][this.state.xAxisVar] || this.state.metrics[metric][val]["value"]) {
                            var x =  this.state.metrics[metric][val][this.state.xAxisVar]
                            var y =  this.state.metrics[metric][val]["value"]
                            if (this.state.xAxisVar == 'timestamp') {
                                x =  moment(this.state.metrics[metric][val][this.state.xAxisVar])
                            }
                        }
                        experimentData[metric][idx]["data"].push({
                            x: x,
                            y: y
                        })                                
                })
            })
        }

        Object.keys(experimentData).map(metric => {
            Object.keys(experimentData[metric]).map((vals) => {
                experimentData[metric] = experimentData[metric].flat()
            })
        })

        const graphs = []

        {
            Object.keys(experimentData).map((metric) => {
                const data = { datasets:  experimentData[metric] };
                return (
                    graphs.push(
                    <Col  style={{ borderRadius: 30, marginBottom: 20, border: '1px solid rgba(255, 255, 255, 0.1)', padding: 30}} sm={6}>
                        <p style={{color: 'white'}}> {metric} <span style={{cursor: 'pointer'}} className="float-right" onClick={() => this.resetZoom(metric, this.state.xAxisVar)}>Reset Zoom  <Icon name="zoom-out" /> </span> </p>
                        <Scatter
                            ref={(reference) => this.setRef(reference, metric, this.state.xAxisVar)} 
                            data={data}
                            options={
                                this.state.xAxisVar == "timestamp"?
                            timeStampOptions(this.state.xAxisVar, metric.substring(0, 1).toLocaleUpperCase() + metric.substring(1, metric.length))
                            :  stackBarChartOptions(this.state.xAxisVar, metric.substring(0, 1).toLocaleUpperCase() + metric.substring(1, metric.length))}
                        />
                    </Col>
                    )
                )
            })
        } 

        return (
            <div>
                <Dropdown style={{color: 'white', fontSize:14, marginLeft: '-13px'}} text={"Choose: " + this.state.xAxisVar}>
                    <Dropdown.Menu style={{width: '100%'}}>
                        {
                            ExperimentUtils.getXAxisVar().map((variable) => {
                                return (
                                    <Dropdown.Item onClick={() => this.chooseXAxis(variable)} text={variable}/>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
                <br></br>
                <br></br>
                <FadeIn>
                    <Row>
                        {graphs}
                    </Row>
                </FadeIn>
                <br></br>
            </div>
        )
    }
}