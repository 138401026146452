import React from 'react';
import Home from './pages/Home';
import { Route, Switch } from 'react-router';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import './styles/main.scss';
import './styles/homepage.scss';
import './styles/about.scss'

import './styles/platform/ml-production/styles/components/_components'
import './styles/platform/ml-production/styles/layout/_layouts'
import './styles/platform/ml-production/styles/pages/_pages'

import MLProduction from './pages/landing-pages/MLProduction';
import MLDeployment from './pages/landing-pages/MLDeployment';
import MLExperimentation from './pages/landing-pages/MLExperimentation';
import DataAnalytics from './pages/landing-pages/DataAnalytics';

import MLProdDashboard from './pages/platforms/ml-production/dashboardapp/MLProdDashboard';
import MLDeploymentDashboard from './pages/platforms/ml-deployment/dashboard/pages/MLDeploymentDashboard';
import ExperimentPage from './pages/platforms/ml-experimentation/experimentboard/pages/ExperimentPage';
import { SnappingPoint } from './pages/SnappingPoint';

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  constructor() {
    super()
  }
  
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
            <Switch>

            <Route
              exact
              path='/'
              component={Home}
              />

          <Route
              exact
              path='/landing-page/ml-production'
              component={MLProduction}
              />

          <Route
              exact
              path='/about'
              component={SnappingPoint}
              />
      
          <Route
              exact
              path='/snapping'
              component={SnappingPoint}
              />

            <Route
              exact
              path='/landing-page/ml-deployment'
              component={MLDeployment}
              />

          <Route
              exact
              path='/landing-page/ml-deployment/home'
              component={MLDeployment}
              />


            <Route
                exact
                path='/landing-page/ml-experimentation'
                component={MLExperimentation}
                />

            <Route
              exact
              path='/landing-page/data-analytics'
              component={DataAnalytics}
            />

          <Route
              exact
              path='/ml-production/dashboard'
              component={MLProdDashboard}
            />

          <Route
              exact
              path='/ml-deployment/dashboard'
              component={MLDeploymentDashboard}
            />

          <Route
              exact
              path='/ml-experiment/dashboard'
              component={ExperimentPage}
            />

          </Switch>
        </BrowserRouter>

    )
  }
}

export default App;
