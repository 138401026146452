import React, { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; 
import { DateRangePicker } from 'react-date-range';
import { Button } from 'reactstrap';

const date = new Date();

export const DatePicker = ({
    filterMetricDate,
    changeDates,
    modelId,
    userToken,
    ...restProps
}) => {
    const [startDate, getStart] = useState(new Date())
    const [endDate, getEnd] = useState(new Date);
    const [popUp, setPopUp] = useState(false);

	const onDateRangeSelect = async(startDatee, endDatee) => {
        if (startDatee && endDatee) {
            changeDates(startDatee.toISOString(), endDatee.toISOString())
            return await filterMetricDate(userToken, modelId, startDatee.toISOString(), endDatee.toISOString())
        }
    }
    
    const handleSelect = async(date) => {
        getStart(date["selection"]["startDate"])
        getEnd(date["selection"]["endDate"])
        onDateRangeSelect(date["selection"]["startDate"], date["selection"]["endDate"]);
      }

      const handlePopUp = () => {
          if (popUp == false) {
            document.getElementById("overlay").style.display = "block";
          }
          setPopUp(true)
      }

    window.onclick = (event) => {
        if (event.srcElement.className) {
            if (event.srcElement.className == "overlayDatePicker") {
                document.getElementById("overlay").style.display = "none";
                setPopUp(false)
            }
        }
    }

    return (
        <span style={{marginTop: 10}}>
            <div id="overlay" className="overlayDatePicker"> </div>
            {
                popUp ?
                <div id="myDate" className="myDatePicker" style={{position: 'absolute', right: 0, marginTop: 0}}>
                    <div id="myDate" className="myDatePicker" style={{position: 'absolute', zIndex: 999, right: 0, marginTop: 0}}>
                <DateRangePicker
                    className="myDatePicker"
                    ranges={[{"startDate": startDate, "endDate": endDate, "key": 'selection'}]}
                    onChange={handleSelect}
                />  
                    </div>
                </div>
            : null }
            <Button className="float-right filterDateBtn" onClick={handlePopUp}> {startDate.getMonth() + 1} / {startDate.getDate()} / {startDate.getFullYear()} - {endDate.getMonth() + 1} / {endDate.getDate()} / {endDate.getFullYear()} </Button>
            <p style={{marginRight: 15, marginTop: 15}} className="metricDateTitle"> <span> <img style={{marginRight: 9}} src={require("../../../../../../assets/platforms/ml-production/assets/calendarIcon.svg")} /> </span> FILTER DATE RANGE </p> 
        </span>
    )
}
