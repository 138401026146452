import { Card, Tooltip } from "@material-ui/core";
import React from "react";
import FadeIn from "react-fade-in";
import { toast } from "react-toastify";
import { CardBody,  UncontrolledButtonDropdown,Button, ButtonGroup, DropdownToggle, DropdownMenu, DropdownItem, Table, Input } from 'reactstrap';
import DeleteIcon from '@material-ui/icons/Delete';


class MyThresholds extends React.Component {
    constructor(props) {
        super(props)
        this.userToken = props["userToken"]
        this.state={
            organization: props["organization"],
            project: {"project": { "thresholds": { "accuracy": 0.46, "f1_score": 0.20, "recall": 0.12, "precision": 0.234}}},
            thresholdData: [],
            isLoading: true,
            newEditedMetrics: {},
            addedMetrics: [],
            newThresholdData: {},
            editing: false,
            editingText: false
        }
    }

    getThresholds = (project) => {
        console.log(project)
        var thresholdData = project["thresholds"]
        this.setState({ thresholdData : thresholdData })
        this.setState({ newThresholdData : JSON.stringify(thresholdData) })
        this.setState({ isLoading : false })
    }

    resetThresholdMetrics = () => {
        var setNewThresholdData = {}
        Object.keys(this.state.thresholdData).map(metric => {
            setNewThresholdData[metric] = "None"
        })
        this.setState({ newEditedMetrics : this.state.thresholdData })
    }

    updateThresholds = async(projectId, orgId) => {
        var projectBody = this.state.project
        projectBody["project"]["thresholds"] = JSON.parse(this.state.newThresholdData)
        toast.dark('Thresholds have been updated!');
        this.resetThresholdMetrics();
        this.setState({ editing : false })
    }

    handleThresholdChange = (metric, value) => {
        this.setState({ editingText : true })
        var setNewThresholdData = JSON.parse(this.state.newThresholdData)
        setNewThresholdData[metric] = Number.parseFloat(value)
        this.setState({ newThresholdData : JSON.stringify(setNewThresholdData) })
    }

    deleteMetric = (metricName) => {
        var copyDate = this.state.thresholdData;
        var copyNewData = JSON.parse(this.state.newThresholdData);
        copyDate[metricName] = 0;
        copyNewData[metricName] = 0;
        this.setState({ newThresholdData : JSON.stringify(copyNewData) })
        this.setState({ thresholdData : copyDate })
    }

    turnEditing = (type) => {
        if (!type) {
            this.setState({ editing : true })
            this.resetThresholdMetrics()
        } else {
            this.updateThresholds(this.state.project["project"]["id"], this.state.organization["id"])
        }
    }

    addMetric = (metricName) => {
        var copyData = JSON.parse(this.state.newThresholdData);
        copyData[metricName] = 1;
        this.setState({ newThresholdData : JSON.stringify(copyData) })
        this.setState({ thresholdData : copyData })
    }

    componentDidMount() {
        this.getThresholds(this.state.project.project)

    }

    componentDidUpdate() {
        if (this.state.organization != this.props["organization"]) {
            this.setState({ organization : this.props["organization"] })
        }
        if (this.state.project != this.props["project"]) {
            this.setState({ project : this.props["project"] })
        }

    }

    componentDidUpdate() {
        window.onclick = (event) => {
            if (this.state.editingText) {
                this.setState({ editingText : false })
            }
        }
    }

    render() {
        if (this.state.isLoading) {
            return "Loading Thresholds";
        }

        return (
            <div style={{paddingRight: 130}}>
            <p className="subTabTitle" style={{marginBottom: 10}}> My Thresholds <span> </span> </p>
            <p className="subTabDescription"> Change and edit the thresholds for each metric by clicking on + Add Thresholds and edit the values for each metric.  </p>

                {
                    Object.keys(JSON.parse(this.state.newThresholdData)).map((metric) => {
                        var formatMetricName = metric.substring(0, 1).toUpperCase() + metric.substring(1, metric.length)
                        if (this.state.thresholdData[metric].toString() != "0")
                            return (
                                <FadeIn>
                                <p style={{marginBottom: '15px'}}> <span className="metricTitle"> {formatMetricName} <input id={metric} style={{marginLeft: 8}} onChange={(text) => this.handleThresholdChange(metric, text.target.value)} className={"expandStepsInput metricInputBoxes " + metric} placeholder={JSON.parse(this.state.newThresholdData)[metric]} />  </span> <Tooltip placement="top"  title={"Delete"} ><DeleteIcon style={{color: 'white', cursor: 'pointer'}} onClick={() => this.deleteMetric(metric)} /></Tooltip> </p>
                                </FadeIn>
                            )
                    })
                }

            <UncontrolledButtonDropdown>
                <DropdownToggle style={{cursor: 'pointer'}} tag="a" className="nav-link addThresholdsBtn"> + Add Thresholds </DropdownToggle>
                <DropdownMenu style={{width: '100%'}} className="organizationDropdownMenu">
                {
                    Object.keys(JSON.parse(this.state.newThresholdData)).map((threshold) => {
                        var formatMetricName = threshold.substring(0, 1).toUpperCase() + threshold.substring(1, threshold.length)
                        if (this.state.thresholdData[threshold] == 0)
                        return (
                            <DropdownItem onClick={() => this.addMetric(threshold)}>
                            {formatMetricName}
                        </DropdownItem>

                        )
                    })
                }               
                </DropdownMenu>

            </UncontrolledButtonDropdown>
            <Button className="saveBtn" onClick={() => this.updateThresholds(this.state.project["project"]["id"], this.state.organization["id"])}> Save </Button>
    
        
        </div>
        )

    }
}

export default MyThresholds;