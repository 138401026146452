import React from "react";
import { tabNames } from "../utils/TabManager";
import { Row } from 'reactstrap';
import { DashboardUtils } from "../utils/DashboardUtil";

export const TabMenu = ({
    switchTabs,
    ...restProps
}) => {
    return (
        <div>
            <div className="dashboard-medium-gray">
                <Row className="dashboard-menu" style={{ marginLeft: 1, marginTop: 18}}>
                    {
                       tabNames.map((tab) => {
                           if (tab.route == DashboardUtils.getParameterByName('tab-name')) {
                            return <a  onClick={() => switchTabs(tab["name"], tab["route"])} className="dashboardSubTabs"> {tab["name"]} </a>
                           }
                           return <a  onClick={() => switchTabs(tab["name"], tab["route"])} className="clicked-dashboardSubTabs"> {tab["name"]} </a>
                       })
                    }
                </Row>
            </div>
        </div>
    )
}