import React from "react";
import CreateAlert from '../subtabs/CreateAlert';
import FadeIn from 'react-fade-in'
import MyThresholds from '../subtabs/MyThresholds';

export const settingsTabNames = [{"ALERTS": ["Set Thresholds"]}];

export function getSettingsTab(page, userToken, organization, project) {
    switch (page) {
        case "create new alerts":
            console.log("I created new alerts")
            return <FadeIn> <CreateAlert userToken={userToken} organization={organization} project={project}  /> </FadeIn>
        case "set thresholds":
            console.log("Set thresholds");
            return <FadeIn>  <MyThresholds organization={organization} project={project} /> </FadeIn>
            // return <PredictionLogs userToken={userToken} modelData={modelData} metrics={metrics} />
        case "monitor drift":
            console.log("Monitor Drift");
            // return <Reupload userToken={userToken} modelData={modelData} />;
        case "settings":
            console.log("Alerts");
            // return <SettingsPage orgId={""} userToken={userToken} modelData={modelData} />
        default:
            console.log("Overview");
            // return <Overview userToken={userToken} modelData={modelData} metrics={metrics} />
    }
}
