import React from "react";
import { Overview } from "../subtabs/Overview";
import { Settings } from "../subtabs/settings/Settings";

export const tabNames = [{
    "name": "Overview",
    "route": "overview"
},{
    "name": "System",
    "route": "system"
} ]

export function TabManager(name, model, version, metrics, reqInfo, operations) {
    switch (name) {
        case "Overview":
            return <Overview 
                        model={model} 
                        version={version} 
                        metrics={metrics} 
                        requestInfo={reqInfo} 
                        getRequestInfo={operations.getRequestInfo}
                        switchVersion={operations.switchVersion}
                        getMetrics={operations.getMetrics}
                        getTimestamp={operations.getTimestamp}
                        currTimeTab={operations.currTimeTab}
                    />
        case "System":
            return <Settings
                    model={model} 
                    switchVersion={operations.switchVersion}
                    refreshData={operations.refreshData}
                    version={version} 
            />
    }
}