import { Tooltip, Zoom } from '@material-ui/core';
import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';

const HomeSideBar = ({
    organization,
    ...restProps}) => {
    return (
        <div>
        <div className="homeSideBanner">
                    <div className="miniMenuController">
                    <Tooltip TransitionComponent={Zoom} title="Projects" placement="right-end">
                    <img style={{position: 'absolute', cursor: 'pointer', top: 40, left: 25}} src={require('../../../../../../assets/platforms/ml-production/assets/dashboardbtn.svg')} />
                    </Tooltip>
                    <br></br>
                    <Tooltip TransitionComponent={Zoom} title="Credentials" placement="right-end">
                    <img height={25} style={{position: 'absolute', cursor: 'pointer', width: 40, top: 110, left: 25}} src={require('../../../../../../assets/platforms/ml-production/assets/keyIcon.svg')} />
                    </Tooltip>
                    <br></br>
                    <Tooltip TransitionComponent={Zoom} title="Settings" placement="right-end">
                    <SettingsIcon style={{color: 'white', cursor: 'pointer', position: 'absolute',  top: 163, left: 31}} />
                    </Tooltip>

                    {/* <Tooltip TransitionComponent={Zoom} title="Settings" placement="right-end">
                    <img height={30} onClick={() => window.location.href = '/settings'} style={{position: 'absolute', cursor: 'pointer', width: 40, top: 160, left: 25}} src={require('../../assets/usersettings.svg')} />
                    </Tooltip> */}
                    <hr  style={{borderColor: 'rgba(255, 255, 255, 0.4)', height: '0.05px'}} />
                    <img className="miniMenuControllerDiamond" src={require('../../../../../../assets/platforms/ml-production/assets/diamond.svg')} />
                </div>
            </div>
        </div>
    )
}

export default HomeSideBar;