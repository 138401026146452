import { Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FadeIn from "react-fade-in";
import { Icon } from "semantic-ui-react";

export const ExperimentsMenu = ({expColors, updateExp, experiments, ...data}) => {
    const [open, setOpen] = useState(false);

    const closeMenu = () => {
        setOpen(false)
        document.getElementById('experimentSideMenu').style.width = '0px'
        document.getElementById('exp_menu_body').style.display =  'none';
        // document.getElementById('exp_menu_body').style.transition = 'fadeOut 3s ease-in-out'
        document.getElementById('experimentBanner').style.paddingLeft =  '110px';

        document.getElementById('experimentSideMenu').style.left = '14px'
        document.getElementById('experimentSideMenu').style.top = '79px'
        document.getElementById('experimentSideMenu').style.borderRadius = '300px'
        document.getElementById('experimentSideMenu').style.padding = '30px'
        document.getElementById('experimentSideMenu').style.background = 'linear-gradient(180deg, #000000 2.66%, rgba(16, 40, 89, 0.42) 100%)'


        document.getElementById('dashboardBody').style.paddingLeft = '130px'
        document.getElementById('dashboardBody').style.paddingRight = '100px'        
    }

    const openMenu = () => {
        setOpen(true)

        document.getElementById('experimentSideMenu').style.width = '208px'
        document.getElementById('experimentSideMenu').style.height = '70%'
        document.getElementById('experimentSideMenu').style.borderRadius = '0px'
        document.getElementById('experimentSideMenu').style.left = '0px'
        document.getElementById('experimentSideMenu').style.top = '62px'
        document.getElementById('experimentBanner').style.paddingLeft =  '246px';
        document.getElementById('experimentSideMenu').style.padding = '40px'
        document.getElementById('experimentSideMenu').style.background = 'linear-gradient(180deg, #2D333F 2.66%, rgba(16, 40, 89, 0.42) 100%)'

        document.getElementById('dashboardBody').style.paddingLeft = '260px'
        document.getElementById('dashboardBody').style.paddingRight = '100px'

        setTimeout(() => {
            document.getElementById('exp_menu_body').style.display =  'block';
        }, 1000)
    }

    return (
        <div>
            <div id="experimentSideMenu" className="experimentSideMenu">
                {
                    open ?
                    <p style={{position: 'absolute', right: '7px', cursor: 'pointer', top: '10px'}}>
                        <Tooltip placement="right" title="Minimize menu">
                            <Icon onClick={() => closeMenu()} style={{color: 'white'}} name="cancel" /> 
                        </Tooltip> </p>
                    :
                    <div style={{position: 'absolute', left: '17px'}}>
                    <p onClick={() => openMenu()}> 
                        <Tooltip placement="right" title="Open menu">
                        <Icon style={{color: 'white', cursor: 'pointer', marginLeft: 6}} name="plus" />  
                        </Tooltip>
                    </p>
                    <FadeIn>
                        {
                        Object.keys(expColors).map((exp, i) => {
                                return (
                                    <Tooltip placement="right"  title={exp}>
                                    <div 
                                    onClick={() => updateExp(exp)}
                                    style={{
                                        height: '25px',
                                        boxShadow: 'inset -2px 3px 1px rgba(0, 0, 0, 0.2)',
                                        width: '25px',
                                        cursor: 'pointer',
                                        borderRadius: '3px',
                                        marginTop: 11,
                                        backgroundColor: expColors[exp]["color"]
                                    }}>
                                    </div>
                                    </Tooltip>
                                )
                            })
                        }
                        </FadeIn>
                    </div>

                }
                <FadeIn delay="300">
                <div id="exp_menu_body" className="exp_menu_body">
                    <p style={{marginBottom: 5, textDecoration: 'underline', cursor: 'pointer'}} id="experiments_menu" className="experimentSideMenuTitle"> All Experiments </p>
                    <br></br>
                    {
                        Object.keys(expColors).map((exp, i) => {
                          return (
                                <p                                     onClick={() => updateExp(exp)}
                                style={{cursor: 'pointer', fontWeight: 500}} className="experimentSideMenuLink"> 
                                <span>    <div 
                                  onClick={() => updateExp(exp)}

                                style={{
                                        height: '5px',
                                        width: '5px',
                                        marginTop: 6,
                                        marginRight: 7,
                                        float: 'left',
                                        cursor: 'pointer',
                                        borderRadius: '3px',
                                        backgroundColor: expColors[exp]["color"]
                                    }}>
                            </div> {exp.substring(0, 9)} </span> </p>
                            )
                        })
                    }
                </div>

                </FadeIn>
            </div>
        </div>
    )
}
