import React from "react"
import { Row, Col } from 'reactstrap'
import { Icon } from "semantic-ui-react"
import "../../../../../styles/landing-pages/ml-deployment.scss"

export const Footer = ({...restProps}) => {
    return (
        <div className="footer">

            <hr className="full-width-divider" style={{borderTop: '1px solid rgba(255, 255, 255, 0.21', marginBottom: 30}} />

            <div className="footer-body">

                    <Row>
                        <Col>

                          <img src={require('../../../../../assets/landing-pages/ml-deployment/assets/stackstr-logo.svg')} />
                        
                        </Col>

                        <Col>
                          <p className="footer-links">COMPANY </p>
                          <p onClick={() => window.location.href = '/about'} className="footer-links"> About </p>

                        </Col>

                        <Col>
                          <p className="footer-links"> FIND US </p>
                          <p onClick={() => window.open('https://www.linkedin.com/company/stackstr/')}  className="footer-links"> Linkedin </p>

                        </Col>

                        <Col>
                          <p className="footer-links"> CONTACT US</p>
                          <p className="footer-links"> founders@stackstr.io </p>
                        </Col>
                      </Row>

                      <hr style={{borderTop: '1px solid rgba(255, 255, 255, 0.21', marginTop: '50px', marginBottom: 30}} />

                        <p className="footer-copyright">Copyright <Icon name="copyright" />    2021 StackStr, Inc. All rights reserved. </p>

            </div>
   
        </div>
    )
}