import { Card } from "@material-ui/core";
import React from "react";
import { CardBody, Row, Col, Button } from 'reactstrap'

class CreateAlert extends React.Component {
    constructor(props) {
        super(props);
        this.userToken = props["userToken"]
        this.state={
            orgIg: props["orgId"],
            modelData: props["modelData"],
            isLoading: false,
        }
    }

    getPageComponent = async(pageName) => {
        this.setState({ pageComponent : "hey" })
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    render() {
        if (this.state.isLoading) {
            return "Loading Settings";
        }

        return (
            <div style={{paddingRight: 130}}>
                <p className="subTabTitle"> Create New Alerts </p>

                <Card style={{marginBottom: 15}} className="stepTitleCard">
                    <CardBody>
                        <p className="stepTitleText"> Alert Details </p>
                        <p className="stepSubTitleText"> Define Alert Details </p>
                    </CardBody>
                </Card>

                <Card className="expandStepCard">
                    <CardBody className="expandStepCardPadding">
                        <p style={{marginBottom: 21}} className="stepTitleText"> Alert Details </p>
                        <Row style={{marginBottom: 10}}>
                            <Col>
                                <p className="stepSubTitleText"> Establish View </p>
                            </Col>
                            <Col>
                            <input className="expandStepsInput" placeholder="Type a value" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="stepSubTitleText"> Value Threshold </p>
                            </Col>
                            <Col>
                            <input className="expandStepsInput" placeholder="Type a value" />
                            </Col>
                        </Row>
                        <br></br>
                    </CardBody>
                </Card>
                <Card style={{marginBottom: 15, marginTop: 24}} className="stepTitleCard">
                    <CardBody>
                        <p className="stepTitleText"> Delivery Settings </p>
                        <p className="stepSubTitleText"> Automatically have this sent to: </p>
                    </CardBody>
                </Card>

                <Card className="expandStepCard">
                <CardBody className="expandStepCardPadding">
                        <p style={{marginBottom: 21}} className="stepTitleText"> Delivery </p>
                        <Row>
                            <Col>
                                <p className="stepSubTitleText"> Sent via </p>
                            </Col>
                            <Col>
                            <input className="expandStepsInput" placeholder="Type a value" />
                            </Col>
                        </Row>
                        <br></br>
                    </CardBody>
                </Card>
                <Card style={{marginBottom: 15, marginTop: 24}} className="stepTitleCard">
                    <CardBody>
                        <p className="stepTitleText"> Alert Name </p>
                        <p className="stepSubTitleText"> Finalize alert details </p>
                    </CardBody>
                </Card>

                <Card className="expandStepCard">
                <CardBody className="expandStepCardPadding">
                        <p style={{marginBottom: 21}} className="stepTitleText"> Alert Name </p>
                        <Row style={{marginBottom: 10}}>
                            <Col>
                                <p className="stepSubTitleText"> Create an alert name </p>
                            </Col>
                            <Col>
                            <input className="expandStepsInput" placeholder="Type a value" />
                            </Col>
                        </Row>
                        <br></br>
                    </CardBody>
                </Card>
                <br></br>
                <Row className="float-right">
                    <Button className="clearStepsBtn"> Clear </Button>
                    <Button className="createStepsBtn"> Create </Button>
                </Row>
            </div>
        )
    }
}

export default CreateAlert;