import { Card } from "@material-ui/core";
import copy from "copy-to-clipboard";
import React from "react";
import { CardBody, Row, Col, Input } from 'reactstrap';
import { Icon, Popup } from "semantic-ui-react";
import { RollbackConfirm } from "./components/modal/RollbackConfirm";
import { TabManager, tabNames } from "./subtabs/TabManager";

export class Settings extends React.Component {
    state={
        version: this.props.version,
        model: this.props.model,
        copied: false,
        tab: {
            "name": "Pods",
            "route": "pods",
        },
        pageComponent: []
    }
    
    refreshData = this.props.refreshData
    switchVersion = this.props.switchVersion

    getTabComponent = (tabName, route) => {
        this.setState({ tab : {
            "name": tabName,
            "route": route
        } })
        const operations = {
            "refreshData": this.refreshData
        }
        return this.setState({ pageComponent : TabManager(tabName, this.state.model, this.state.version, operations) })
    };

    copyURL = (endpoint) => {
        copy(endpoint)
    };

    componentDidMount() {
        this.getTabComponent("Pods", this.state.model, this.state.version)
    }

    componentDidUpdate() {
        if (this.state.model != this.props.model) {
            this.setState({ model : this.props.model })
        }

        if (this.state.version != this.props.version) {
            this.setState({ version : this.props.version })
        }
    }

    render() {
        return (
            <div>
                <Card className="settings-main-body-card">
                    <CardBody>

                        <Row style={{justifyContent: 'space-between', paddingLeft: '20px', marginBottom: '10px', paddingBottom: '8px', paddingRight: '20px'}}>

                            <div style={{marginTop: '3px'}}>

                                <p className="settings-search-bar">
                                      <Icon name="grid layout" />  System Customization </p> 

                            </div>

                            <br></br>

                            <div style={{marginTop: '6px'}}>

                                <p className="settings-version-number"> {"v0.0.1"}
                                    <span className="settings-status"> <Icon style={{fontSize: '9px', position: 'relative', top: '-2px'}} name="circle outline green-light"/> Deployed  </span>

                                    <RollbackConfirm refreshData={this.refreshData} switchVersion={this.switchVersion} version={this.state.version} model={this.state.model} />

                                    <span className="settings-endpoint-URL"> https://api.stackstr.io/predict/new-model-test

                                        <Popup  
                                            style={{background: 'black', }}
                                            inverted
                                            on="click"
                                            onOpen={() => this.setState({ copied : true }) }
                                            content="Copied URL"
                                            trigger={ this.state.copied ?
                                                <Icon onClick={() => { copy(this.state.model.url); this.setState({ copied : true }) }} style={{color: 'green', marginLeft: '8px', cursor: 'pointer', fontSize: '11px'}}  name="check" />
                                                :
                                                  <Icon onClick={() => { copy(this.state.model.url); this.setState({ copied : true }) }} style={{color: 'rgba(255, 255, 255, 0.52)', marginLeft: '8px', cursor: 'pointer', fontSize: '11px'}}  name="clipboard" />
                                            }
                                        />

                                    </span>
                                </p> 

                            </div>


                        </Row>

                        <Row>
                            <Col style={{paddingLeft: '0px'}}>
                                <Card style={{background: 'none', boxShadow: 'none'}}>
                                    <CardBody style={{paddingTop: '0px', paddingLeft: '8px', paddingRight: '0px'}}>
                                        {
                                            tabNames.map((tab) => {
                                                if (tab.name == this.state.tab.name) {
                                                    return (
                                                        <Card style={{borderRight: '4px solid #EA60DC'}} className="settings-subtab-card">
                                                            <CardBody>  
                                                                <div style={{display: 'inline-flex'}}>
                                                                    <img style={{marginTop: '-15px'}} src={require(`../../../../../../assets/platforms/ml-deployment/assets/${tab.img}`)} />
                                                                    <div style={{marginLeft: '14px'}}>
                                                                        <p className="settings-subtab-card-title"> {tab.title} </p>
                                                                        <p className="settings-subtab-card-description"> {tab.description} </p>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    )
                                                }
                                                return (
                                                    <Card onClick={() => this.getTabComponent(tab.name, tab.route)} style={{border: '1px solid rgba(14, 13, 13, 0.55)'}} className="settings-subtab-card">
                                                        <CardBody>  
                                                            <div style={{display: 'inline-flex'}}>
                                                                <img style={{mixBlendMode: 'luminosity', marginTop: '-15px'}} src={require(`../../../../../../assets/platforms/ml-deployment/assets/${tab.img}`)} />
                                                                <div style={{marginLeft: '14px'}}>
                                                                    <p style={{color: 'rgba(255, 255, 255, 0.78)'}} className="settings-subtab-card-title"> {tab.title} </p>
                                                                    <p style={{color: 'rgba(255, 255, 255, 0.47)'}} className="settings-subtab-card-description"> {tab.description} </p>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                )
                                            })
                                        }
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col style={{paddingLeft: '0px'}} sm={9}>
                                <Card className="settings-subbody-card">
                                    <CardBody style={{padding: '1.95rem'}}>
                                        {this.state.pageComponent}
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                        <br></br>
                        <br></br>
                        <br></br>

                    </CardBody>
                </Card>

            </div>
        )
    }
}