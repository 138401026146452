import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import Fade from 'react-reveal/Fade';
import TextLoop from "react-text-loop";

export const AboutMe = ({...restProps}) => {
    const [opened, setOpen] = useState(false)

    return (
        <div>
            <div style={{right: '100px', bottom: '120px', background: 'linear-gradient(200.05deg, #B588FF 20.36%, #FFD7F6 75.78%, #CCFFF6 94.49%)', width: '400px', animation: 'moving_shape 20s ease infinite', transform: 'rotate(116deg)', opacity: '0.50'}} className="slide_2 slide_3_animated_groove"></div>

            <div className="slide_2 slide_3_animated_groove"></div>
            <div className="slide_2 slide_3_br_animated_groove_1"></div>
            <div className="slide_2 slide_3_br_animated_groove_2"></div>
            <div className="slide_2 slide_3_br_animated_groove_3"></div>

            <img  className="slide_2 slide_3_pink_diamond floating" src={require('../assets/slide_3_pink_diamond.svg')} />

            {
            /* 
            / Slide 3 ANIMATIONS - Top Left Corner
            */
            }
            <div className="slide_2 slide_3_tl_animated_groove_1"></div>
            <div className="slide_2 slide_3_tl_animated_groove_2"></div>
            <div className="slide_2 slide_3_tl_animated_groove_3"></div>

            {
            /* 
            / Slide 3 ANIMATIONS - Bottom Left Corner
            */
            }
            <div className="slide_2 slide_3_bl_animated_groove_1"></div>
            <div className="slide_2 slide_3_bl_animated_groove_2"></div>
            <div className="slide_2 slide_3_bl_animated_groove_3"></div>

            {
            /* 
            / Slide 3 ANIMATIONS - Bottom Right Corner
            */
            }
            <div className="slide_2 slide_3_br_animated_groove_1"></div>
            <div className="slide_2 slide_3_br_animated_groove_2"></div>
            <div className="slide_2 slide_3_br_animated_groove_3"></div>

            <img className="slide_2 slide_2_floating_hexagon floating" src={require('../assets/slide_2_floating_hexagon.svg')} />

            {
                opened?
                <div>
           
                    <Row className="slide_2 slide_2_row">
                    
                      <Col className="slide_2 slide_3_col_1">
                        <Fade delay={800} top>
                            <div className="slide_2">
                              <img className="slide_2 slide_2_bubble_1 float-right" src={require('../assets/slide_2_bubble_1.svg')} />
                              <img className="slide_2 slide_2_bubble_2 float-right" src={require('../assets/slide_2_bubble_2.svg')} />
                              <img className="slide_2 slide_2_bubble_3 float-right" src={require('../assets/slide_2_bubble_3.svg')} />

                              <img style={{width: '554px'}} className="slide_2 float-right" loading="eager" src={require('../assets/slide_2_main_img.jpg')} />
                            </div>
                        </Fade>
                      </Col>

                      <Col className="slide_2" style={{paddingRight: '30px'}}>
                          <Fade delay={200} bottom cascade>
                              <div className="slide_2 float-left">
                                  <p className="slide_2 slide_2_gradient_text"> My Story </p>
                                  <div style={{height: '360px', overflowY: 'auto', position: "relative", zIndex: 99999}}>
                                  <p className="slide_2 slide_2_white_text"> 
                                          Throughout childhood, <span style={{textDecoration: "underline"}}>I was a painter</span> who replicated famous artworks into canvas. After being accepted to Georgia Tech & joining their Create-X program, 
                                          I merged my artistic skills into software, as every project
                                          I've worked on/created had allowed me to grow as both a developer & designer. 
                                          
                                          <br></br>
                                          <br></br>

                                          During Create-X, I cofounded my first startup GymSplat - a platform that monitored human activity & machine usage patterns using cameras & ML in gyms. 
                                          As our team <span style={{fontWeight: 600, textDecoration: "underline"}}>piloted with Georgia Tech's CRC</span>, I developed the dashboard's frontend & implemented both hardware and software into their gym. 

                                          <br></br>
                                          <br></br>

                                          I then cofounded StackStr, a platform that monitored ML models in production. I
                                          assisted in <span style={{fontWeight: 600, textDecoration: "underline"}}>raising a seed round of around ~$1 million by several of the largest investors in Atlanta</span>. I became an integral asset to the team 
                                          by bringing forth my unique designs and frontend skills.
                                          
                                          <br></br>
                                          <br></br>

                                          Most recently, I <span style={{fontWeight: 600, textDecoration: "underline"}}>developed a custom software called AtlasPlanner</span> for Create-X that allows
                                          social networking among closed-groups. Students used it to efficiently schedule one-on-ones w/mentors in the program. 
                                    
                                        </p>
                                    </div>
                                  <br></br>
                                  <br></br>

                                  <Row className="row_read_full_story" style={{ marginLeft: '0px', position: "relative", zIndex: 999}}>
                                    <Button onClick={() => setOpen(false)} className="read_full_story minimize_story"> Minimize Story </Button>
                                </Row>
                              </div>
                          </Fade>
                          <br></br>
                       
                      </Col>

                    
                    </Row>
                </div>


                :
                <div>
               
                <Row className="slide_2 slide_3_row">
                    <Col className="slide_2 slide_3_col_1">
                      <div className="slide_2 float-right">
                        <Fade delay={400} top cascade>
                          <div className="slide_2">
                            <img className="slide_2 slide_3_glowy_circle" src={require('../assets/slide_3_glowy_circle.svg')} />
                            <img className="slide_2 slide_3_glowy_blue_triangle" src={require('../assets/slide_3_glowy_blue_triangle.svg')} />
                            <img className="slide_2 slide_3_glowy_pink_rectangle" src={require('../assets/slide_3_glowy_pink_rectangle.svg')} />

                            <img className="slide_2 slide_3_white_sphere" src={require('../assets/slide_3_white_sphere.svg')} />
                            <img className="slide_2 slide_3_main_img " src={require('../assets/slide_3_main_img.svg')} />
                            <img className="slide_2 slide_3_pearl_img" src={require('../assets/slide_3_pearl_img.svg')} />

                          </div>
                        </Fade>
                      </div>
                    </Col>

                    <Col className="slide_2">
                      <Fade delay={200} bottom cascade>
                          <div className="slide_2">
                              <p className="slide_2 slide_3_white_text" style={{marginBottom: '23px', position: "relative", zIndex: 999}}> I was a painter </p>
                              <p id="slide_3_white_text" style={{marginTop: '-20px', position: "relative", zIndex: 999, marginBottom: '0px'}} className="slide_2 slide_3_white_text"> who turned into a </p>
                          </div>
                        <TextLoop fade delay={300} >
                        <p className="slide_2 slide_3_gradient_text">Graphic Designer.  </p>
                            <p className="slide_2 slide_3_gradient_text">UX/UI Designer.    </p>
                            <p className="slide_2 slide_3_gradient_text">Graphic Designer.  </p>
                            <p className="slide_2 slide_3_gradient_text">Frontend Developer.</p>
                            <p className="slide_2 slide_3_gradient_text">Cofounder.    </p>
                            <p className="slide_2 slide_3_gradient_text">Full Stack Developer.</p>
                        </TextLoop>
                        </Fade>

                        <br></br>

                        <Fade delay={200} bottom cascade>
                        <Row className="row_read_full_story" style={{marginTop: '30px', marginLeft: '0px', position: "relative", zIndex: 999}}>
                          <Button onClick={() => setOpen(true)} className="read_full_story"> Read Full Story </Button>
                        </Row>
                        </Fade>
                    </Col>

                    
                  </Row>
                  </div>
            }
            
        </div>
    )
}