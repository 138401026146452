import { Input, Button} from '@material-ui/core';
import React, { useState } from 'react';
import { Row } from 'reactstrap'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Card } from '@material-ui/core';
import {  Modal,Table,  ModalHeader, ModalBody, ModalFooter, Label,  CardBody, Col, DropdownMenu } from 'reactstrap';
import { UncontrolledDropdown, UncontrolledButtonDropdown, DropdownToggle, DropdownItem } from 'reactstrap';
import { Flip, toast, Zoom } from 'react-toastify';
import FadeIn from 'react-fade-in';

export const DashboardHeader =({
    models,
    userInfo,
    chooseModel,
    organization,
    currentModel,
    userToken,
    ...restprops
}) => {
    return (
        <div className="dashboardHeader">
            {/* <NotifMenu organization={organization}>  </NotifMenu> */}
            <img className="logo" src={require('../../../../../../assets/platforms/ml-production/assets/logo/logo.svg')} />
                <Row>
                        <p style={{width: '200px'}} className="dashboardHeaderText" style={{cursor: 'pointer'}}> PROJECT <span style={{fontWeight: 600, marginLeft: 6}}>  SAMPLE PROJECT 
                        <span style={{marginTop: -3, marginLeft: 7}}> 
                            <img src={require('../../../../../../assets/platforms/ml-production/assets/WhiteCheckmark.svg')} /> </span> </span></p>
                    <br></br>
                </Row>
            <br></br>
            <br></br>
       </div>
    )
}

export const ChooseProjectModal = (props) => {
    const {
      organization,
      userToken,
      models,
      chooseModel,
      currentModel,
      className,
      children,
    } = props;
  
    const [modal, setModal] = useState(false);
    const [modelName, selectModelName] = useState("TEST_MODEL");
    const [modelId, selectModelId] = useState("id_1234567890");

    const okay = { children }

    const linkModel = async(modelName, modelId) => {
        selectModelName(modelName)
        selectModelId(modelId)
    }

     const selectModel = async(modelId) => {
         if (modelId.length == 0) {
             return alert('You must choose select a project to open.')
         }
         return await chooseModel(userToken, organization["id"], modelId).then(() => {
                toggle();
         })
     }
  
    const toggle = () => {
        selectModelName("");
        selectModelId("");
        setModal(!modal);
    }

    return (
      <div onClick={toggle}>
          {children}
        <Modal style={{width: 745}} isOpen={modal} toggle={toggle} className={className}>
        <FadeIn>
          <ModalHeader className="selectProjectTitle"> Select a project </ModalHeader>
          </FadeIn>
          <ModalBody>
          <div style={{maxHeight: '300px', overflowY: 'scroll'}}>
          <Table bordered hover>
            <tr className="projectCellModalTitle">
                <td> Name </td>
                <td> ID </td>
                    </tr>
                <tbody style={{height: '263px', overflowY: 'scroll'}}>
                  {
                      models.map(model => {
                          if (model["id"] == modelId) {
                            return (
                                <tr onClick={() => selectModel(model["id"])} className="projectCellModal" style={{background: 'rgba(30, 51, 86, 0.12)', cursor: 'pointer'}}>
                                  <td> {model["name"]} </td>
                                  <td> {model["id"]} </td>
                                </tr>
                            )
                        }
                          return (
                              <tr className="projectCellModal" style={{cursor: 'pointer'}} onClick={() => selectModel(model["id"])}>
                                <td> {model["name"]} </td>
                                <td> {model["id"]} </td>
                              </tr>
                          )
                      })
                  }
                  </tbody>
          </Table>
          </div>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
  

