import { Button, Card } from '@material-ui/core';
import React, { useState } from 'react';
import { Flip, toast } from 'react-toastify';
import { Row, Col, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import FadeIn from 'react-fade-in';
import en from 'javascript-time-ago/locale/en'
import ReactTimeAgo from 'react-time-ago'

class Issues extends React.Component {
    constructor(props) {
        super(props);
        this.userToken = props["userToken"]
        this.state={
            organization: props["organization"],
            project: {"id": "55229b03-45ca-49f9-8d01-744f16f451c"},
            isLoading: false,
            issues: {
                "issues": [
                    {"occurrences": 23, "kind": "Accuracy", "notification_sent_at": "5 minutes ago", "id": "80b5e99-252e-4865-97f33-23dw99"},
                    {"occurrences": 23, "kind": "Accuracy", "notification_sent_at": "5 minutes ago", "id": "80b5e99-252e-4865-97f33-23dw99"},

                ]
            }
        }
    }

    getAllIssues = async(userToken, orgId) => {}

    resolveIssue = async(userToken, orgId, projectId, issueId) => {
        toast.dark('Resolved issues!',  { transition: Flip, progressStyle: { background: 'linear-gradient(89.94deg, #8BDCFF 2.06%, #3C7BD9 99.97%)' } });
    }

    ignoreIssue = async(userToken, orgId, projectId, issueId) => {
        toast.dark('Successfully deleted the issue!',  { transition: Flip, progressStyle: { background: 'linear-gradient(89.94deg, #8BDCFF 2.06%, #3C7BD9 99.97%)' } });
    }

    isLoading() {
        this.setState({ isLoading : false });
    }

    componentDidMount() {
        this.getAllIssues(this.userToken, this.props["organization"]["id"])
    }

    render() {
        console.log(this.state.issues.issues)
        if (this.state.isLoading) {
            return "Loading issues..."
        }

        return (
            <FadeIn>
            <div className="issueInstanceBg">
                <p className="issueTitle"> Issues ({ this.state.issues["issues"].length != 0 ? Object.keys(this.state.issues["issues"]).length : 0 }) </p>
                <Card className="issueInstanceTable">
                    <div className="issueInstanceTopBorder">
                        <Row>
                            <Col sm={9}>
                                <p className="issueInstanceTableProject"> PROJECT <span className="issueInstanceTableProjectName"> {this.state.project["name"]} </span> <span className="issueInstanceTableTitleMetrics"> {this.state.issues.length != 0 ? Object.keys(this.state.issues["issues"]).length : 0} total issues </span></p>
                            </Col>
                            <Col>
                                <p className="issuesInstanceMetrics"> {this.state.issues.length != 0 ? Object.keys(this.state.issues["issues"]).length : 0} </p>
                            </Col>
                            <Col>
                                <p className="issuesInstanceMetrics"> 0 </p>
                            </Col>
                        </Row>
                    </div>
                    {
                        this.state.issues["issues"].length != 0 ?
                            Object.keys(this.state.issues["issues"]).map((issue, i) => {
                                console.log(issue)
                                return (
                                    <div>
                                    <Row style={{marginLeft: -30}}>
                                    <Col sm={9}>
                                        <div>
                                            <div className="float-left">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle style={{background: "none", border: 'none', outline: 'none', borderRadius: '0px'}}> 
                                            <img style={{cursor: 'pointer', marginTop: -13}} src={require('../../../../../../assets/platforms/ml-production/assets/issuesoptions.svg')} /> </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => this.resolveIssue(this.userToken, this.state.organization["id"], this.state.project["id"], this.state.issues["issues"][issue]["id"])}> Resolve </DropdownItem>
                                                <DropdownItem onClick={() => this.ignoreIssue(this.userToken, this.state.organization["id"], this.state.project["id"], this.state.issues["issues"][issue]["id"])}> Delete </DropdownItem>
                                            </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            </div>
                                            <div style={{marginLeft: 45}}>
                                                <p className="issuesInstanceTitle"> {this.state.issues["issues"][issue]["kind"]} <span className="issuesInstanceH3"> project {this.state.project["id"]} </span> </p>
                                                <p className="issuesInstanceH1" style={{marginTop: 6}}> {this.state.issues["issues"][issue]["kind"]} dropped below the threshold  </p>
                                                <p className="issuesInstanceH2"> {this.state.issues["issues"][issue]["notification_sent_at"]} {"-"} {this.state.issues["issues"][issue]["id"]} </p>
                                            </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <p className="issuesInstanceMetrics"> {this.state.issues["issues"][issue]["occurrences"]} </p>
                                        </Col>
                                        <Col>
                                            <p className="issuesInstanceMetrics"> Occurrences </p>
                                        </Col>
                                    </Row>
                                    <hr className="issueInstanceSeparator" />
                                </div>
                                )
                            })
                        : null
                    }
                </Card>
            </div>
        </FadeIn>
        )
    }
}

export default Issues