import React from "react";
import { Icon } from "semantic-ui-react";


export const HomeSideBar = ({...restProps}) => {
    return (
        <div className="projectSideMenu">
            <div className="projectSideMenuMark">
                <img className="ml-deployment-stackstrLogo" src={require('../../../../assets/platforms/ml-deployment/assets/teal-diamond.svg')} />
            </div>
            <Icon onClick={() => window.location.href = '/'} className="home-icon"  name="home"  />
        </div>
    )
}