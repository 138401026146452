import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import Fade from 'react-reveal/Fade';
import { Icon } from "semantic-ui-react";
import { Carousel } from "react-responsive-carousel";
import { Tooltip } from "@material-ui/core";

export const ProjectCards = ({
    changeSlide,
    ...restProps
}) => {
    const [slide, setSlide] = useState("");
    const [isCodeOpen, setCodeOpen] = useState("");


    return (
        <div style={{marginTop: '15px', margin: '0 auto', padding: '30px'}}>

            {
                isCodeOpen.length == "" ?
                        slide == "ml_real_time_production"?

                        <div>
                            <Fade bottom>
                                <Row className="project_cards_row">
                                    <div style={{width: '500px'}}>
                                        <Button style={{marginBottom: '29px'}} className="go_back_to_projects" onClick={() => setSlide("")}> <Icon style={{marginRight: '0px', fontSize: '9px'}} name="chevron left"/> Back to all projects</Button>
                                        <h1 className="slide_title"> ML Real-Time Production </h1>
                                        <div className="slide_paragraph container">
                                            <p className="slide_paragraph" style={{marginBottom: '30px'}}> <span style={{fontWeight: 600, textDecoration: "underline"}}>What is it about:</span> A platform that monitors your ML models in production. (1) install our Python package, (2) log predictions after verifying your credentials using the SDK, and (3) view metrics of
                                            model performance in your dashboard. Receive alerts of any occurring data drift. 

                                            <br></br>
                                            <br></br>
                                            <span style={{fontWeight: 600, textDecoration: "underline"}}>What did I do:</span> Wrote all platform's frontend using React/JS/TS/SCSS/Bootstrap/Axios. Designed platform using Figma/AdobeXD. 
                                            Collaborated w/ co-founders to write SDK in Python.
                                            
                                            </p>
                                        </div>

                                        <Tooltip style={{fontSize: '16px'}} placement="top" title="Private Company Code">
                                            <p className="float-left"  style={{color: 'white', opacity: 0.3, fontSize: '14px' }}> <Icon name="code"/><span className="dribbble_design_link">Code</span></p>
                                        </Tooltip>
                                            <p className="float-right" onClick={() => window.open('https://dribbble.com/kimiakavanroodi/collections/5405239-ML-Production-Monitoring-Platform')} style={{color: 'white', fontSize: '14px'}}> <Icon name="dribbble"/><span className="dribbble_design_link">Design shots</span></p>
                                        <Button className="go_to_site_btn" style={{width: '100%'}} onClick={() => window.open('/landing-page/ml-production')}> View Site </Button>
                                    </div>
                                </Row>
                            </Fade>
                        </div>
                        : 
                        slide == "ml_deployment"?
                            <div>
                                <Fade bottom>
                                <Row className="project_cards_row">
                                    <div style={{width: '500px'}}>
                                        <Button style={{marginBottom: '29px'}}  className="go_back_to_projects" onClick={() => setSlide("")}> <Icon style={{marginRight: '0px', fontSize: '9px'}} name="chevron left"/> Back to all projects </Button>
                                        <h1 className="slide_title"> ML Deployment Platform </h1>
                                        <div className="slide_paragraph container">
                                            <p className="slide_paragraph" style={{marginBottom: '30px'}}> <span style={{fontWeight: 600, textDecoration: "underline"}}>What is it about:</span> A platform that deploys your ML model on our/your cloud, including its infrastructure. Features include
                                            model versioning, one-click rollback, & monitoring. To start, install our Python package & call stackstr.deploy(), passing in your API key, model, and name.

                                            <br></br>
                                            <br></br>
                                            <span style={{fontWeight: 600, textDecoration: "underline"}}>What did I do:</span> Wrote all platform's frontend using React/JS/TS/SCSS/Bootstrap. Designed platform using Figma. 
                                            Collaborated w/co-founders in writing SDK in Python & assisted in writing microservices in Go.
                                            
                                            </p>
                                        </div>
                                        <Tooltip placement="top" title="Private Company Code">
                                            <p className="float-left"  style={{color: 'white', opacity: 0.3, fontSize: '14px' }}> <Icon name="code"/><span className="dribbble_design_link">Code</span></p>
                                        </Tooltip>
                                            <p className="float-right" onClick={() => window.open('https://dribbble.com/kimiakavanroodi/collections/5405230-ML-Deployment-Platform')} style={{color: 'white', fontSize: '14px'}}> <Icon name="dribbble"/><span className="dribbble_design_link">Design shots</span></p>
                                            <Button className="go_to_site_btn" style={{width: '100%'}} onClick={() => window.open('/landing-page/ml-deployment')}> View Site </Button>
                                        </div>
                                    </Row>
                                </Fade>
                            </div>
                            :

                            slide == "ml_experimentation"?
                            <div>
                                <Fade bottom>
                                    <Row className="project_cards_row">
                                    <div style={{width: '500px'}}>
                                    <Button style={{marginBottom: '29px'}}  className="go_back_to_projects" onClick={() => setSlide("")}> <Icon style={{marginRight: '0px', fontSize: '9px'}} name="chevron left"/> Back to all projects </Button>
                                        <h1 className="slide_title"> ML Experimentation Platform </h1>
                                        <div className="slide_paragraph container">
                                            <p className="slide_paragraph" style={{marginBottom: '30px'}}> <span style={{fontWeight: 600, textDecoration: "underline"}}>What is it about:</span> A platform to view all training runs for your ML models in one dashboard &
                                            easily benchmark each model's performance against each other. Using our Python SDK, log features like hyperparameters, epochs, loss, & accuracy to orchestrate such experiments.
                                            <br></br>
                                            <br></br>
                                            <span style={{fontWeight: 600, textDecoration: "underline"}}>What did I do:</span> Wrote all platform's frontend using React/JS/CSS/Bootstrap. Designed platform using Figma. 
                                            Collaborated w/co-founders in writing SDK in Python & assisted in writing microservices in Go.
                                            
                                            </p>
                                        </div>
                                        <Tooltip placement="top" title="Private Company Code">
                                            <p className="float-left"  style={{color: 'white', opacity: 0.3, fontSize: '14px' }}> <Icon name="code"/><span className="dribbble_design_link">Code</span></p>
                                        </Tooltip>
                                            <p className="float-right" onClick={() => window.open("https://dribbble.com/kimiakavanroodi/collections/5405245-ML-Experimentation-Platform")} style={{color: 'white', fontSize: '14px'}}> <Icon name="dribbble"/><span className="dribbble_design_link">Design shots</span></p>
                                                <Button className="go_to_site_btn" style={{width: '100%'}} onClick={() => window.open('/landing-page/ml-experimentation')}> View Site </Button>
                                        </div>
                                    </Row>
                                </Fade>
                            </div>
                            :
                            slide == "data_analytics"?
                            <div>
                                <Fade bottom>
                                <Row className="project_cards_row">
                                    <div style={{width: '500px'}}>
                                    <Button style={{marginBottom: '29px'}}  className="go_back_to_projects" onClick={() => setSlide("")}> <Icon style={{marginRight: '0px', fontSize: '9px'}} name="chevron left"/> Back to all projects </Button>
                                        <h1 className="slide_title"> StackStr 1.0 </h1>
                                        <div className="slide_paragraph container">
                                            <p className="slide_paragraph" style={{marginBottom: '30px'}}> <span style={{fontWeight: 600, textDecoration: "underline"}}>What is it about:</span> A platform that allows you to connect your datasource and automatically
                                            clean your data. Once you clean and review the data, you then can train a ML model or immediately deploy a ML model off of the cleaned data.

                                            <br></br>
                                            <br></br>
                                            <span style={{fontWeight: 600, textDecoration: "underline"}}>What did I do:</span> Wrote all platform's frontend using React/JS/SCSS/Bootstrap. Designed platform using Figma. 
                                            
                                            </p>
                                        </div>
                                        <Tooltip placement="top" title="Private Company Code">
                                            <p className="float-left"  style={{color: 'white', opacity: 0.3, fontSize: '14px' }}> <Icon name="code"/><span className="dribbble_design_link">Code</span></p>
                                        </Tooltip>
                                            <p className="float-right" onClick={() => window.open("https://dribbble.com/kimiakavanroodi/collections/5405248-Data-Analytics-Platform")} style={{color: 'white', fontSize: '14px'}}> <Icon name="dribbble"/><span className="dribbble_design_link">Design shots</span></p>
                                        <Button className="go_to_site_btn" style={{width: '100%'}} onClick={() => window.open('/landing-page/data-analytics')}> View Site </Button>
                                        </div>
                                    </Row>
                                </Fade>
                            </div>
                            :
                            slide == "atlasplanner"?
                            <div>
                                <Fade bottom>
                                    <Row className="project_cards_row">
                                        <div style={{width: '500px'}}>
                                        <Button style={{marginBottom: '29px'}}  className="go_back_to_projects" onClick={() => setSlide("")}> <Icon style={{marginRight: '0px', fontSize: '9px'}} name="chevron left"/> Back to all projects </Button>
                                        <h1 className="slide_title"> Atlasplanner </h1>
                                        <div className="slide_paragraph container">
                                            <p className="slide_paragraph" style={{marginBottom: '30px'}}> <span style={{fontWeight: 600, textDecoration: "underline"}}>What is it about:</span> A closed-group social networking platform where mentors/advisors/etc can add their schedules onto a public user interface and students can then book one-on-one times with them. 
                                            Organizers of the event can oversee all activity occurring between both students and mentors. Think of a "shared calendly."

                                            <br></br>
                                            <br></br>
                                            <span style={{fontWeight: 600, textDecoration: "underline"}}>What did I do:</span> Sole Developer. Used React/Javascript/Bootstrap/SCSS for frontend. Used Node.js/Socket.io/Express/Mongo DB for backend.
                                            Used Firebase/GCP for deployment and authentication.
                                            
                                            </p>
                                        </div>

                                            <p className="float-left" onClick={() => setCodeOpen("atlasplanner")} style={{color: 'white', fontSize: '14px' }}> <Icon name="code"/><span className="dribbble_design_link">Code</span></p>
                                            <p className="float-right" onClick={() => window.open('https://dribbble.com/kimiakavanroodi/collections/5405249-AtlasPlanner')} style={{color: 'white', fontSize: '14px'}}> <Icon name="dribbble"/><span className="dribbble_design_link">Design shots</span></p>

                                            <Button className="go_to_site_btn" style={{width: '100%'}} onClick={() => window.open('https://atlasplanner-dev.web.app')}> View Site </Button>
                                        </div>
                                    </Row>
                                </Fade>
                            </div>
                            :
                            slide == "gymsplat"?
                            <div>
                                <Fade bottom>
                                    <Row className="project_cards_row">
                                        <div style={{width: '500px'}}>
                                        <Button style={{marginBottom: '29px'}} className="go_back_to_projects" onClick={() => setSlide("")}> <Icon style={{marginRight: '0px', fontSize: '9px'}} name="chevron left"/> Back to all projects </Button>
                                        <h1 className="slide_title"> GymSplat </h1>
                                        <div className="slide_paragraph container">
                                            <p className="slide_paragraph" style={{marginBottom: '30px'}}> <span style={{fontWeight: 600, textDecoration: "underline"}}>What is it about:</span> A platform to monitor human activity & machine usage patterns in gyms. Cameras were installed in gyms, tracked human activity, & sent 
                                            that data back to gym owners all live.

                                            <br></br>
                                            <br></br>
                                            <span style={{fontWeight: 600, textDecoration: "underline"}}>What did I do:</span> Developed dashboard using React/JS/TS/SCSS. 
                                            Used Python/Flask/Pytorch/PiCamera to detect human activity in gyms. Set up Raspberry Pis and a Python script to send requests back to 
                                            the deployed Flask app. Installed hardware in gyms. 
                                            
                                            </p>
                                        </div>
                                            <p className="float-left" onClick={() => setCodeOpen("gymsplat")} style={{color: 'white', fontSize: '14px' }}> <Icon name="code"/><span className="dribbble_design_link">Code</span></p>
                                            <p className="float-right" onClick={() => window.open('https://dribbble.com/kimiakavanroodi/collections/5405227-GymSplat')} style={{color: 'white', fontSize: '14px'}}> <Icon name="dribbble"/><span className="dribbble_design_link">Design shots</span></p>
                                            <Button className="go_to_site_btn" style={{opacity: 0.2, width: '170px'}}> Not connected </Button>
                                        </div>
                                    </Row>
                                </Fade>
                            </div>
                            :

                <div>
                    <div className="sticky_items menu-arrows-container">
                        <img style={{width: '40px', borderRadius: '100%', boxShadow: '0px -1px 20px #ffffff87'}} className="sticky_items" onClick={() => { changeSlide("down") }}   src={require('../assets/grad-up-icon.svg')} />
                        <br></br>
                        <br></br>

                        <img style={{width: '40px', borderRadius: '100%', boxShadow: '0px -1px 20px #ffffff87'}} className="sticky_items"  onClick={() => { changeSlide("up") }} src={require('../assets/grad-down-icon.svg')} />
                    </div>

                    <div className="slide_3">
                        <Fade delay={500} bottom cascade>
                            <div className="slide_3">
                            <p className="slide_3 slide_5_white_text"> All archived works  </p>
                            <p className="slide_3 text-center"> <span className="slide_3 slide_5_gradient_text"> Experience each project.  </span></p>
                            </div>
                        </Fade>
                    </div>

                    <div className="sticky_items desktop-project-slideshow">
                        <Row style={{justifyContent: "center", marginTop: '0px'}}>
                            <Fade delay={1100}>
                                <img onClick={() => setSlide("atlasplanner")} style={{position: 'relative', cursor: 'pointer', marginRight: '-30px',  width: '250px', height: '250px'}} className="slide_3" src={require('../assets/slide_5_atlasplanner.svg')} />
                            </Fade>

                            <Fade delay={300}>
                                <img className="slide_3 project_card" onClick={() => setSlide("ml_real_time_production")} style={{position: 'relative', cursor: 'pointer', marginRight: '-30px', width: '250px', height: '250px'}}  src={require('../assets/slide_5_ml_real_time_production.svg')} />                                                
                            </Fade>

                            <Fade delay={1300}>
                                <img onClick={() => setSlide("gymsplat")} style={{position: 'relative', marginRight: '-30px', cursor: 'pointer', width: '250px', opacity: 0.1, height: '250px'}} className="slide_3" src={require('../assets/slide_5_gymsplat.svg')} />
                            </Fade>

                    
                        </Row>

                        <Row style={{justifyContent: "center", marginTop: '-60px'}}>
                            <Fade delay={500}>
                                <img onClick={() => setSlide("ml_deployment")} style={{position: 'relative', cursor: 'pointer',  marginRight: '-30px', width: '250px', height: '250px'}} className="slide_3" src={require('../assets/slide_5_ml_deployment.svg')} />
                            </Fade>

                            <Fade  delay={700}>
                                <img onClick={() => setSlide("ml_experimentation")} style={{position: 'relative', cursor: 'pointer',  marginRight: '-30px', width: '250px', height: '250px'}} className="slide_3" src={require('../assets/../assets/slide_5_experimentation.svg')} />
                            </Fade>

                            <Fade delay={900}>
                                <img onClick={() => setSlide("data_analytics")} style={{position: 'relative', cursor: 'pointer',  marginRight: '-30px', width: '250px', height: '250px'}} className="slide_3" src={require('../assets/slide_5_data_analytics.svg')} />
                            </Fade>
                        </Row>
                    </div>

                        <Fade delay={600} bottom>
                        <div className="sticky_items mobile-project-carousel">
                            <Carousel autoPlay width={300} onClickItem={(type) => console.log(type)} showThumbs={false} dynamicHeight={true} swipeable={true} className="slide_3" showArrows={false} >

                            <div onClick={() => setSlide("ml_real_time_production")} className="slide_3">
                                <img style={{position: 'relative', width: '300px', height: '300px'}} className="slide_3" src={require('../assets/slide_5_ml_real_time_production.svg')} />
                            </div>

                            <div onClick={() => setSlide("ml_deployment")}className="slide_3">
                                <img style={{position: 'relative', width: '300px', height: '300px'}} className="slide_3" src={require('../assets/slide_5_ml_deployment.svg')} />
                            </div>

                            <div onClick={() => setSlide("ml_experimentation")} className="slide_3">
                            <img style={{position: 'relative', cursor: 'pointer',  marginRight: '-30px', width: '300px', height: '300px'}} className="slide_3" src={require('../assets/../assets/slide_5_experimentation.svg')} />
                            </div>

                            <div onClick={() => setSlide("data_analytics")} className="slide_3">
                            <img  style={{position: 'relative', cursor: 'pointer',  marginRight: '-30px', width: '300px', height: '300px'}} className="slide_3" src={require('../assets/slide_5_data_analytics.svg')} />
                            </div>

                            <div className="slide_3" onClick={() => setSlide("atlasplanner")}>
                            <img style={{position: 'relative', width: '300px', height: '300px'}} className="slide_3" src={require('../assets/slide_5_atlasplanner.svg')} />
                            </div>

                            <div className="slide_3" onClick={() => setSlide("gymsplat")}>
                            <img style={{position: 'relative', width: '300px', height: '300px'}} className="slide_3" src={require('../assets/slide_5_gymsplat.svg')} />
                            </div>
                        </Carousel>
                        </div>
                        </Fade>
                        </div>

                : 

                isCodeOpen == "gymsplat" ?
                <div>
                    <Fade bottom>
                        <Row className="project_cards_row">
                            <div style={{width: '500px'}}>
                                <Button style={{marginBottom: '29px'}} className="go_back_to_projects" onClick={() => setCodeOpen("")}> <Icon style={{marginRight: '0px', fontSize: '9px'}} name="chevron left"/> Back </Button>
                                <h1 className="slide_title" style={{marginBottom: '18px'}}> Code </h1>
                                <Button style={{width: '100%', border: '1px solid white', fontSize: '14px', height: '40px', borderRadius: '100px', marginBottom: '15px', background: 'none'}} onClick={() => window.open('https://github.com/kimiakavanroodi/gymsplat-webapp-v2')}> Dashboard React App </Button>
                                <br></br>
                                <Button style={{width: '100%', border: '1px solid white', fontSize: '14px', height: '40px', borderRadius: '100px', background: 'none'}} onClick={() => window.open('https://github.com/kimiakavanroodi/raspberry-pi-flask-app')}> Flask App </Button>
                            </div>
                        </Row>
                    </Fade>
                </div>
                : 

                isCodeOpen == "atlasplanner" ?
                <div>
                <Fade bottom>
                    <Row className="project_cards_row">
                        <div style={{width: '500px'}}>
                            <Button style={{marginBottom: '29px'}} className="go_back_to_projects" onClick={() => setCodeOpen("")}> <Icon style={{marginRight: '0px', fontSize: '9px'}} name="chevron left"/> Back </Button>
                            <h1 className="slide_title" style={{marginBottom: '18px'}}> Code </h1>
                            <Button style={{width: '100%', border: '1px solid white', height: '40px', borderRadius: '100px', marginBottom: '15px', fontSize: '14px', background: 'none'}} onClick={() => window.open('https://github.com/kimiakavanroodi/atlasplanner')}> Frontend React App </Button>
                            <br></br>
                            <Button style={{width: '100%', fontSize: '14px', border: '1px solid white',height: '40px', borderRadius: '100px', background: 'none'}} onClick={() => window.open('https://github.com/kimiakavanroodi/atlas-api')}> Backend Express App </Button>
                        </div>
                    </Row>
                </Fade>
            </div>

                :
                null
            }

      </div>
    )
}