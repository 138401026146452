import React, { useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';

export const UserBanner = ({teamId, ...restProps}) => {
    const [username, setUsername] = useState("Test")

    return (
        <Dropdown id="user_settings" className="userProfileDropdownBtn" icon={null} 
            style={{marginLeft: 16, top: 8}} text={ <div>
                <Icon style={{color: 'white'}} name="user" /> <span style={{marginLeft: '10'}} className="usernameText"> {username} </span></div> }>
            <Dropdown.Menu style={{left: -20}} >
                <Dropdown.Item icon="user outline"  text='Settings' />
                <Dropdown.Divider />
                <Dropdown.Item text='Docs'  />
                <Dropdown.Item  text='Tutorial'  />
                <Dropdown.Divider />
                <Dropdown.Item text='Logout'  />
            </Dropdown.Menu>
        </Dropdown>
    )
}