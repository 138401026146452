import React from "react";
import { ScalePods } from "./ScalePods";

export function TabManager(tabName, model, version, operations) {
    switch (tabName) {
        case "Pods":
            return <ScalePods
                        version={version}
                        model={model}
                        refreshData={operations.refreshData}
                    />
        case "Endpoint":
            return
        case "Usage":
            return
    }
}

export const tabNames = [
    {
        "name": "Pods",
        "route": "pods",
        "title": "Cluster Resources",
        "description": "Manually or auto-scale your clusters",
        "img": 'pod-scaling.svg'
    }, {
        "name": "Endpoint",
        "route": "endpoint",
        "title": "Endpoint Settings",
        "description": "Customize your endpoint",
        "img": 'endpoint-custom.svg'
    }, {
        "name": "Usage",
        "route": "usage-and-billing",
        "title": "Usage & Billing",
        "description": "Details about your billing & usage info",
        "img": 'diamond-point.svg'
    }]