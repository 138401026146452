import React from 'react';
import 'react-animated-term/dist/react-animated-term.css'
import { Button, CardBody, Col, Row, Input, Modal } from 'reactstrap';
import "react-step-progress-bar/styles.css";
import 'semantic-ui-css/semantic.min.css'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dropdown, Icon, Popup } from 'semantic-ui-react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Terminal from 'react-animated-term'
import 'react-animated-term/dist/react-animated-term.css'
import { Card, Slider, TextField } from '@material-ui/core';
import { Header } from "../../pages/landing-pages/components/ml-production/components/Header";
import { Footer } from '../../pages/landing-pages/components/ml-production/components/Footer';
import { DropdownMenu, MobileMenu } from '../../pages/landing-pages/components/ml-production/components/MobileMenu';
import ModalExampleModal, { SignUpModal } from '../../pages/landing-pages/components/ml-production/components/SignUpModal';
import "../../styles/landing-pages/ml-deployment.scss"
import { BlackBanner } from '../../components/BlackBanner';

const spinner = ['⠋', '⠙', '⠹', '⠸', '⠼', '⠴', '⠦', '⠧', '⠇', '⠏']
const termLines = [
  {
    text: 'import stackstr',
    cmd: true,
    delay: 5
  },
  {
    text: `stackstr.deploy(api-key, model, name)`,
    cmd: true,
    delay: 5
  },
  {
    text: '✔ Packaged model',
    cmd: false,
    repeat: true,
    repeatCount: 2,
    frames: spinner.map(function (spinner) {
      return {
        text: spinner + ' Creating endpoint',
        delay: 10
      }
    })
  },
  {
    text: `Model deployed at: https://api.stackstr.io/predict/model-name`,
    cmd: true,
    delay: 5
  },
]
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});

class MLDeployment extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            isLoading: false,
            email: "",
            menu: false
        }
    }


    componentDidMount() {
      console.log("Welcome! Seems like you're inspecting - the artist behind this is kimia kavanroodi (https://linkedin.com/in/kimiakavanroodi1). Feel free to roam around")
      if (this.props.location.state && this.props.location.state.section) {
        document.getElementById("point-features").scrollIntoView()

        let state = { ...this.props.history.location.state };
        delete state.section;
        this.props.history.replace({ ...this.props.history.location, state });
      }

      const images = document.querySelectorAll('.slideshow__caption');

      console.log(images)
       
        
        const config = {
        root: '.slideshow__panel',
        rootMargin: '30px',
        threshold: 1.0
      };

      const observer = new IntersectionObserver((entries) => {
        console.log('here')
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {      
            //entry.target.classList.remove('hidden');
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
            //entry.target.classList.add('hidden');
          }
        });
      });

        images.forEach(image => {
          console.log('image')
          observer.observe(image);
        });

    }

    componentDidUpdate() {
      if (this.props.location.state && this.props.location.state.section) {
        document.getElementById("point-features").scrollIntoView()

        let state = { ...this.props.history.location.state };
        delete state.section;
        this.props.history.replace({ ...this.props.history.location, state });
      }
    }

    submitEmail = async(email) => {
   
  }

      
    render() {

  

        return (
            <div id="home_bg_foreground" className="ml-deployment-home-body"> 

            <div></div>


            <div className="left-blue-circle"></div>
            <div className="clear-matte-circle"></div>
            <div className="pink-circle"></div>

            <img className="aura-ball" src={require("../../assets/landing-pages/ml-deployment/assets/aura-ball.svg")} />
            <img className="pop-art-diamond" src={require('../../assets/landing-pages/ml-deployment/assets/pop-art-diamond.svg')} />
            <img className="floating-dark-hexagon" src={require('../../assets/landing-pages/ml-deployment/assets/floating-dark-hexagon.svg')} />
            <img className="part-diamond" src={require('../../assets/landing-pages/ml-deployment/assets/part-diamond.svg')} />
            <img className="pink-diamond" src={require('../../assets/landing-pages/ml-deployment/assets/pink-diamond.svg')} />
            <img className="glowy-small-ring" src={require('../../assets/landing-pages/ml-deployment/assets/glowy-small-ring.svg')} />
            <div className="right-blue-circle"></div>
            <div className="gradient-blue-circle"></div>
            <div className="right-pink-circle "></div>


            <Header />

            <BlackBanner content="This platform was built for StackStr and has since been archived" />
        
            
              <div className="container main-top-section-padding">

              <div className="main-top-section">
                <Row>
                  <Col sm={6}>
                    <p className="hidden-title"> the simplest way to deploy models. </p>
                    <p className="mainFirstLineTitle"> The Simplest way </p>
                    <p className="mainSecondLineTitle"> to deploy models </p>
                    <p className="mainDescription"> With one line of code, you can deploy your ML model, anytime, on our cloud or yours. </p>
                    <Row> 
                      <Button onClick={() => window.location.href = '/ml-deployment/dashboard'} className="waitlist-btn"> See Live Dashboard </Button> 
                    </Row>
                  </Col>
                  <Col sm={5}>
                    <img className="model-tag" src={require('../../assets/landing-pages/ml-deployment/assets/model-tag.svg')} />
                    <Card className="terminal-card-1">
                        <CardBody style={{marginBottom: '50px'}}>
                          <Card  className="terminal-card-2">
                                <CardBody >
                                  <Card  className="terminal-card">
                                    <CardBody style={{marginRight: '35px'}}>
                                      <Terminal
                                        lines={termLines}
                                        interval={80}
                                      />
                                    </CardBody>
                                  </Card>
                              </CardBody>
                        </Card>
                      </CardBody>
                    </Card>
                  <img className="stack-of-diamonds" src={require('../../assets/landing-pages/ml-deployment/assets/stack-of-diamonds.svg')} />

                  </Col>
                </Row>
              </div>



        <div data-aos="fade-up"  data-aos-duration="3000" class="">
          <div  data-aos-duration="3000" data-aos="fade-up">
            <div class="slideshow__caption visible row feature-section">                
                <Col>
                  <img className="feature-small-img" src={require('../../assets/landing-pages/ml-deployment/assets/flower-ring.svg')} />
                  <p className="small-feature-text"> AN EASY FIRST STEP </p>
                  <p className="feature-h1"> it all starts with one line</p>
                  <p className="feature-p1"> After installing and importing StackStr, call stackstr.deploy() and pass in your API key, model, and model name. You will then receive a unique endpoint - ready for predictions! </p>

                  <hr className="feature-divider" />

                </Col>
                <Col sm={'0'}>
                  <img className="feature-img"  src={require('../../assets/landing-pages/ml-deployment/assets/deployed-messages.svg')} />                  
                </Col>
            </div>
          </div>

            <Row data-aos="fade-up"  data-aos-duration="3000" className="feature-section row-1">
                  <Col sm={'0'}>
                    <img className="feature-img"  src={require('../../assets/landing-pages/ml-deployment/assets/curl-statement.svg')} />                  
                  </Col>
                  <Col>
                    <img className="feature-small-img" src={require('../../assets/landing-pages/ml-deployment/assets/flower-ring.svg')} />
                    <p className="small-feature-text"> AN EASY FIRST STEP </p>
                    <p className="feature-h1"> it all starts with one line</p>
                    <p className="feature-p1"> After installing and importing StackStr, call stackstr.deploy() and pass in your API key, model, and model name. You will then receive a unique endpoint - ready for predictions! </p>

                    <hr className="feature-divider" />
                  </Col>
              </Row>

          <div class="slideshow__panel">
            <Row data-aos-duration="3000"  style={{justifyContent: 'center'}} className="feature-section slideshow__caption">
                  <Col sm={'0'}>
               
                    <img className="feature-small-img" style={{marginLeft: -20}} src={require('../../assets/landing-pages/ml-deployment/assets/three-triangles.svg')} />
                    <p className="small-feature-text"> RUN, MANAGE, MONITOR </p>
                    <p className="feature-h1"> production-grade APIs in minutes</p>
                    <p className="feature-p1">Avoid Unscalable hacky APIs by deploying with StackStr. StackStr builds a production grade serving API in minutes. </p>
                    <hr className="feature-divider" />

                  </Col>

                    <Col style={{position: 'relative', display: 'flex', flexDirection: "column", justifyContent: 'center'}} sm={'0'}>

                    <div>

                <Card style={{position: 'relative', zIndex: 999}} className="settings-main-body-card">
                  <img className="backdrop-index-cards" style={{mixBlendMode: 'hue'}}  src={require('../../assets/landing-pages/ml-deployment/assets/backdrop-pod.svg')} />
                <img style={{mixBlendMode: 'hue'}} className="ball-on-cube" src={require('../../assets/landing-pages/ml-deployment/assets/ball-on-cube.svg')} />
                   
                    <CardBody>


                        <Row className="cluster-options" style={{justifyContent: 'space-between', paddingLeft: '20px', zIndex: 9999, position: 'relative', marginBottom: '10px', paddingBottom: '8px', paddingRight: '20px'}}>

                            <div style={{marginTop: '3px'}}>

                            </div>

                            <br></br>

                            <div style={{marginTop: '6px'}}>

                                <p className="settings-version-number"> v0.0.1
                                    <span className="settings-status"> <Icon style={{fontSize: '9px', position: 'relative', top: '-2px'}} name="circle outline green-light"/> Deployed  </span>

                                    <span style={{marginRight: '15px', cursor: 'pointer'}}>

                                      <Icon style={{color: 'rgba(255, 255, 255, 0.52)', marginRight: '5px'}} name="undo alternate" />
                                          <Dropdown text="Rollback">
                                              <Dropdown.Menu> 
                                                 <Dropdown.Item> v0.0.2 </Dropdown.Item>
                                                   
                                              </Dropdown.Menu>


                                          </Dropdown>

                                      </span>
                                    <span className="settings-endpoint-URL"> api.stackstr.io/predict/stackstr-models

                                        <Popup  
                                            style={{background: 'black', }}
                                            inverted
                                            on="click"
                                            onOpen={() => this.setState({ copied : true }) }
                                            content="Copied URL"
                                            trigger={<Icon style={{color: 'green', marginLeft: '8px', cursor: 'pointer', fontSize: '11px'}}  name="check" />}
                                        />

                                    </span>
                                </p> 

                            </div>


                        </Row>

                        <Row>
                            

                            <Col>
                                <Card className="settings-subbody-card">
                                    <CardBody style={{padding: '1.95rem'}}>
                                      
                        <p className="settings-subtab-title">Pod configuration 
                            <Popup
                                inverted 
                                style={{background: 'black'}} 
                                content="Here is some information about the pod configuration" 
                                trigger={<Icon style={{color: 'rgba(255, 255, 255, 0.27)', cursor: 'pointer', marginLeft: '4px'}} name="info circle" />} 
                            />
                        </p>

                        <p className="settings-subtab-subtitle"> Scale the number of pods you want to use for your model </p>

                        <Card style={{marginTop: '30px'}} className="card-progress-bar">
                            <CardBody >
                                <br></br>
                                <br></br>

                            <Slider
                                defaultValue={0}
                                inverted
                                value={60}
                                aria-labelledby="discrete-slider-small-steps"
                                step={1}
                                min={0}
                                max={100}
                                valueLabelDisplay="auto"
                            />

                            <br></br>
                            <br></br>

                            <TextField
                                style={{marginRight: '25px'}}
                                id="standard-error-helper-text"
                                label="CPU Utilization"
                                defaultValue={`0%`}
                                helperText=""
                            />

                            <TextField
                                style={{marginRight: '25px'}}
                                id="standard-error-helper-text"
                                label="Memory Utilization"
                                defaultValue={`0%`}
                                helperText=""
                            />

                            </CardBody>
                        </Card>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                        <br></br>

                    </CardBody>
                    <img className="large-3d-hexagon" style={{mixBlendMode: 'color-dodge'}} src={require('../../assets/landing-pages/ml-deployment/assets/large-3d-hexagon.svg')} />

                </Card>

            </div>


                
                    </Col>
                  </Row>
                  <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          </div>
        </div>

        
            
          
        

              
                  </div>


                  <div  className="pivotal-section">

                    <div data-aos-duration="3000" data-aos="fade-up" className="container">
             

                    <div className="light-gradient-card" style={{marginTop: 50}}>

                    <img style={{mixBlendMode: 'luminosity', marginBottom: 20}} src={require('../../assets/landing-pages/ml-deployment/assets/flower-ring.svg')} />

                      <p  className="light-gradient-card-h1"> where we made sure to include everything </p>
                      <p className="light-gradient-card-p"> Stackstr automates your machine learning infrastructure so you can focus on what you love, building models. From canary deployments to GPU resources, we have you covered. </p>
                      </div>

                <br></br>
                <br></br>

                <div id="point-features" className="stretch-bg">

                  <div data-aos-duration="3000" data-aos="fade-up"  className="left-light-purple-circle"></div>
                  <div data-aos-duration="3000" data-aos="fade-up"  className="left-light-pink-circle"></div>
                  <div data-aos-duration="3000" data-aos="fade-up"  className="left-radial-circle"></div>

                  <div data-aos-duration="3000" data-aos="fade-up"  className="right-light-purple-circle"></div>
                  <div className="right-light-teal-circle"></div>
                  <div data-aos-duration="3000" data-aos="fade-up"  className="right-radial-circle"></div>
                  <div className="right-pink-radial-circle"></div>
                  <div data-aos-duration="3000" data-aos="fade-up"  className="right-lower-pink-radial-circle"></div>


                      <Row  data-aos-duration="3000" data-aos="fade-up"   style={{justifyContent: 'center'}}>
                        <Col sm={'0'}>

                        <img className="feature-img" style={{padding: '30px', marginTop: -10, position: "relative"}} src={require('../../assets/landing-pages/ml-deployment/assets/pop-art.svg')} />

                        </Col>
                        
                        {
                          // console.log(document.getElementById("point-features").scrollIntoView())
                        }

                        <Col sm={'0'} style={{padding: '30px'}}>

                          <Row>
                            <Col> 
                              <p className="feature-h3"> Model Versioning </p>
                              <p className="feature-p2">StackStr implements semantic versioning so you can version your models and keep track of how they are performing</p>

                            </Col>
                            <Col>
                            <p className="feature-h3"> Monitoring </p>
                              <p className="feature-p2">StackStr provides a variety of metrics that allow you to monitor your models health.</p>
                            </Col>
                          </Row>
                          <br></br>
                          <br></br>

                          <Row>
                          <Col> 
                              <p className="feature-h3"> Canary Deployment </p>
                              <p className="feature-p2"> Roll out your model to a percentage of your user base.</p>

                            </Col>
                            <Col>
                            <p className="feature-h3"> One-Click Rollback </p>
                              <p className="feature-p2">Is your model not performing as well as you'd like? Rollback to the last version with a click of a button.</p>
                            </Col>
                          </Row>

                          <br></br>
                          <br></br>

                          <hr className="feature-divider"  style={{marginTop: '0px', marginBottom: '10px'}} className="feature-divider" />

                        </Col>
                      </Row>
                      
                      </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>


                      <div className="stretch-bg">
                      <img className="blue-square-square " src={require('../../assets/landing-pages/ml-deployment/assets/glowy-left-square.svg')} />
                      <img className="glowy-triangle" src={require('../../assets/landing-pages/ml-deployment/assets/glowy-triangle.svg')} />
                      <div className="circle-blur"></div>
                      <div className="circle-right-blur" style={{backdropFilter: 'blur(0px)'}}></div>
                      <div style={{top: '305px', mixBlendMode: 'normal'}} className="circle-right-blur"></div>
                      <img className="half-circle" src={require('../../assets/landing-pages/ml-deployment/assets/half-circle.svg')} />

                      <Row data-aos-duration="3000" data-aos="fade-up"   style={{justifyContent: 'center'}} className="feature-section row-1 feature-section">

                            <Col sm={"0"}> 
                              <img className="feature-img" src={require('../../assets/landing-pages/ml-deployment/assets/dashboard.svg')} />

                            </Col>
                            <Col sm={"0"} style={{padding: '20px'}}>
                              <img className="feature-small-img" src={require('../../assets/landing-pages/ml-deployment/assets/on-cube.svg')} />
                              <p  className="small-feature-text"> IT ALL STARTS HERE </p>
                              <p className="feature-h1">in an all-in-one <span className="underline-word">tool</span>.</p>
                              <p className="feature-p1"> From notebook to production, deploy with StackStr and have confidence in your model. We manage, run, and monitor your model.</p>

                            </Col>
                    
                      </Row>
                      </div>

                <Footer />
              </div>
              </div>

 

              <ToastContainer />
            </div>
        )
    }
}

export default MLDeployment;