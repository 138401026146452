import React, { Component } from 'react'
import { CodeEditorEditable } from 'react-code-editor-editable'
import 'highlight.js/scss/ir-black.scss'
import FadeIn from 'react-fade-in';
import { CircularProgress } from '@material-ui/core';

const writeCode = () => {
    return `
    from pandas import read_csv
    from sklearn.model_selection import train_test_split
    from sklearn.preprocessing import LabelEncoder
    from tensorflow.keras import Sequential
    from tensorflow.keras.layers import Dense
    import tensorflow as tf
    from voyager import Voyager
    import keras
    
    # import voyager.voyager
    voy = Voyager('Sample API Key', 'Project Name')
    
    voy.log_metric('random_metric_2', 31)
    voy.log_metric('accuracy', 2.1)
    
    mnist = tf.keras.datasets.mnist
    (x_train, y_train), (x_test, y_test) = mnist.load_data()
    x_train, x_test = x_train / 255.0, x_test / 255.0
    
    model = tf.keras.models.Sequential([
      tf.keras.layers.Flatten(input_shape=(28, 28)),
      tf.keras.layers.Dense(128, activation='relu'),
      tf.keras.layers.Dropout(0.2),
      tf.keras.layers.Dense(10)
    ])
    
    predictions = model(x_train[:1]).numpy()
    
    loss_fn = tf.keras.losses.SparseCategoricalCrossentropy(from_logits=True)
    model.compile(optimizer='adam',
                  loss=loss_fn,
                  metrics=['accuracy'])
    
    model.fit(x_train, y_train, epochs=50, steps_per_epoch=10, batch_size=128, callbacks=[voy.keras_callback()])`;
};

export default class LogCodeScript extends Component {
    
    state = { 
        value: writeCode(), 
        experiment: this.props.experiment,
        isLoading: true,
    }

    isLoadingScript() {
        this.setState({ isLoading : false }); 
    };

    componentDidMount() {
       setTimeout(() => {
            this.isLoadingScript();
       });
    };

    render() {
        if (this.state.isLoading) {
            return <div className="centerLoader"> 
                <CircularProgress style={{color: 'rgb(229, 140, 101)'}}  /> </div> 
        }

        return (
            <FadeIn>
                <div className='center'>
                    <CodeEditorEditable
                        style={{height: '70vh'}}
                        borderRadius="0px"
                        tabSize={5}
                        caretColor="agate"
                        value={this.state.value}
                        setValue={(value) => this.setState({ value : value })}
                        width='50vw'
                        height='60vh'
                        language='python'
                        inlineNumbers
                    />
                </div>
            </FadeIn>
        )
    }
}