import React from "react";
import { Col, Row } from "reactstrap";
import Fade from 'react-reveal/Fade';

export class SnappingPoint extends React.Component {
    state={
        menu: false
    }

    openMenu = () => {

        console.log("Menu status:", this.state.menuOn)
  
        if (this.state.menuOn) {
            this.scaleWindowAgain()
        } else {
          this.scaleWindow().then(() => {
            setTimeout(() => {
              document.getElementById("home_bg_foreground").classList.add("shrink-mini-window")
              document.getElementById("home_bg_foreground").classList.remove("maximize-mini-window")
            }, 600)
          })
        }
  
        var toggle = document.querySelector('.hamburger');
        toggle.classList.toggle('active');
  
        this.setState({ menuOn : !this.state.menuOn  })
      }
  
      scaleWindow = async() => {
          document.getElementById("home_bg_foreground").classList.add("scale_window")
      }
  
      scaleWindowAgain = async() => {
        document.getElementById("home_bg_foreground").classList.remove("scale_window")
        document.getElementById("home_bg_foreground").classList.remove("shrink-mini-window")
        document.getElementById("home_bg_foreground").classList.add("maximize-mini-window")
      }
  

    render() {
        return (
            <div id="home_bg_foreground"  className="sticky_items home_bg" >

                {
                this.state.menuOn ?
                    <Fade top>
                        <div className="sticky_items opened-menu">
                        <p className=" sticky_items opened-menu-text"> Home </p>
                        <p className="sticky_items opened-menu-text"> About </p>
                        <p className="sticky_items opened-menu-text"> Resume </p>
                        <p onClick={() => { this.setSlideNum(5).then(() => this.matchSlideToProgress("slide_5")); this.openMenu();  }} className="sticky_items opened-menu-text"> Projects </p>
                        <p className="sticky_items opened-menu-text"> Designs </p>
                        </div>
                    </Fade>

                : null
                }


        <div id="menu_icon" className="sticky_items hamburger" onClick={this.openMenu}>
            <span className=" sticky_items hamburger__patty"></span>
            <span className="sticky_items hamburger__patty"></span>
          </div>


            <section style={{background: "linear-gradient(180deg, #080C1E 26.73%, #080C1E 73.07%, #030653 89.98%, #276468 102.02%)"}}>
                    
              <div className="slide_1">

                        
                {/* SLIDE 1 - TOP LEFT ANIMATED GROOVES */}
                    <div className="slide_1 slide_1_tl_animated_groove_1"></div>
                    <div className="slide_1 slide_1_tl_animated_groove_2"></div>
                    <div className="slide_1 slide_1_tl_animated_groove_3"></div>
                    <div className="slide_1 slide_1_tl_animated_groove_4"></div>
                    <div className="slide_1 slide_1_tl_animated_groove_5"></div>
                    <div className="slide_1 slide_1_tl_animated_groove_6"></div>


                <div className="slide_1 slide_1_container">
                    <Row style={{justifyContent: 'center'}} className="slide_1 slide_1_row_1">

                    <Col className="slide_1 slide_1_col_1">
                        <Fade delay={400} style={{zIndex: 999}} bottom cascade>
                        <div className="slide_1" style={{zIndex: 999}}>
                            <p style={{zIndex: 999}} className="hi_i_am slide_1"> Hi, I am </p>
                            <span className="slide_1"> <p style={{zIndex: 999}} className="kimia_txt slide_1"> Kimia. </p></span>
                        </div>
                        </Fade>
                    </Col>

                    <Col className="slide_1">
                    
                    </Col>
                    </Row>
                    <br></br>
                    <Row style={{justifyContent: 'center'}} className="slide_1 slide_1_row_1">


                    {/* FLOATING OBJECTS */}
                            <Fade delay={600}>
                            <div style={{position: 'relative'}} className="slide_1">

                                <img className="slide_1 slide_1_floating_hexagon" src={require('../assets/slide_1_floating_hexagon.svg')} />
                                <img className="slide_1 slide_1_floating_pink_triangle" src={require('../assets/slide_1_floating_pink_triangle.svg')} />
                                <img className="slide_1 slide_1_pearl_img" src={require('../assets/slide_1_pearl_img.svg')} />
                                <img className="slide_1 slide_1_blue_diamond" src={require('../assets/slide_1_blue_diamond.svg')} />
                                <img className="slide_1 slide_1_purple_ball" src={require('../assets/slide_1_purple_pearl.svg')} />
                                <img className="slide_1 slide_1_glowy_rectangle" src={require('../assets/slide_1_glowy_rectangle.svg')} />

                                <img className="slide_1 castle" src={require('../assets/slide_1_castle.svg')} />
                                </div>
                                </Fade>
                        {/* </Fade> */}
                    </Row>
                </div>



                </div>
                </section>
                <section style={{background: "linear-gradient(180deg, #080C1E 26.73%, #080C1E 73.07%, #030653 89.98%, #276468 102.02%)"}}>
                    
                <div className="slide_2">

                        {
                            /*
                        / Slide 2 ANIMATIONS - Top Left Corner
                            */
                        }
                        <div className="slide_2 slide_2_tl_animated_groove_1"></div>
                        <div className="slide_2 slide_2_tl_animated_groove_2"></div>
                        <div className="slide_2 slide_2_tl_animated_groove_3"></div>
                        <div className="slide_2 slide_2_tl_animated_groove_4"></div>
                        <div className="slide_2 slide_2_tl_animated_groove_5"></div>
                        <div className="slide_2 slide_2_tl_animated_groove_6"></div>
                        {/* <div className="slide_2 slide_2_tl_animated_groove_7"></div> */}


                        {
                            /*
                        / Slide 2 ANIMATIONS - Bottom Right Corner
                            */
                        }

                        <div className="slide_2 slide_2_br_animated_groove_1"></div>
                        <div className="slide_2 slide_2_br_animated_groove_2"></div>
                        <div className="slide_2 slide_2_br_animated_groove_3"></div>



                            {/* <img className="slide_2 slide_2_grooves" src={require('../assets/slide_2_grooves.svg')} /> */}
                            <img className="slide_2 slide_2_glow_stick" src={require('../assets/slide_2_glow_stick.svg')} />
                            <img className="slide_2 slide_2_floating_hexagon floating" src={require('../assets/slide_2_floating_hexagon.svg')} />
                            {/* <img className="slide_2 slide_2_bottom_right_curves" src={require('../assets/slide_2_bottom_right_curves.svg')} /> */}

                            <Row className="slide_2 slide_2_row">
                                <Col className="slide_2" style={{paddingRight: '30px'}}>
                                <Fade delay={200} bottom cascade>
                                        <div className="slide_2">
                                        <p className="slide_2 slide_2_white_text"> I first started with   </p>
                                        <br></br>
                                        <p className="slide_2 slide_2_gradient_text"> graphic design </p>
                                        </div>
                                    </Fade>
                            </Col>
                            <Col className="slide_2 slide_2_main_img">
                                <Fade delay={500} top cascade>
                                    <img className="slide_2 slide_2_bubble_1" src={require('../assets/slide_2_bubble_1.svg')} />
                                    <img className="slide_2 slide_2_bubble_2" src={require('../assets/slide_2_bubble_2.svg')} />
                                    <img className="slide_2 slide_2_bubble_3" src={require('../assets/slide_2_bubble_3.svg')} />

                                    <img className="slide_2" src={require('../assets/slide_2_main_img.svg')} />
                                </Fade>
                            </Col>
                            </Row>

                        </div>
                </section>
                <section>
                    <h1>Section Three</h1>
                </section>
                <section>
                    <h1>Section Four</h1>
                </section>
                <section>
                    <h1>Section Five</h1>
                </section>
            </div>
        )
    }
}