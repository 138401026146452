import {  LinearProgress, Slider, TextField } from "@material-ui/core";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { CardBody, Button, Row, Card, } from 'reactstrap';
import { Icon, Popup } from "semantic-ui-react";

export class ScalePods extends React.Component {
    state={
        version: this.props.version, 
        model: this.props.model,
        deploymentConfig: { 'cpu_limit': 23, 'memory_limit': 23  },
        changedConfig: [],
        isLoading: true
    }

    refreshData = this.props.refreshData

    getDeployConfig = async(modelName) => {
        this.setState({ deploymentConfig : { 'cpu_limit': 23, 'memory_limit': 23, 'replicas': 45  } })
        this.setState({ changedConfig :  { 'cpu_limit': 23, 'memory_limit': 23, 'replicas': 45  } })
        this.setState({ isLoading : false })
    };

    saveDeployConfig = async(modelName) => {
        toast.dark('Saved the new configuration!')
    };

    handleSliderChange = (event, value) => {
        var copiedObj = this.state.changedConfig
        copiedObj['replicas'] = value
        this.setState({ changedConfig : copiedObj })
    };

    handleTextChange = (key, value) => {
        var copiedObj = this.state.changedConfig
        copiedObj[key] = value
        this.setState({ changedConfig : copiedObj })
    };

    componentDidMount() {
        this.getDeployConfig(this.state.model)
    };

    render() {
        if (this.state.isLoading) {
            return <LinearProgress color="secondary" />
        }
        console.log(this.state.changedConfig)

        return (
            <div style={{paddingRight: '300px'}}>
                <p className="settings-subtab-title"> Cluster resources </p>
                <p className="settings-subtab-subtitle"> Customize memory and CPU resources for your model </p>

                <p className="settings-subtab-title">Pod configuration 
                    <Popup 
                        inverted 
                        style={{background: 'black'}} 
                        content="Here is some information about the pod configuration" 
                        trigger={<Icon style={{color: 'rgba(255, 255, 255, 0.27)', cursor: 'pointer', marginLeft: '4px'}} name="info circle" />} 
                    />
                </p>

                <p className="settings-subtab-subtitle"> Scale the number of pods you want to use for your model </p>

                <Card style={{marginTop: '30px'}} className="card-progress-bar">
                    <CardBody >
                        <br></br>
                        <br></br>

                    <Slider
                        defaultValue={this.state.changedConfig.replicas}
                        inverted
                        aria-labelledby="discrete-slider-small-steps"
                        step={1}
                        onChange={this.handleSliderChange}
                        min={0}
                        max={100}
                        valueLabelDisplay="auto"
                    />

                    <br></br>
                    <br></br>

                    <TextField
                        style={{marginRight: '25px'}}
                        id="standard-error-helper-text"
                        label="CPU Utilization"
                        onChange={(text) => this.handleTextChange('cpu_limit', text.target.value)}
                        defaultValue={this.state.changedConfig.cpu_limit}
                        helperText=""
                    />

                    <TextField
                        style={{marginRight: '25px'}}
                        id="standard-error-helper-text"
                        label="Memory Utilization"
                        onChange={(text) => this.handleTextChange('memory_limit', text.target.value)}
                        defaultValue={this.state.changedConfig.memory_limit}
                        helperText=""
                    />

                    <TextField
                        style={{marginRight: '25px'}}
                        id="standard-error-helper-text"
                        label="GPUs Utilization"
                        disabled
                        aria-disabled
                        defaultValue="coming soon"
                    />

                    </CardBody>
                </Card>

                <br></br>
                <br></br>

                <Row style={{margin: '0 auto'}}>
                    <Button onClick={() => this.saveDeployConfig(this.state.model.name)} className="settings-save-btn"> Save </Button> 
                    <Button onClick={() => this.setState({ changedConfig : JSON.parse(JSON.stringify(this.state.deploymentConfig)) })} className="settings-cancel-btn"> Reset </Button> 
                </Row>
            </div>
        )
    }
}