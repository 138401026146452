import React from "react";
import { settingsTabNames } from "../settings/utils/SettingsTab";
import { Row, Col } from 'reactstrap'
import FadeIn from "react-fade-in";
import { getSettingsTab } from "../settings/utils/SettingsTab";


class SettingsPage extends React.Component {
    constructor(props) {
        super(props);
        this.userToken = props["userToken"]
        this.state={
            project: props["project"],
            organization: props["organization"],
            modelData: props["modelData"],
            isLoading: false,
            pageName: "set thresholds",
            pageComponent: [],
        }
    }

    getPageComponent = async(pageName) => {
        this.setState({ pageName : pageName })
        this.setState({ pageComponent : getSettingsTab(pageName, this.userToken, this.state.organization, this.state.project) })
    }

    componentDidMount() {
        this.getPageComponent("set thresholds")
    }

    componentDidUpdate() {
        if (this.state.organization != this.props["organization"]) {
            this.setState({ organization : this.props["organization"] })
        }
        if (this.state.project != this.props["project"]) {
            this.setState({ project : this.props["project"] })
        }
    }

    render() {
        if (this.state.isLoading) {
            return "Loading Settings";
        }

        return (
            <div>
            <div style={{borderRadius: 20, padding: 40, paddingTop: 0}} className="settingsOverlay">
            <div>
                <FadeIn>
                <Row>
                    <Col sm={2}>
                    <div className="orgMenuBlock" style={{paddingTop: 47}}>
                        {
                            settingsTabNames.map((subtab, j, i) => {
                            return Object.keys(subtab).map((tabName, i) => {
                                return (
                                    <div> 
                                        <p className="titleMenu" style={{marginBottom: 25}}> {tabName.toUpperCase()} </p>
                                        {
                                            subtab[tabName].map(tab => {
                                                var setURLtab = tab.toLowerCase()
                                                if (tab.toLowerCase() == this.state.pageName) {
                                                    return <p className="highlightTabName" onClick={() => this.getPageComponent(setURLtab)}> {tab} </p>
                                                }
                                                return <p className="tabName" onClick={() => this.getPageComponent(setURLtab)}> {tab} </p>
                                            })
                                        }
                                        <p style={{marginBottom: 29}}></p>
                                        </div>
                                    )
                                })
                            })
                        }
                    </div>
                </Col>
            <Col className="subtabBody">
                    {this.state.pageComponent}
                    </Col>
                </Row>
                </FadeIn>
                    </div>
                </div>
            </div>
        )
    }
}

export default SettingsPage;