import React from 'react';
import {  Row, Col, Button } from 'reactstrap';
import 'chartjs-plugin-style';
import HomeSideBar from '../dashboardapp/components/layout/HomeSideBar';
import Overview from '../dashboardapp/components/dashboard/Overview';
import { dashboardTabs, dashboardTabNames } from './utils/dashboardTabs.js';
import { formatPageName, reformatPageTabs } from './utils/dashboardTabs.js';
import { ToastContainer, toast } from 'react-toastify';
import SdkSetup from '../dashboardapp/components/onboarding/SdkSetup';
import LoadingIcon from '../dashboardapp/components/dashboard/PredictionLogs';
import { DashboardHeader } from '../dashboardapp/components/layout/DashboardHeader';
import { DatePicker } from "../dashboardapp/components/dashboard/DatePicker";
import FadeIn from 'react-fade-in';
import { UncontrolledDropdown, UncontrolledButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { BlackBanner } from '../../../../components/BlackBanner';

const date = new Date()
date.setHours(0,0,0,0);
var end = new Date();
end.setHours(23,59,59,999);

class MLProdDashboard extends React.Component {
    constructor(props){
        super(props);
        this.newProject=props["location"]["state"]
        this.userToken = "";
        this.userInfo = [];
        this.state={
            page: "Overview",
            pageComponent: <Overview />,
            allModels: [],
            loading: true,
            typeMetric: '_weighted',
            dates: {"startDate": date.toISOString(), "endDate": end.toISOString()},
            miniLoading: false,
            organization: [],
            featureCounts: [],
            modelId: "",
            components: [],
            sdkPopup: false,
            pageExists: false,
            selectModel: [],
            metrics: {"metrics": {}},
        }
    }

    getAllModels = async(userToken, orgId) => {
        return this.setState({ allModels : [] })
    }

    changeDates = (startDate, endDate) => {
        var dates = this.state.dates
        dates["startDate"] = startDate
        dates["endDate"] = endDate
        this.setState({ dates : dates })

    }

    getModel = async(userToken, orgId, modelId) => {
        this.setState({ miniLoading : true })
        this.setState({ sdkPopup : false })
        let allModels = {}
        this.modelExists(allModels).then(() => {
            this.getMetrics(userToken, orgId, modelId).then(async() => {
                this.setState({ components  : [] })
                this.setPage(this.props.match.params.page, this.state.organization["id"], modelId);
                this.setState({ modelId : modelId })
                this.getAllModels(this.userToken, orgId)
            })
        })
    }

    getMetrics = async(userToken, orgId, modelId) => {
        const date = new Date()
        date.setHours(0,0,0,0);
        var end = new Date();
        end.setHours(23,59,59,999);
        this.filterMetricDate(userToken, modelId, date.toISOString(), end.toISOString())
    }

    modelExists = async(model) => {
        if (model == undefined) {
            this.setState({ pageExists : true });
            this.setState({ loading : false })
        }
        return this.setState({selectModel : model });
    }

     filterMetricDate = async(userToken, modelId, startDate, endDate) => {
        const featureCounts = {}
        this.setState({ featureCounts : featureCounts})

        var metrics = {"data": { "averages": [], "metrics": [] }}

        this.setState({ metrics: metrics })
        this.setPage(this.props.match.params.page, this.state.organization["id"], this.state.modelId)
        this.setState({ loading : false })
        this.setState({ miniLoading : false })
             
     }

     switchMetricType = async(type) => {
         this.setState({ typeMetric : type })
     }

    setPage = (page) => {
        console.log(page)
        this.setState({ page : page })
        return this.setState({ pageComponent : dashboardTabs(page, this.getComponents, this.state.components, this.state.typeMetric, this.state.organization, this.userToken, this.state.selectModel, this.state.metrics, this.state.featureCounts)});
    }

    getOrganization = async(userToken, orgId) => {
        this.setState({ organization : [] })
    }

    getUserData = async() => {}

    componentDidMount() {
        console.log("ok")
        this.getUserData().then(() => {
            this.getOrganization(this.userToken, this.props.match.params.orgId).then(async(org) => {
                if (org != null) {
                    this.getModel(this.userToken, this.props.match.params.orgId, this.props.match.params.id).then(() => {
                        if (this.newProject == "newProject") {
                            this.setState({ sdkPopup : true })
                        }
                    })
                } else {
                    this.setState({ loading : false })
                    this.setState({ pageExist : true })
                }
            })
        })
    }

    render() {

        console.log(this.state.metrics)


        return (
            <div className="dashboardBackground">

                <BlackBanner content={"This platform was built for StackStr and has since been archived "} />
           
                <HomeSideBar organization={this.state.organization} />

                <DashboardHeader 
                    userInfo={this.userInfo}
                    models={this.state.allModels} 
                    organization={this.state.organization}
                    userToken={this.userToken}
                    chooseModel={this.getModel}
                    currentModel={this.state.selectModel["project"]}
                />

                <div className="dashboardBody">
                    <br></br>
                    {
                    this.state.miniLoading ? 
                    <div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <LoadingIcon />
                     </div>
                     :
                    this.state.sdkPopup ?
                        <SdkSetup orgId={this.state.organization["id"]} projectId={this.state.modelId} /> 
                         :
                        <div>
                            <div style={{position: 'relative', paddingTop: '20px', paddingBottom: '20px', zIndex: 99}}>
                            <h1 className="dashboardTitle"> Dashboard 
                            <UncontrolledButtonDropdown style={{marginLeft: 17, float: 'right'}}>
                                <DropdownToggle className="dropdownMetricTypeBtn float-right" style={{background: "#321fdb", color: "#FFF", borderRadius: '4px'}}> {this.state.typeMetric.substring(2, 1).toUpperCase()}{this.state.typeMetric.substring(2, this.state.metrics.length)} </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => this.switchMetricType("_macro").then(() => this.filterMetricDate(this.userToken, this.state.modelId, this.state.dates["startDate"], this.state.dates["endDate"]) )}> Macro </DropdownItem>
                                    <DropdownItem onClick={() => this.switchMetricType("_weighted").then(() => this.filterMetricDate(this.userToken, this.state.modelId, this.state.dates["startDate"], this.state.dates["endDate"]) )}> Weighted </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                            <p className="metricDateTitle" style={{marginTop: 15, marginLeft: 20}}> METRIC TYPE </p>
                                    <DatePicker changeDates={this.changeDates} modelId={this.state.modelId} filterMetricDate={this.filterMetricDate} userToken={this.userToken} />   
                               </h1>

                                </div>
                            <Row style={{marginLeft: 7, marginBottom: 24, paddingBottom: '10px'}}>
                                {
                                    dashboardTabNames.map(page => {
                                        if (this.state.page == page) {
                                            return (
                                                <a className="dashboardLinksActive" onClick={(id) => this.setPage(page)}> {reformatPageTabs(page).toUpperCase()} </a>
                                            )
                                        } else {
                                            return (
                                                <a className="dashboardLinks" onClick={(id) => this.setPage(page)}> {reformatPageTabs(page).toUpperCase()} </a>
                                            )
                                        }
                                    })
                                }
                            </Row>
                        {this.state.pageComponent}
                    </div>
                        }
                    </div>          
                <ToastContainer />
            </div>
        )
    }
}
export default MLProdDashboard;