
import React, { useEffect, useState } from 'react';
import { Bar, HorizontalBar, Line } from 'react-chartjs-2';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import FeatureCards from '../cards/FeatureCards';
import ProgressCard from '../cards/ProgressCard';
import { getStackLineChart, getStackLineChartPlain, stackLineChartOptions } from './Chart';
import FadeIn from 'react-fade-in';
import { getStackBarChart, stackBarChartOptions } from './BarChart';
import { UncontrolledButtonDropdown,Button, ButtonGroup, CardBody, DropdownToggle, DropdownMenu, DropdownItem, Table, Input } from 'reactstrap';
import { Card, Tooltip, Zoom } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import  ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownCircle';

var saveState = {0 : {"name": "Pick Feature", "data": {}}}
var saveIdx = 0

const Overview = ({
    typeMetric,
    featureCounts,
    organization,
    components,
    getComponents,
    project,
    metrics,
    ...restProps
}) => {

    return (
        <div>
            <FadeIn>
        <div className="dashboardOverviewBody">
            <h1 className="dashboardHeading"> OVERVIEW  </h1>
        
            <Row>
                <Col id={"card"} sm={3} style={{marginBottom: 10}}>
                    <FadeIn>
                        <ProgressCard
                            style={'visible'}
                            title={"ACCURACY RATE"}
                            color={'blue'}
                            value={0.6318}
                            thresholdValue={0.46}
                            />
                    </FadeIn>
                </Col>
                <Col id={"card"} sm={3} style={{marginBottom: 10}}>
                    <FadeIn>
                        <ProgressCard
                            style={'visible'}
                            title={"PRECISION"}
                            color={'purple'}
                            value={0.7069}
                            thresholdValue={0.34}
                            />
                    </FadeIn>
                </Col>
                <Col id={"card"} sm={3} style={{marginBottom: 10}}>
                    <FadeIn>
                        <ProgressCard
                            style={'visible'}
                            title={"RECALL"}
                            color={'blue'}
                            value={0.6518}
                            thresholdValue={0.12}
                            />
                    </FadeIn>
                </Col>
                <Col id={"card"} sm={3} style={{marginBottom: 10}}>
                    <FadeIn>
                        <ProgressCard
                            style={'visible'}
                            title={"F1_SCORE"}
                            color={'purple'}
                            value={0.6710}
                            thresholdValue={0.234}
                            />
                    </FadeIn>
                </Col>
            </Row>

            </div>
            <br></br>
            <Row>
            <Col style={{ marginBottom: 15, cursor: 'pointer' }}>
                <Card className="ml-production-featureCard">
                <CardBody style={{padding: '40px'}} className="featureCardPadding">
                    <p className="dashboardSubHeading"> METRICS
                    </p>
                        <div>
                        <Line
                            options={stackLineChartOptions}
                            data={getStackLineChartPlain({
                                typeMetric: typeMetric,
                                metrics: "",
                                labels: [1, 2, 3, 4, 5, 6, 7, 8, 8, 10, 11, 12],
                                data: [5, 10, 3, 4, 5, 10, 7, 10, 5, 3, 2, 4],
                            })}
                        />
                        </div>

                    </CardBody>
                    </Card>
                </Col>
                </Row>

                <br></br>
                <p className="dashboardHeading" style={{fontSize: 16, paddingTop: '30px', paddingBottom: '20px'}}> FEATURE DISTRIBUTIONS  </p>
                <Row> 
                <Col style={{ marginBottom: 15, cursor: 'pointer' }} sm={5}>
                    <FadeIn>

                        <FeatureCards
                            title={""}
                            content={
                                <div>
                                <Bar
                                    options={stackBarChartOptions}
                                    data={getStackBarChart({
                                        labels: [1, 2, 3, 4, 5, 6, 7, 8, 8, 10, 11, 12],
                                        data: [5, 10, 3, 4, 5, 10, 7, 10, 5, 3, 2, 4],
                                    })}
                                />
                                </div>
                            }
                        />
                        </FadeIn>
                    </Col>
                    <Col style={{ marginBottom: 15, cursor: 'pointer' }} sm={5}>
                        <FeatureCards
                            title={""}
                            content={
                                <Bar
                                    options={stackBarChartOptions}
                                    data={getStackBarChart({
                                        labels: [1, 2, 3, 4, 5, 6, 7, 8, 8, 10, 11, 12],
                                        data: [5, 10, 3, 4, 5, 10, 7, 10, 5, 3, 2, 4],
                                    })}
                                />
                            }
                        />
                    </Col>
                    <Col style={{ marginBottom: 15, cursor: 'pointer' }} sm={5}>
                        <FeatureCards
                            title={""}
                            content={
                                <Bar
                                    options={stackBarChartOptions}
                                    data={getStackBarChart({
                                        labels: [1, 2, 3, 4, 5, 6, 7, 8, 8, 10, 11, 12],
                                        data: [5, 10, 3, 4, 5, 10, 7, 10, 5, 3, 2, 4],
                                    })}
                                />
                            }
                        />
                    </Col>
                </Row>
                    {/* {
                        featureCounts ? 
                        <div>
                            <Row> 
                        {
                            Object.keys(featureCountCopies).map(key => {
                                if (featureCountCopies[key] != undefined) {
                                    if (featureCountCopies[key]["name"] != "NA") {
                                        return (
                                            <Col style={{ marginBottom: 15, cursor: 'pointer' }} sm={5}>
                                            <FadeIn>
                                            <FeatureCards 
                                            style={{overflow: 'visible'}}
                                            title={""}
                                            content={
                                                <div>
                                                <div>
                                                    <UncontrolledButtonDropdown className="float-right" setActiveFromChild style={{color: 'white', marginTop: -6}}>
                                                        <DropdownToggle tag="a" className="nav-link" >  <p style={{width: '200px'}} className="dashboardHeaderText" style={{cursor: 'pointer', float: 'left'}}> FEATURE <span style={{fontWeight: 600, marginLeft: 6}}> 
                                                    {featureCountCopies[key]["name"]} 
                                                                <span style={{marginTop: -3, marginLeft: 7}}> 
                                                                            <img src={require('../../../../../../assets/platforms/ml-production/assets/WhiteCheckmark.svg')} /> </span> </span></p>
                                                        </DropdownToggle>
                                                        <DropdownMenu style={{height: '100px', overflowY: 'scroll'}} className="organizationDropdownMenu">
                                                            {
                                                                    Object.keys(featureCounts).map(feature => {
                                                                        return (
                                                                            <DropdownItem onClick={() => { getFeatureDistributionDropdown(key, feature).then((copies) => addFeatureDistribution("NA"))}} style={{marginBottom: -10}} className="organizationDropdownText"> {feature} </DropdownItem>
                                                                        )
                                                                    })
                                                                }                           
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                    </div>
                                                <Bar
                                                options={stackBarChartOptions}
                                                data={getStackBarChart({
                                                    labels: Object.keys(featureCountCopies[key]["data"]),
                                                    data: Object.values(featureCountCopies[key]["data"])
                                                })}
                                                />
                                                </div>                                                
                                            }
                                        />
                                            </FadeIn>
                                            </Col>
                                        )
                                    }
                                }
                              })
                            }</Row>
                              </div>
                            : null} */}

        </FadeIn>
    </div>
    )
}

export default Overview;