import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";

const organizeVersions = (versions) => {
    const all_versions = []

    versions.map((version, idx) => {
        all_versions.push({
            key: idx,
            text: version,
            value: version
        })
    })

    return all_versions.reverse()
};

export const VersionDropdown = ({
    deployedVersion,
    version,
    getModel,
    switchVersion,
    getMetrics,
    model,
    ...restProps
}) => {
    const [versions, getVersions] = useState(organizeVersions(["v0.0.1", "v0.0.2", "v0.0.3", "v0.0.4"]))
    const [filteredVersion, getfilteredVersion] = useState("v.0.0.1")
    const [loading, isLoading] = useState(false)

    const selectVersion = async(e, data) => {
        console.log(e, data)
        isLoading(false)
        getfilteredVersion(data.value)
    };

    console.log(version)

    return (
        <div>
            <Dropdown 
                placeholder="Select a version"
                className="endpoint-performance-banner endpoint-performance-banner-text" 
                text={filteredVersion} 
                options={versions} 
                onChange={selectVersion}
                loading={loading}
                scrolling
                closeOnEscape
                style={{color: 'white'}}
                search
            />
        </div>
    )
}