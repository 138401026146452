import React from "react";
import { Button, UncontrolledButtonDropdown, Col, Row, Dropdown, DropdownItem, DropdownToggle, Card, CardBody, DropdownMenu } from 'reactstrap';
import { KeyReveal } from "../globalcomponents/KeyReveal";
import { UserBanner } from "../globalcomponents/UserBanner";
import ExpSearchBox from "../input/ExpSearchBox";

export const DashboardHeader = ({updateExp, goToAllExp, experiments, ...restProps}) => {
    return (
        <div className="projectsHeader">
            <img  className="voyagersLogo" src={require('../../../../../../assets/platforms/ml-experimentation/assets/voyagersLogo.svg')} />
            <p  className="voyagersTitle">  <img style={{cursor: 'pointer'}} src={require('../../../../../../assets/platforms/ml-experimentation/assets/voyagerTxtLogo.svg')} /> </p>
            <div style={{paddingLeft: 180, marginTop: 3}} className="headerPadding">
            <Row>
            <p onClick={goToAllExp} style={{marginLeft: 29, marginTop: 20}} className="headerLinks"> Home </p>
            <p className="headerLinks" style={{marginTop: 20}}>Settings</p>

            <ExpSearchBox
                updateExp={updateExp}
                data={experiments} />
             
            </Row>

            <KeyReveal />

            <div className="lighterGrayBlockHeader">
                <UserBanner />
            </div>

            
        </div> 
    </div>
    )
}