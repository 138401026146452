import React from "react";
import moment from "moment"

export const DashboardUtils = {

    getTimes: (type, amt) => {
        var timeStamps = {
            "amt": amt,
            "type": type,
            "start_time": "",
            "end_time": ""
        }
        var date = moment(new Date()).subtract(amt, type).add("1000", "milliseconds")
        timeStamps['start_time'] = new Date(date.toString()).toISOString()
        timeStamps['end_time'] = new Date().toISOString()
        return timeStamps;
    },

    getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },

    addUrlParam(name, value) {
        let here = new URL(window.location.href);
        console.log(here, name, value)
        here.searchParams.append(name, value);
    },

    getTimeTabs() {
        return [
            {
                "name": "Last hour",
                "time": {
                    "amt": 1,
                    "type": "hours"
                }
            }, 
            {
                "name": "Last 24 hours",
                "time": {
                    "amt": 24,
                    "type": "hours"
                }
            },
            {
                "name": "Last 7 days",
                "time": {
                    "amt": 7,
                    "type": "days"
                }
            },
            {
                "name": "Last 30 days",
                "time": {
                    "amt": 30,
                    "type": "days"
                }
            }
        ]
    }

}