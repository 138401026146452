import { locale } from 'moment';
import React, { useEffect } from 'react'
import { toast } from 'react-toastify';
import { Row } from 'reactstrap'
import { Button, Header, Icon, Modal, Dropdown } from 'semantic-ui-react'

export const RollbackConfirm = ({
    version,
    model,
    switchVersion,
    refreshData,
    ...restProps}) => {
  const [open, setOpen] = React.useState(false)
  const [selectVersion, chooseVersion] = React.useState("v0.0.1")

  const rollbackModel = async(version) => {
    setOpen(false)
  }

  return (
    <span style={{marginRight: '15px', cursor: 'pointer'}}>

        <Icon style={{color: 'rgba(255, 255, 255, 0.52)', marginRight: '5px'}} name="undo alternate" />
            <Dropdown text="Rollback">
                <Dropdown.Menu> 
                    {
                        ["v0.0.1", "v0.0.2", "v0.0.3", "v0.0.4"].map((version_all) => {
                            return <Dropdown.Item onClick={() => { chooseVersion(version_all); setOpen(true) }}> {version_all} </Dropdown.Item>
                        })
                    }
                </Dropdown.Menu>


            </Dropdown>

      <Modal
          basic
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          size='small'>
              <br></br>
              <br></br>
              <br></br>
              <br></br>

          <Header icon>
            <Icon name="undo alternate" />
            Rollback Model
          </Header>
          <Modal.Content>
          <p style={{textAlign: 'center'}}>
            Looks like you're trying to roll your model back to version: {selectVersion}. Are you positive you want to do this? 
          </p>
        </Modal.Content>
        <Modal.Actions>
            <Row style={{justifyContent: 'center'}}>
            <Button basic color='white' inverted onClick={() => setOpen(false)}>
                <Icon name="cancel"  /> No
            </Button>
            <Button style={{marginLeft: '20px'}} onClick={() => rollbackModel(selectVersion)} inverted color='red'>
                <Icon name='trash' /> Yes
            </Button>
          </Row>
        </Modal.Actions>
    </Modal>
    </span>
  )
}
