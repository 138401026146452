import { Card } from '@material-ui/core';
import React from 'react'
import { Line } from 'react-chartjs-2';
import { CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';

const FeatureCards = ({title, style, content,...props}) => {
    return (
        <div>
            <Card style={style} className="ml-production-featureCard">
                <CardBody className="featureCardPadding">
                    <p className="dashboardSubHeading"> {title}
                    </p>
                    {content}
                </CardBody>
            </Card>
        </div>
    )
}

export default FeatureCards;
