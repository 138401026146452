import React, { version } from "react";
import { Header } from "../components/Header";
import { BigBanner } from "../components/BigBanner";
import { TabManager } from "../utils/TabManager";
import { CircularProgress } from "@material-ui/core";
import FadeIn from "react-fade-in";
import { ToastContainer } from "react-toastify";
import '../../styles/homePage.scss'
import { BlackBanner } from "../../../../../components/BlackBanner";

class  MLDeploymentDashboard extends React.Component {
    constructor() {
        super()
        this.modelName = ""
        this.model = []
        this.state={
            versions: {
                "filtered_version": "",
                "deployed_version": "",
            },
            metrics: [],
            currTab: {
                "name": "Overview",
                "route": "overview"
            },
            requestInfo: {
                "current_token": "",
                "next_page_token": "",
                "request_info": []
            },
            currTimeTab: "Last hour",
            timeStamp: {
                "amt": "",
                "type": "",
                "start_time": "",
                "end_time": ""
            },
            pageComponent: [],
        }
    }

    switchTabs = (tabName, route) => {
        this.setState({ currTab : { 'name': tabName, 'route': route } })
        this.setState({ pageComponent : TabManager(tabName, this.model, this.state.versions, this.state.metrics, this.state.requestInfo, {}) })
    };


    componentDidMount() {
        this.switchTabs("Overview", "overview")
    }

    render() {
        if (this.state.pageNotFound) {
            return "Page not found"
        }

        return (
            <div>
                <BlackBanner content={"Dashboard may not have functionality and is a display of the actual platform"} />
                <Header />

                    <BigBanner switchTabs={this.switchTabs} />

                        <div style={{paddingTop: 120, paddingLeft: 160, paddingBottom: '80px', paddingRight: 100}}>
                            <FadeIn>
                                {this.state.pageComponent}
                        </FadeIn>
                    </div>


                    <ToastContainer />
                 
            </div>
        )
    }
}

export default MLDeploymentDashboard;