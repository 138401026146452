import React from "react";
import { Button, UncontrolledButtonDropdown, Col, Row, Dropdown, DropdownItem, DropdownToggle, Card, CardBody, DropdownMenu } from 'reactstrap';
import ExpSearchBox from "./ExpSearchBox";
import { KeyReveal } from "./KeyReveal";
import { UserBanner } from "./UserBanner";

export const DashboardHeader = ({updateExp, goToAllExp, experiments, ...restProps}) => {
    return (
        <div className="projectsHeader">
            <img  className="voyagersLogo" src={require('../../../../../assets/landing-pages/ml-experimentation/assets/logo/voyagersLogo.svg')} />
            <p  className="voyagersTitle">  <img style={{cursor: 'pointer'}} src={require('../../../../../assets/landing-pages/ml-experimentation/assets/logo/voyagerTxtLogo.svg')} /> </p>
            <div style={{paddingLeft: 180, marginTop: 3}} className="headerPadding">
            <Row>
            <p onClick={goToAllExp} style={{marginLeft: 29, marginTop: 20}} className="headerLinks"> Home </p>

            <ExpSearchBox
                updateExp={updateExp}
                data={experiments} />
             
            </Row>

            <KeyReveal />

            <div className="lighterGrayBlockHeader">
                <UserBanner />
            </div>

            
        </div> 
    </div>
    )
}