import React, { useState } from "react";
import { Dropdown, Button, Icon, Input, Label, Popup } from "semantic-ui-react";

export const DatePopup = ({model, version, switchVersion, getMetrics, setTimestamp, ...restProps}) => {
    const [amt, getAmt] = useState(0);
    const [type, setType] = useState('hours');
    const [opened, setOpen] = useState(false);

    const setTimestampp = async() => {
        setTimestamp("", type, amt)
    };

    const onSubmit = () => {
        setOpen(false)
    }

    console.log(version)

    return (
        <div>
            <Popup
                closeOnEscape
                trigger={<Icon style={{marginRight: 30, cursor: 'pointer', marginTop: 3, marginLeft: '20px', color: 'white'}} name="ellipsis horizontal" />}
                content={<div>
                    <div className="date-popup-overlay"></div>
                    <Label color="black" className="text-center"> Custom Time </Label>
                    <br></br>
                    <row>
                    <br></br>
                    <p style={{marginBottom: 0}}> Get data for the last number of hours or days  </p>
                    <br></br>
                    <Dropdown style={{background: '#80808024', padding: 10, borderRadius: 8, fontWeight: 600}} closeOnEscape fluid text={type}>
                        <Dropdown.Menu style={{width: '100%'}} onClick={(id) => setType(id.target.id)}>
                            <Dropdown.Item id="hours"> Hours </Dropdown.Item>
                            <Dropdown.Item id="days"> Day </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <br></br>
                    <Input style={{color: 'black'}}  min="0" label="Amount" onChange={(text) => getAmt(text.target.value)} type="number" placeholder="Type in amount"/>

                
                </row> 
                <br></br>
                <br></br>
                <Button style={{background: 'black', color: 'white', fontFamily: 'inter'}} className="float-right " onClick={() => setOpen(false)}> Save </Button>
                </div>}
                on='click'
                onOpen={() => setOpen(true)}
                // open={opened}
            />
        </div>
    )
}