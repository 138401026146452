import React, { Component } from "react";
import { Icon, Popup, Reveal } from "semantic-ui-react";
import { Button } from 'reactstrap'
import FadeIn from "react-fade-in";
import copy from 'copy-to-clipboard';
import { CircularProgress, LinearProgress } from "@material-ui/core";


export class KeyReveal extends Component {
    state = {
        key: "aI3SB-32Ba2-aJ23I-231DA",
        opened: false,
        loading: true,
        copied: false
    }

    getKey = () => {
        setTimeout(() => {
            this.setState({ loading : false })
        }, 800)
    };

    createKey = async() => {};

    updateKey = async() => {};
    
    copyCodeToClipboard = (key) => {
        copy(key)
        this.setState({ copied : true })
      }

    openReveal = () => {
        this.setState({ loading : true })
        this.setState({ opened : true })
        this.getKey();
    };

    render() {
        return (
            <Reveal className="ml-deployment ml-deployment-key-reveal-dash" style={{position: 'absolute', right: 290, borderRadius: 100, top: 19}} animated={this.state.opened? 'move up': null}>
            <Reveal.Content className="ml-deployment ml-deployment-key-reveal-dash" style={{border: '1px solid #B9B9B9', borderRadius: 100}} onClick={this.openReveal} visible>
                <Button style={{width: '250px', borderRadius: 100}} id="API_key" className="APIKeyBtn">  Load API key <Icon style={{marginLeft: 3}} name="key" /> </Button>
            </Reveal.Content>
            <Reveal.Content className="ml-deployment-key-reveal-dash" style={{width: 250, borderRadius: 100, border: '1px solid white'}} hidden>
            <FadeIn>

                <p className={this.state.copied? "keyRevealed": "keyReveal"}  style={{borderRadius: 3, height: 32, padding: 6, textAlign: 'center',borderRadius: 100, width: '250px', fontSize: 13}}> 

                { 
                    this.state.loading ?

                    <p style={{marginTop: '-3px'}}> <span> Getting key </span> <CircularProgress style={{marginLeft: 5, marginTop: '5px', opacity: .6}} size={13} color="secondary" />
                    </p>
                    :
                    <FadeIn>
                        <p>
                        {this.state.key}
                        <Popup 
                        style={{marginLeft: '3px'}}
                        content='Copied Key!' 
                        on={this.state.copied} 
                        trigger={this.state.copied? <Icon style={{color: 'green'}} name="check" /> : <Icon style={{cursor: 'pointer'}} onClick={() => this.copyCodeToClipboard(this.state.key)} name="clipboard" />} 
                        />
                    </p>
                </FadeIn>
                }


                </p>

                </FadeIn>
            </Reveal.Content>
        </Reveal>
        )
    }
}