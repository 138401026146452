import React, { Component } from "react";
import { Scatter } from "react-chartjs-2";
import { Row, Col } from 'reactstrap';
import { stackBarChartOptions, timeStampOptions } from "../../graphs/BarGraph";
import { ExperimentUtils } from "../../../utils/ExperimentUtils";
import moment from 'moment';
import { Dropdown, Icon } from "semantic-ui-react";

export class OverviewMetrics extends Component {
    state={
        metrics: this.props.metrics,
        expColors: this.props.expColors,
        xAxis: "epoch"
    }

    inputRefs = {};

    setRef = (ref, expId, type) => {
        const refData = this.inputRefs
        if (!refData[expId]) {
            refData[expId] = {}
        }
        refData[expId][type] = ref
        this.inputRefs = refData;
    };
  
    resetZoom = (metric, type) => { 
        setTimeout(() => {
            this.inputRefs[metric][type].chartInstance.resetZoom();
        }, 200);
    };

    selectXAxis = (name) => {
        this.setState({ xAxis : name })
        this.inputRefs = this.inputRefs
        setTimeout(() => {
            Object.keys(this.state.metrics).map((expId) => {
                Object.keys(this.state.metrics[expId]["metrics"]).map((metric) => {
                    this.resetZoom(metric, name)
                });
            });
        }, 1000);
    };
    
    render() {

        const experimentData = {}

        {
            Object.keys(this.state.metrics).map((expId, idx) => {

                Object.keys(this.state.metrics[expId]["metrics"]).map((metrics, i) => {
                    if (experimentData[metrics] == undefined) {
                        experimentData[metrics] = []
                    }

                    experimentData[metrics][idx] = {
                        "label": expId.substring(0, 8), 
                        showLine: true,  
                        borderWidth: .8,
                        fill: true, 
                        borderColor: this.state.expColors[expId]["color"],
                        data: []
                    }

                    Object.keys(this.state.metrics[expId]["metrics"][metrics]).map((val) => {
                        if (this.state.metrics[expId]["metrics"][metrics][val][this.state.xAxis] || this.state.metrics[expId]["metrics"][metrics][val]["value"]) {
                            var x =  this.state.metrics[expId]["metrics"][metrics][val][this.state.xAxis]
                            var y =  this.state.metrics[expId]["metrics"][metrics][val]["value"]
                            if (this.state.xAxis == 'timestamp') {
                                x =  moment(this.state.metrics[expId]["metrics"][metrics][val][this.state.xAxis])
                            }
                            experimentData[metrics][idx]["data"].push({
                                x: x,
                                y: y
                            })   
                        }     
                             
                     })
                })
            })
        }

        Object.keys(experimentData).map(metric => {
            Object.keys(experimentData[metric]).map(() => {
                experimentData[metric] = experimentData[metric].flat()
            })
        })

        const graphs = []

        {
            Object.keys(experimentData).map((metric, idx) => {
                const data = { datasets:  experimentData[metric] };
                return (
                    graphs.push(
                    <Col sm={5} md={6}  style={{borderRadius: '30px', boxShadow: '26px 45px 43px #071224', zIndex: 999, marginBottom: 20, border: '1px solid rgba(255, 255, 255, 0.06)', padding: 30}}>
                        <p style={{color: 'white'}}> {metric} <span style={{cursor: 'pointer'}} className="float-right" onClick={() => this.resetZoom(metric, this.state.xAxis)}>Reset Zoom  <Icon name="zoom-out" /> </span> </p>
                        <Scatter
                            ref={(reference) => this.setRef(reference, metric, this.state.xAxis)} 
                            data={data}
                            options={this.state.xAxis == "timestamp"?
                            timeStampOptions(this.state.xAxis, metric.substring(0, 1).toLocaleUpperCase() + metric.substring(1, metric.length))
                            :  stackBarChartOptions(this.state.xAxis, metric.substring(0, 1).toLocaleUpperCase() + metric.substring(1, metric.length))}
                        />
                    </Col>
                    )
                )
            })
        }

        return (
            <div>
                <Dropdown style={{color: 'white', zIndex: 9999, fontSize:14, marginLeft: '-13px'}} text={"Choose: " + this.state.xAxis}>
                    <Dropdown.Menu style={{width: '100%'}}>
                        {
                            ExperimentUtils.getXAxisVar().map((variable) => {
                                return (
                                    <Dropdown.Item onClick={() => this.selectXAxis(variable)} text={variable}/>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
                <br></br>
                <br></br>
                <Row>
                    {graphs}
                </Row>
            </div>
        )
    }
}