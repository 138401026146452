import React from "react"
import { Button } from "reactstrap"
import { Icon } from "semantic-ui-react"

export const BlackBanner = ({
    content,
}) => {
    return (
        <div className="black_banner_menu">
            <p style={{color: 'white', fontSize: '14px', lineHeight: '20px'}} className="vertically-align"> <span style={{color: 'white'}}> {content} </span>
            <span className="back_to_portfolio_hyperlink" onClick={() => window.location.href = '/'}> Back to Portfolio </span>
            </p>
        </div>
    )
}