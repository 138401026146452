import React from "react";
import { Row } from 'reactstrap';
import { BlackBanner } from "../../../../../components/BlackBanner";
import { DashboardBanner } from "../components/layout/DashboardBanner";
import { DashboardHeader } from "../components/layout/DashboardHeader";
import { ExperimentsMenu } from "../components/layout/ExperimentsMenu";
import { OverviewMetrics } from "../components/subtabs/allExpPage/OverviewMetrics";
import { ExperimentUtils } from "../utils/ExperimentUtils";


class ExperimentPage extends React.Component {
    constructor() {
        super();
        this.teamId = ""
        this.projectName = ""
        this.state={
            expId: "6096fe3b029464f12b17c253",
            pageComponent: [],
            view: "all_exp",
        }
    }


    updateTabData = (currentTab) => {
        this.setState({ currentTab : currentTab });
        this.setState({ pageComponent : ExperimentUtils.TabManager(currentTab, this.state.expId) });
    };

    goToAllExp = () => {
        this.setState({ view : "all_exp" })
    };

    switchExpData = (expID) => {
        this.setState({ expId : expID })
        this.setState({ view : "single_exp" })
        this.updateTabData("metrics")
        // this.setState({ pageComponent : ExperimentUtils.TabManager(this.state.currentTab, expID) });
    };

    componentDidMount() {
        this.updateTabData("all_exp");
    };


    render() {
        console.log(this.state.view)

        const expColors = {}
        const metrics = ExperimentUtils.getAllExpData()["metrics"];

        {
            Object.keys(metrics).map((expId, idx) => {
                const chooseGrad = idx % ExperimentUtils.getExpGrads().length
                expColors[expId] = {
                    "color": ExperimentUtils.getExpGrads()[chooseGrad]["color"]
                }
            })
        }  

        return (
        <div className="dashboardBg">

            <BlackBanner content={"Dashboard may not have functionality and is a display of the actual platform"} />
                {
                    this.state.view == "all_exp" ?
                    <div onClick={this.clickOnDashboard}>

                        <DashboardHeader goToAllExp={this.goToAllExp} updateExp={this.switchExpData} experiments={metrics} />
                        <ExperimentsMenu updateExp={this.switchExpData} expColors={expColors} experiments={metrics} />
                        <div id="experimentBanner" className="experimentBanner">
                            <p style={{marginTop: 24}}> <span className="experimentBannerTitle"> Dashboard</span> <span> </span></p>
                        </div>
                            <div id="dashboardBody" className="dashboardMetricsBody">
                                    <OverviewMetrics
                                        metrics={metrics}
                                        expColors={expColors}
                                    />
                            </div>

                    </div>
                    :

                    <div>

                <div>
                    <DashboardHeader  goToAllExp={this.goToAllExp}  updateExp={this.switchExpData} experiments={metrics} />
                    <ExperimentsMenu updateExp={this.switchExpData} expColors={expColors} experiments={metrics} />
                    <div id="experimentBanner" className="experimentBanner">
                        <p style={{marginTop: 24}}> <span className="experimentBannerTitle"> Dashboard</span> <span> </span></p>
                    </div>
                        <div id="dashboardBody" className="dashboardMetricsBody">
                        <Row>
                            {
                                ExperimentUtils.TabNames().map((tab) => {
                                    return (
                                        <p onClick={() => this.updateTabData(tab["route"])} style={{cursor: 'pointer'}} className="dashboardMetricsTabs"> {tab["name"]} </p>
                                    )
                                })
                            }
                        </Row>
                        {this.state.pageComponent}
                    </div>
                                </div>
                                </div>
                    }

        </div>
        )
    }
}

export default ExperimentPage;