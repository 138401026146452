import ChartJS from 'chart.js';
import 'chartjs-plugin-style';
export const getStackBarChart = ({ labels, animation, data, data1 }) => canvas => {
  const ctx = canvas.getContext('2d');
  let gradient = ctx.createLinearGradient(0, 0, 0, 255);
  {
  gradient.addColorStop(
    .3,
    ChartJS.helpers
      .color('#4850FF')
      .alpha(1)
      .rgbString()
  );
  gradient.addColorStop(
    .8,
    ChartJS.helpers
      .color('#8BDCFF')
      .alpha(.23)
      .rgbString()
    )
  }
  return {
    labels,
    type: 'scatter',

    data,
    datasets: [
      {
        data: data,
        label: 'Count',
        borderColor: '#8BDCFF',
        backgroundColor: gradient,
    
      },

    ],
  };
};

export const stackBarChartOptions = {
    tooplips: {

  
        shadowOffsetX: 3,
  
        shadowOffsetY: 3,
  
        shadowBlur: 10,
  
        shadowColor: 'rgba(0, 0, 0, 0.5)'
  
      },

    tooltips: {
      intersect: true,
    },
    elements: {
        point:{
            radius: 0
        }
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: true,
            color: 'rgba(168, 162, 162, 0.12)',
            lineWidth: 1
          },
          scaleLabel: {
            display: true,
            labelString: '',
            letterSpacing: '0.27em',
            marginTop: '20px',
            fontColor: '#FFF',
          },
          ticks: {
            fontColor: "#FFF", // this here
          },
        },
        
      ],
      yAxes: [{ display:true,   scaleLabel: {
        display: true,
        // labelString: 'Response Times',
        fontColor: '#FFF',
      },  gridLines: {
        display: true,
        color: 'rgba(168, 162, 162, 0.12)',
        
        lineWidth: 1
      },  ticks: {
        fontColor: "rgba(255, 255, 255, 0.76)", // this here
      },
    }],
    },
    legend: {
      display: true,
      labels: {
          fontColor: 'rgb(255, 255, 255)'
      }
  }

};

