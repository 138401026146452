import React from 'react';
import { Row, Button, Col, CardBody, CardText, Card, ListGroupItem, ListGroup, Media, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { BlackBanner } from '../../components/BlackBanner';
import "../../styles/landing-pages/data-analytics.scss"

class DataAnalytics extends React.Component {
    constructor() {
        super();
        this.state={
            openToggle: false
        }
    }

    render() {
        return (
            <div className="homebody">

                {/* <div style={{width: '100%', position: 'fixed', top: 0, zIndex: 999, height: 100, background: 'black'}}>
                    sdf 
                </div> */}
            <BlackBanner content="This platform was built for StackStr and has since been archived" />

            
                <div className="introduction">

                    <div style={{paddingLeft: 100, paddingTop: 20, paddingBottom: -20}} className="menu">
                    <a style={{color: '#FBFCFE', position: 'relative', top: '50px', paddingRight: 72, paddingTop: 70, fontSize: 24, fontWeight: 'bold' }}> <img src={require('../../assets/landing-pages/data-analytics/assets/section1/stackstrlogo.svg')} /> StackStr </a>
                    {/* <Button style={{fontWeight: 'bold', fontSize: 14, borderRadius: 40, background: 'linear-gradient(180deg, rgba(29, 32, 53, 0.5) 28.13%, rgba(0, 0, 0, 0.5) 141.67%)', marginTop: 10, height: 55, border: '3px solid #3E86F2'}} className="trystackstrdemobtn"> Request Access</Button> */}

                    </div>

                    <Row style={{alignItems: 'center', paddingRight: 30}}>
                        <Col sm={5}>
                    <div className="introductionbody">

                                <p style={{
                                    color: '#828EAE',
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    background: '#31374D',
                                    borderRadius: 4,
                                    height: 32,
                                    width: 240,
                                    paddingLeft: 10,
                                    paddingTop: 6


                                }}> Next Generation Data Platform</p>


                            <h1 style={{color: '#FBFCFE',  fontWeight: 'bold',}}> Level up your data science workflow.</h1>
                            <p
                            style={{paddingTop: 10, paddingBottom: 10, lineHeight: '140.34%'}}
                            > StackStr is an end-to-end data platform that allows you to fully utilize your data. Build AI applications, data pipelines, and more. </p>

                        <Button style={{fontWeight: 'bold', fontSize: 14, borderRadius: 40, background: 'linear-gradient(180deg, rgba(29, 32, 53, 0.5) 28.13%, rgba(0, 0, 0, 0.5) 141.67%)', marginTop: 10, height: 55, border: '3px solid #3E86F2'}} className="trystackstrdemobtn"> Request Access</Button>
                        {/* <img style={{position: 'absolute', left: 0, paddingTop: -5}} src={require('../assets/section1/cutoffblob.svg')} /> */}

                        <br></br>
                        <br></br>

                        <br></br>

                       

     
                        </div>
                        </Col>
                        <Col>
                        <img style={{width: '100%', paddingTop: 30}} src={require('../../assets/landing-pages/data-analytics/assets/section1/dataprofdashboard.svg')} />

                        </Col>
                        </Row>

                        <div className="achievements">
                        <Row style={{color: 'white', paddingLeft: '10rem', paddingBottom: '4rem', paddingRight: '10rem', textAlign: 'center', alignContent: 'center'}}>
                            <Col>
                            <p style={{color: ' #636881', fontWeight: 600, marginBottom: -5, fontSize: 55}}> 1000+ </p>
                            <p style={{color: 'rgba(139, 153, 190, 0.6)', fontSize: 17, fontWeight: "normal"}}> Datasets processes </p>

                            </Col>
                            <Col>
                            <p style={{color: ' #636881', fontWeight: 600, marginBottom: -5, fontSize: 55}}> 50% </p>
                            <p style={{color: 'rgba(139, 153, 190, 0.6)', fontSize: 17,  fontWeight: "normal"}}> Faster predictions </p>
                            </Col>
                            <Col>
                            <p style={{color: ' #636881', fontWeight: 600, marginBottom: -5, fontSize: 55}}> 20+ </p>
                            <p style={{color: 'rgba(139, 153, 190, 0.6)', fontSize: 17, fontWeight: "normal"}}> Hours saved per week </p>
                            </Col>
                            <Col>
                            <p style={{color: ' #636881', fontWeight: 600, marginBottom: -5, fontSize: 55}}> 4 </p>
                            <p style={{color: 'rgba(139, 153, 190, 0.6)', fontSize: 17, fontWeight: "normal"}}> Companies using StackStr </p>
                            </Col>
                        </Row>
                        </div>

                </div>




                <br></br>
                <br></br>

                <img className="dashboardalone" src={require('../../assets/landing-pages/data-analytics/assets/section1/dashboardalone.svg')} />

                <div className="section2">
                <div class="d-flex justify-content-center">   

                <h1 style={{color: '#FBFCFE', fontWeight: 'bold',  lineHeight: '109.43%'}}> Not just another machine learning workbench</h1>

                </div>
                <div class="d-flex justify-content-center">   

                <p style={{ lineHeight: '136%', }}> We rebuilt the data science lifecycle. StackStr does everything from cleaning your data to deploying your models.</p>
                </div>
                </div>

                <img className="keytounlockdataimg" src={require('../../assets/landing-pages/data-analytics/assets/section1/keytounlockdata.svg')} />

      



                {/* <div class="d-flex justify-content-center"> */}
                    {/* <hr style={{border: '1px solid rgba(117, 126, 156, 0.44)', width: 730}} /> */}
                {/* </div>   */}

                <div style={{marginTop: -20}} className="section3">

                    <img className="triangle" src={require('../../assets/landing-pages/data-analytics/assets/section1/triangle.svg')} />
                    <Row>
                        <div style={{width: 600}} className="float-left">
                    <p
                    style={{  color: '#3E86F2', fontWeight: 600
                    }}
                    >Getting started is easy  </p>
                    <h1
                    style={{color: '#FBFCFE',fontWeight: "bold", }}
                    >Clean, predict, and deploy with only one click</h1>
                    <p
                    > Stackstr’s handles the dirty work and speeds up your data workflow by 50%.
                        </p>
                        </div>

                    </Row>

                    </div>

                    <div style={{paddingLeft: 50, paddingRight: 50}}>

                    <img style={{width: '100%' }} src={require('../../assets/landing-pages/data-analytics/assets/section1/3simplesteps.svg')} />


                    <br></br>
                                        <br></br>


                        <Row style={{justifyContent: 'space-around'}}>

                            <Col sm={2}>

                                <img className="steps" src={require('../../assets/landing-pages/data-analytics/assets/section1/step1.svg')} />
                            
                            </Col>
                            <Col sm={2}>

                            <img className="steps"  src={require('../../assets/landing-pages/data-analytics/assets/section1/step2.svg')} />

                            </Col>
                            <Col sm={2}>

                            <img className="steps"  src={require('../../assets/landing-pages/data-analytics/assets/section1/step3.svg')} />

                            </Col>

                        </Row>
                        </div>




                <div className="section4">
                    <p 
                    style={{color: '#3E86F2', fontWeight: 600}}
                    className="text-center"> Then what's next? </p>
                <div class="d-flex justify-content-center">   

                <h1 style={{color: '#FBFCFE',  textAlign: 'center', width: 420, fontWeight: 'bold'}}>Experiment without the messy work </h1>
                </div>
                <div class="d-flex justify-content-center">    
                <p style={{color: '#8F97B0',  textAlign: 'center', width: 584}}> StackStr provides a quick lifecycle for you to redo and make mistakes until your data is perfect - except without the hassle of the extra work.</p>
                </div>
                <div class="d-flex justify-content-center">   

                <img className="lifecycle"  src={require('../../assets/landing-pages/data-analytics/assets/section1/lifecycle.svg')} />

                </div>

                <div>
                <Row style={{justifyContent: 'center'}}>
                    <Col md={3} sm={2}>
                        <img style={{width: '100%'}} src={require('../../assets/landing-pages/data-analytics/assets/section1/feature1.svg')} />
                    </Col>
                    <Col md={3} sm={2}>
                    <img style={{width: '100%'}} src={require('../../assets/landing-pages/data-analytics/assets/section1/feature2.svg')} />
                    </Col>
                    <Col md={3} sm={2}>
                    <img style={{width: '100%'}} src={require('../../assets/landing-pages/data-analytics/assets/section1/feature3.svg')} />
                    </Col>

                </Row>
                {/* <br></br> */}

                <Row style={{justifyContent: 'center'}}>
                <Col md={3} sm={2}>
                    <img style={{width: '100%'}} src={require('../../assets/landing-pages/data-analytics/assets/section1/feature4.svg')} />

                    </Col>
                    <Col md={3} sm={2}>
                        <img style={{width: '100%'}} src={require('../../assets/landing-pages/data-analytics/assets/section1/feature5.svg')} />

                    </Col>
                </Row>
                </div>

                </div>

                <br></br>


                <br></br>
                <br></br>


                <div className="section5">
                {/* <img className="cutoffblob" style={{position: 'absolute', left: 0, paddingTop: 10}} src={require('../assets/section1/cutoffblob.svg')} />
                <img className="cube" style={{position: 'absolute', right: 0}} src={require('../assets/section1/cube.svg')} /> */}


                <Row style={{alignItems: 'center'}}>
                    <Col>
                    <p style={{color: '#3E86F2', fontWeight: 600}}> Streamline workflows </p>
                     <Col sm={6}  className="col-sm-6 column-right p-0 terminalimgdevicesize">
                    <img style={{width: '100%', paddingTop: -20}} src={require('../../assets/landing-pages/data-analytics/assets/section1/lookunderthehood.svg')} />
                    </Col>
                    <h1
                    style={{color: '#FBFCFE', fontWeight: "bold",marginTop: 10 }}
                    > Intelligently clean 
                    your data</h1>
                    <p
                    >Stackstr’s handles the dirty work and speeds up your data workflow by 50% by training multiple models and deploying the most accurate with only three easy steps.</p>
                    <a href="#" className="explorelinks" > Try StackStr's workflows</a>

                    </Col>
                    <Col sm={6}  className="col-sm-6 column-right p-0 terminalimg">
                    <img style={{width: '100%', paddingTop: -20}} src={require('../../assets/landing-pages/data-analytics/assets/section1/lookunderthehood.svg')} />
                    </Col>

                </Row>


                <br></br>
                <br></br>
                <br></br>




                <Row style={{alignItems: 'center'}}>
                
                <Col sm={6}  className="col-sm-6 column-right p-0 connectdatasource">
        
                    <img style={{width: '100%', paddingRight: 30, marginBottom: 10}} src={require('../../assets/landing-pages/data-analytics/assets/section1/connecttodatasource.svg')} />
                    </Col>

                    <Col>
                    <p
                    style={{color: 'rgb(62, 134, 242)', fontWeight: 600}}
                    > Flexible with your dataset </p>
                    <h1
                    style={{color: '#FBFCFE', fontWeight: "bold", }}
                    >Connect to any source
                    or destination</h1>
                    <p
                    style={{ marginTop: 20}}
                    > StackStr provides numerous integrations to your datasource. Simply enter the link to your dataset or upload a file when starting a new project.</p>
                                        <a href="#" className="explorelinks" > View all sources</a>

                    </Col>
                  
                </Row>

                </div>


   
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                {/* <div className="section6">

                <br></br>
                    
                <h1 style={{color: 'white'}}> Data Governance </h1>


                <div>

                <a style={{color: "white"}}> Customized Pipelines </a>
                <a style={{color: "white"}}> Customized Pipelines </a>
                <a style={{color: "white"}}> Customized Pipelines </a>
                <a> </a>

                </div>


                <Card>
                        <CardBody>m
                            sdf
                        </CardBody>
                    </Card>
                </div>
 */}







               
                           

            </div>
        )
    }
}

export default DataAnalytics;