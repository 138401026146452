import Overview from "../components/dashboard/Overview";
import React from 'react';
import Issues from '../components/dashboard/Issues';
import FadeIn from 'react-fade-in';
import SettingsPage from '../subtabs/settings/SettingsPage';

export const dashboardTabNames = ["overview", "alerts", "issues"];

export function dashboardTabs(page, getComponents, components, typeMetric, organization, userToken, project, metrics, featureCounts) {
    console.log(typeMetric)
    switch (page) {
        case "dashboard":
            console.log("dashboard");
            return <FadeIn> <Overview getComponents={getComponents} components={components} typeMetric={typeMetric} organization={organization} userToken={userToken} project={project} metrics={metrics} featureCounts={featureCounts} /> </FadeIn>
        case "issues":
            console.log("issues");
            return <Issues organization={organization} userToken={userToken} project={project} />;
        case "alerts":
            console.log("alerts");
            return <SettingsPage organization={organization} userToken={userToken} project={project}/>
        default:
            console.log("default");
            return <Overview getComponents={getComponents} components={components} typeMetric={typeMetric}  organization={organization} userToken={userToken} project={project} metrics={metrics} featureCounts={featureCounts} />
    }
}

export function reformatPageTabs(page) {
    var newPage = page;
    newPage = page.charAt(0).toUpperCase() + page.slice(1);
    return newPage;
}

export function sortKindToCategories(projects) {
    var categories = []
    for (var i = 0; i < projects.length; i++) {
        if (projects[i]["kind"]) {
            categories.push(projects[i]["kind"])
        }
    }
    categories = categories.filter(function(item, pos) {
        return categories.indexOf(item) == pos;
    })
    return categories;
}