import React, { Component } from "react";
import FadeIn from "react-fade-in";
import { ExperimentUtils } from "../../../utils/ExperimentUtils";

export class System extends Component {
    state = {
        systemMetrics: [],
    }

    getSystemMetrics = () => {
        this.setState({ systemMetrics : ExperimentUtils.getExpDetails()["details"]["system_config"] })
    };

    componentDidMount() {
        this.getSystemMetrics();
    };

    render() {
        return (
            <FadeIn>
                <br></br>
                    <div>
                        <table style={{background: 'none', borderRadius: '30px'}} class="ui inverted hover celled striped table">
                        <thead>
                        <tr>
                            <th colspan="3"> System </th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(this.state.systemMetrics).map((metric) => {
                                    return (
                                        <tr>
                                        <td class="collapsing">
                                            <i class="setting icon"></i> {metric}
                                        </td>
                                            <td> {this.state.systemMetrics[metric]}</td>
                                            <td class="right aligned collapsing"></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        </table>
                    </div>
            </FadeIn>
        )
    }
}