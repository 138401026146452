
import React from 'react';
import 'react-animated-term/dist/react-animated-term.css'
import "react-step-progress-bar/styles.css";
// import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.css';
import 'react-animated-term/dist/react-animated-term.css'
import { Button, Col, Row } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import 'bootstrap/dist/css/bootstrap.min.css';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Icon } from 'semantic-ui-react';
import { ProjectCards } from '../components/ProjectCards';
import { AboutMe } from '../components/AboutMe';

import resume from '../assets/resume/Resume_2022.pdf'


const slideNames = ["slide_1", "slide_2", "slide_3", "slide_4"]

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.prev = 0
        this.state= {
          dataProgress: 0,
          currentSlide: 1,
          menuOn: false,
          slide: {
            num: 1,
            slideName: "slide_1"
          }
        }
    }

    slideProgressBar = (direction) => {
      var dataProgressBar = this.state.dataProgress;

      if (direction == "up") {
        dataProgressBar -= 2;
        if (dataProgressBar < 1) {
          dataProgressBar = 1;
        }
      } else {
        dataProgressBar += 2;
        if (dataProgressBar > 200) {
          dataProgressBar = 200;
        }
      }

      document.getElementById("light-pink-line-divider").style.height = `${dataProgressBar}px`;
      this.setState({ dataProgress : dataProgressBar })

      if (dataProgressBar > 0 && dataProgressBar < 40) {
        this.setSlideNum(1);
        this.setState({ currentSlide : 1 })
      } else if (dataProgressBar > 40 && dataProgressBar < 80) {
        this.setSlideNum(2);
        this.setState({ currentSlide : 2 })
      } else if (dataProgressBar > 80 && dataProgressBar < 120) {
        this.setSlideNum(3);
        this.setState({ currentSlide : 3 })
      } else if (dataProgressBar > 121 && dataProgressBar < 160) {
        this.setSlideNum(4);
        this.setState({ currentSlide : 4 })
      } else if (dataProgressBar > 161 && dataProgressBar < 200) {
        this.setSlideNum(5);
        this.setState({ currentSlide : 5 })
      }

    };

    /**
     * Using direction to change the slide if we want go above or below
     * @param {*} direction 
     */
    changeSlide = (direction) => {
      console.log(direction)
      var slide = this.state.slide;

      if (direction == "up") {
        slide.num += 1;
        if (slide.num > 5) {
          slide.num = 5;
        }
      } else {
        slide.num -= 1;
        if (slide.num < 1) {
          slide.num = 1;
        }
      }
      this.setState({ currentSlide : slide.num })

      this.setSlideNum(slide.num)
    };

    setSlideNum = async(slideNum) => {
      var slide = this.state.slide;
      slide.num = slideNum;

      this.getSlideComponent(slideNum).then((slideName) => {
        slide.slideName = slideName;
        this.setState({ slide : slide })
        this.clearAllCSS(slide.slideName).then(() => {
          this.turnOnCSS(slideName);
        })
      })
    }

    getSlideComponent = async(currNum) => {
      console.log("currSlide", currNum)
      switch (currNum) {
        case 1:
          // document.getElementById("light-pink-line-divider").style.height = '0px';
          this.setState({ currentSlide : 1 })
          return "slide_1"
        case 2:
          // document.getElementById("light-pink-line-divider").style.height = '60px';
          this.setState({ currentSlide : 2 })
          return "slide_2"
        case 3:
          // document.getElementById("light-pink-line-divider").style.height = '120px';
          this.setState({ currentSlide : 3 })
          return "slide_3"
        case 4:
          // document.getElementById("light-pink-line-divider").style.height = '180px';
          this.setState({ currentSlide : 4 })
          return "slide_4"
        case 5:
          // document.getElementById("light-pink-line-divider").style.height = '260px';
          this.setState({ currentSlide : 5 })
          return "slide_5"
        default:
          // document.getElementById("light-pink-line-divider").style.height = '300px';
          return "slide_0"
      }
    };

    matchSlideToProgress = (slideName) => {
      var new_progress_bar_data = 0;

      switch (slideName) {
        case "slide_1":
          this.setState({ currentSlide : 1 })
          new_progress_bar_data = 0;
          break;
        case "slide_2":
          this.setState({ currentSlide : 2 })
          new_progress_bar_data = 41;
          break;
        case "slide_3":
          this.setState({ currentSlide : 3 })
          new_progress_bar_data = 82;
          break;
        case "slide_4":
          this.setState({ currentSlide : 4 })
          new_progress_bar_data = 121;
          break;
        case "slide_5":
          this.setState({ currentSlide : 5 })
            new_progress_bar_data = 165;
            break;
      }

      document.getElementById("light-pink-line-divider").style.height = `${new_progress_bar_data}px`;
      this.setState({ dataProgress : new_progress_bar_data })
    }

    clearAllCSS = async(slideName) => {
      for (let element of document.body.getElementsByTagName("*")) {
        for (let className of element.classList) {
          if (slideNames.includes(className)) {
            element.classList.add("transparent");
            element.classList.remove("test")
            element.classList.remove("opaque");
          }
        }
     };
    };

    turnOnCSS = async(slideName) => {
      var saveElements = document.body.getElementsByClassName(slideName)


     for (let element of saveElements){
        element.classList.remove("transparent")
      }

      for (let element of saveElements){
        element.classList.add("opaque")
        element.classList.add("test")
      }

      document.getElementById("root").style.display = "block";

      var showStickyItems = document.body.getElementsByClassName("sticky_items");

      for (let element of showStickyItems){
        element.classList.remove("transparent")
        element.classList.add("opaque")
      }

      for (let name of slideNames) {
        document.getElementById(name).classList.remove("slider_expand");
      }
      console.log(slideName)
      document.getElementById(slideName).classList.add("slider_expand");

      if (slideName == "slide_3" || slideName == "slide_4") { 
        document.getElementById("home_bg_foreground").style.animation = "fadeoutBg 6s ease"
        document.getElementById("home_bg_foreground").style.background = "black"
      } else {
        document.getElementById("home_bg_foreground").style.animation = ""
        document.getElementById("home_bg_foreground").style.background = "linear-gradient(180deg, #080C1E 26.73%, #080C1E 73.07%, #030653 89.98%, #276468 102.02%)"
      } 
    }


    componentDidMount() {

      this.getSlideComponent(this.state.slide.num).then((slideName) => {
        this.matchSlideToProgress(slideName);
        this.clearAllCSS(slideName).then(() => {
          this.turnOnCSS(slideName);
        })
      })
    }

    componentWillUnmount() {}

    openMenu = () => {

      console.log("Menu status:", this.state.menuOn)

      if (this.state.menuOn) {
          this.scaleWindowAgain()
      } else {
        this.scaleWindow().then(() => {
          setTimeout(() => {
            document.getElementById("home_bg_foreground").classList.add("shrink-mini-window")
            document.getElementById("home_bg_foreground").classList.remove("maximize-mini-window")
          }, 600)
        })
      }

      var toggle = document.querySelector('.hamburger');
      toggle.classList.toggle('active');

      this.setState({ menuOn : !this.state.menuOn  })
    }

    scaleWindow = async() => {
        document.getElementById("home_bg_foreground").classList.add("scale_window")
    }

    scaleWindowAgain = async() => {
      document.getElementById("home_bg_foreground").classList.remove("scale_window")
      document.getElementById("home_bg_foreground").classList.remove("shrink-mini-window")
      document.getElementById("home_bg_foreground").classList.add("maximize-mini-window")
    }

    getTabName = (slideNum) => {
      switch (slideNum) {
        case 1: 
          return "Home"
        case 2:
          return "Who am I"
        case 3:
          return "Projects"
        case 4:
          return "Resume"
      }
    }

    render() {

      console.log(this.state.currentSlide)


        return (
            <div id="home_bg"  className="sticky_items home_bg"> 


            {
              this.state.menuOn ?
                <Fade top>
                    <div className="sticky_items opened-menu">
                      <p  onClick={() => { this.setSlideNum(1); this.openMenu() }} className=" sticky_items opened-menu-text"> Home </p>
                      <p onClick={() =>  { this.setSlideNum(2); this.openMenu() }} className="sticky_items opened-menu-text"> Who am I </p>
                      <p onClick={() => { this.setSlideNum(3); this.openMenu() }} className="sticky_items opened-menu-text"> Projects </p>
                      <p onClick={() => { this.setSlideNum(4); this.openMenu() }} className="sticky_items opened-menu-text"> Resume </p>
                    </div>
                </Fade>

              : null
            }

          <img className="kimia_logo sticky_items" onClick={() => window.location.href = '/'} src={require('../assets/kimia_logo.svg')} />

          <div id="menu_icon" className="sticky_items hamburger" style={{cursor: "pointer"}} onClick={this.openMenu}>
            <span className=" sticky_items hamburger__patty"></span>
            <span className="sticky_items hamburger__patty"></span>
            
          </div>

          <div id="home_bg_foreground" className="home_bg_foreground">

               {/* <Fade bottom delay={1400}> */}
               <Row className="sticky_items menu_tabs">
              {
                slideNames.map((slideName, idx) => {
                  if ((idx + 1) == this.state.slide.num) {
                    return <a className="menu_tab items name">  {this.getTabName(idx + 1)} <span> <Icon name="chevron right" style={{fontSize: '10px'}}/> </span> </a>
                  }
                  return <a onClick={() =>  this.setSlideNum(idx + 1)} className="menu_tab items">  {this.getTabName(idx + 1)} <span> <Icon name="chevron right" style={{fontSize: '10px'}}/> </span> </a>
                })
              }
      
            </Row>
          {/* </Fade> */}


          <div style={{position: 'absolute', top: '50%', zIndex: 999, transform: 'translateY(-50%)'}}>
            <div className="white-line-divider sticky_items"></div>
            <div id="light-pink-line-divider" className="light-pink-line-divider sticky_items"></div>




          </div>

            <div className="sticky_items wrapper_group_sliders">
              <Row className="sticky_items wrapper_group_sliders_row ">
                {
                  slideNames.map((slider, idx) => {
                    return (
                      <div id={slider} onClick={() => { this.setSlideNum(idx + 1).then(() => this.matchSlideToProgress(this.state.slide.slideName)) }} className="sticky_items slider-stick"></div>
                    )
                  })
                }
              </Row>
            </div>


            <div className="sticky_items expanded-window-foreground"></div>
      
              <div className="slide_1">

                    <div className="slide_1 slide_3_br_animated_groove_1"></div>
                  <div className="slide_1 slide_3_br_animated_groove_2"></div>
                  <div className="slide_1 slide_3_br_animated_groove_3"></div>


        
                  {/* SLIDE 1 - TOP LEFT ANIMATED GROOVES */}
                      <div className="slide_1 slide_1_tl_animated_groove_1"></div>
                      <div className="slide_1 slide_1_tl_animated_groove_2"></div>
                      <div className="slide_1 slide_1_tl_animated_groove_3"></div>
                      <div className="slide_1 slide_1_tl_animated_groove_4"></div>
                      <div className="slide_1 slide_1_tl_animated_groove_5"></div>
                      <div className="slide_1 slide_1_tl_animated_groove_6"></div>


                  <div className="slide_1 slide_1_container " style={{zIndex: 9999}}>
                      <div className="slide_1 menu-arrows-container">
                        <img style={{width: '40px', boxShadow: '0px -1px 20px #ffffff87', animation: 'floating 5s infinite'}} className="sticky_items down-grad-icon" onClick={() => { this.changeSlide("up"); }} src={this.state.slide.slideName == "slide_1" || this.state.slide.slideName == "slide_5" ? require('../assets/grad-right-icon.svg') : require('../assets/grad-right-icon.svg')} />
                      </div>

                      <div className="right-icon">
                        <p className="slide_1 button_arrow_labels"> Who am I </p>
                        <img className="slide_1" style={{width: '60px'}} onClick={() => { this.changeSlide("up"); this.matchSlideToProgress(this.state.slide.slideName)}} src={this.state.slide.slideName == "slide_1" || this.state.slide.slideName == "slide_5" ? require('../assets/white_right_icon.svg') : require('../assets/right_icon.svg')} />
                      </div>


                          <Fade delay={400} style={{zIndex: 999}} bottom>
                            <div className="slide_1" style={{zIndex: 999}}>
                              <p style={{textAlign: 'center'}} className="hi_i_am slide_1"> Hi, I am <span className="kimia_txt slide_1"> Kimia. </span> </p>                              
                            </div>
                            </Fade>
                            <Fade delay={1000} style={{zIndex: 999}} bottom>
                              <div style={{zIndex: 9999, position: "relative"}}>
                                  <p className="kimia-subtitle"> A venture-backed two-time founder & current Georgia Tech student </p>
                              </div>
                          </Fade>

                          <Fade delay={1600} style={{zIndex: 999}} bottom>
                              <div style={{marginTop: '25px'}}>
                                <Row className="slide_1_row_btns" style={{margin: '0 auto'}}>
                                  <Button style={{marginLeft: '-15px'}} onClick={() => { this.setSlideNum(3) }} className="view-my-works-btn">View My Work</Button>
                                 <Button style={{width: '117px'}} onClick={() => { this.setSlideNum(2) }} className="read-my-story-btn"> My Story </Button>
                                </Row>
                              </div>
                          </Fade>

                          <Fade delay={2200} style={{zIndex: 9999, position: "relative"}} bottom>
                            <div>
                              <p className="note_site_designed_and_code "> site designed & coded by yours truly </p>
                            </div>
                          </Fade>


                      

                      <br></br>

                      <Row style={{justifyContent: 'center', margin: '0 auto'}} className="slide_1 slide_1_picture_row">
                          {/* <Fade delay={600}> */}
                              <div style={{position: 'relative'}} className="slide_1">
                                <img className="slide_1 slide_1_floating_hexagon" src={require('../assets/slide_1_floating_hexagon.svg')} />
                                <img className="slide_1 slide_1_floating_pink_triangle" src={require('../assets/slide_1_floating_pink_triangle.svg')} />
                                <img className="slide_1 slide_1_pearl_img" src={require('../assets/slide_1_pearl_img.svg')} />
                                <img className="slide_1 slide_1_blue_diamond" src={require('../assets/slide_1_blue_diamond.svg')} />
                                <img className="slide_1 slide_1_purple_ball" src={require('../assets/slide_1_purple_pearl.svg')} />
                                <img className="slide_1 slide_1_glowy_rectangle" src={require('../assets/slide_1_glowy_rectangle.svg')} />

                                <img className="slide_1 castle" src={require('../assets/slide_1_castle.svg')} />
                              </div>
                            {/* </Fade> */}
                      </Row>
                  </div>

            

                </div>

                <div className="slide_4">

           

                </div>



                <div className="slide_2">
        
                  <div className="sticky_items menu-arrows-container">
                      <img style={{width: '40px', boxShadow: '0px -1px 20px #ffffff87', borderRadius: '100%', marginBottom: '31px'}} className="sticky_items" onClick={() => { this.changeSlide("down")}} src={require('../assets/grad-up-icon.svg')} />
                      <img style={{width: '40px', boxShadow: '0px -1px 20px #ffffff87', borderRadius: '100%'}} className="sticky_items"  onClick={() => { this.changeSlide("up") }} src={require('../assets/grad-down-icon.svg')} />
                    </div>

                <div className="left-icon">
                  <p className="slide_2 button_arrow_labels"> Home </p>
                  <img className="slide_2" style={{width: '60px'}} onClick={() => { this.changeSlide("down"); this.matchSlideToProgress(this.state.slide.slideName)}} src={require('../assets/left_icon.svg')} />
                </div>

                <div className="right-icon">
                  <p className="slide_2 button_arrow_labels"> Projects </p>
                  <img className="slide_2" style={{width: '60px'}}  onClick={() => { this.changeSlide("up"); this.matchSlideToProgress(this.state.slide.slideName)}} src={require('../assets/white_right_icon.svg')} />
                </div>

                  <AboutMe />

                </div>


                <div className="slide_3">

                
                  <Fade delay={300} left>
                      <img className="slide_3 slide_4_left_grooves" src={require('../assets/slide_4_left_grooves.svg')} />
                  </Fade>

                  <Fade delay={100} top>
                    <img className="slide_3 slide_4_top_grooves" src={require('../assets/slide_4_top_grooves.svg')} />
                  </Fade>

                  <Fade delay={500} bottom>
                    <img className="slide_3 slide_4_bottom_grooves" src={require('../assets/slide_4_bottom_grooves.svg')} />
                </Fade>

                <Fade delay={600} right>
                    <img className="slide_3 slide_4_right_grooves" src={require('../assets/slide_4_right_grooves.svg')} />
                </Fade>

                <div className="left-icon">
                  <p className="slide_3 button_arrow_labels"> Who am I  </p>
                  <img className="slide_3" style={{width: '60px'}} onClick={() => { this.changeSlide("down"); this.matchSlideToProgress(this.state.slide.slideName)}} src={require('../assets/left_icon.svg')} />
                </div>

                <div className="right-icon">
                  <p className="slide_3 button_arrow_labels"> Resume </p>
                  <img className="slide_3" style={{width: '60px'}}  onClick={() => { this.changeSlide("up"); this.matchSlideToProgress(this.state.slide.slideName)}} src={require('../assets/white_right_icon.svg')} />
                </div>

                <div className="slide_3 slide_5_container">
                    <ProjectCards changeSlide={this.changeSlide} />
              </div>

      

                </div>

                <div className="slide_4">

                <div className="sticky_items menu-arrows-container">
                    <img style={{width: '40px', borderRadius: '100%', marginBottom: '31px', boxShadow: '0px -1px 20px #ffffff87'}} className="sticky_items" onClick={() => { this.changeSlide("down") }}   src={require('../assets/grad-up-icon.svg')} />
                </div>

                  <Fade delay={300} bottom cascade>
                      <div className="slide_4 slide_5_bl_grooves">
                        <img className="slide_4 slide_5_bl_grooves" src={require('../assets/slide_5_bl_grooves.svg')} />
                        <img className="slide_4 slide_5_small_pearl" src={require('../assets/slide_5_small_pearl.svg')} />
                        <img className="slide_4 slide_5_large_pearl" src={require('../assets/slide_5_large_pearl.svg')} />
                      </div>
                  </Fade>
                  <img className="slide_4 bottom_right_groove_contact" src={require('../assets/contact_us_page_waves.svg')} />

              
                <div className="left-icon">
                  <p className="slide_5 button_arrow_labels"> Projects </p>
                  <img className="slide_5" style={{width: '60px'}} onClick={() => { this.changeSlide("down"); this.matchSlideToProgress(this.state.slide.slideName)}} src={require('../assets/left_icon.svg')} />
                </div>



                  <div className="slide_4 slide_4_container">
                      <div className="slide_4">
                        <div>
                          <Fade bottom delay={400}>
                            <div>
                              <p className="lets_collaborate"> Let's collaborate. </p>
                            </div>
                          </Fade>

                          <Fade bottom delay={900}>
                            <div>
                              <p className="resume_link" onClick={() => window.open(resume)}><span style={{textDecoration: "underline", marginRight: '5px'}}>Resume_2022.pdf</span> <Icon name="attach" /> </p>
                            </div>
                          </Fade>

                          <Fade bottom delay={1300}>
                            <div style={{marginTop: '90px', marginLeft: '0px', display: "flex", justifyContent: "space-between"}}>
                              <div>
                                <p className="title_links_contact"> Atlanta, GA </p>
                                <p style={{fontSize: '20px'}} className="subtitle_links_contact"> 
                                  <Icon name="linkedin" style={{marginRight: '7px'}} onClick={() => window.open("https://www.linkedin.com/in/kimiakavanroodi1/")} /> 
                                  <Icon name="mail" style={{marginRight: '7px'}}  onClick={() => window.location.href = "mailto:kimiakavanroodi@gmail.com?subject=Contact me"} /> 
                                  <Icon name="dribbble" style={{marginRight: '7px'}} onClick={() => window.open("https://dribbble.com/kimiakavanroodi/shots")} />  
                                  <Icon name="github" style={{marginRight: '7px'}}  onClick={() => window.open("https://github.com/kimiakavanroodi")} /> 
                                </p>
                              </div>

                              <div>
                                <p className="title_links_contact"></p>
                                <p className="subtitle_links_contact"></p>
                              </div>
                            </div>
                          </Fade>

                        </div>
                      </div>
                    </div>
                </div>
              </div>




            </div>
        )
    }
}

export default Home;